import "./checkout-payment-method.scss";

import { Col } from "react-bootstrap";
import { IPaymentMethod } from "shared/interfaces/Order.interface";

interface CheckoutPaymentMethodProps {
  method: IPaymentMethod;
  isRadioSelected: (value: string) => boolean;
  handleRadioClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePayment: (method: IPaymentMethod) => void;
}

export const CheckoutPaymentMethod = ({
  method,
  isRadioSelected,
  handleRadioClick,
  handlePayment,
}: CheckoutPaymentMethodProps) => (
  <Col xs={12} className="mb-2">
    {method.identifier === "error" ? (
      <div className="custom-div-error-payment-methods">{method.name}</div>
    ) : (
      <label
        className="input-text-custom"
        onClick={() => {
          handlePayment(method);
        }}
      >
        <input
          type="radio"
          id={method.name}
          name="paymentMethod"
          value={method.identifier}
          className="me-2"
          checked={isRadioSelected(method.identifier)}
          onChange={handleRadioClick}
        />
        <span className="checkmark" />
        {method.name}
      </label>
    )}
  </Col>
);
