import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import getRestaurant from "services/api/restaurant/getRestaurant";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export default (restaurantId: string) =>
  useQuery<IRestaurant>(
    ["restaurant", restaurantId],
    () => getRestaurant(restaurantId),
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (err) => {
        Sentry.captureException(err);
        return err;
      },
    }
  );
