import dayjs from "dayjs";
import {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { formatDateWithHourAndMinutes } from "shared/utils/date";

/**
 * Compare the item's availabilities and the date provided
 * @param item : item to check availability
 * @param due_date : date selected by the client
 * @returns : return a boolean if a match is found
 */
export default (
  item: IHydratedItemDish | IHydratedItemMenu,
  due_date: Date | undefined
): boolean => {
  //If there is no specified availabilities, the item is always available
  if (!item.availability_hours_of_weeks.length) return true;

  //Format the date selected to compare it after with the item's availability dates
  const daySelectedFormated = dayjs(due_date)
    .locale("en")
    .format("dddd")
    .toLowerCase();

  //Try to find to day corresponding in the item's availability dates with the selected date
  const availabilityForDaySelected = item.availability_hours_of_weeks.find(
    (availabilityHours) => {
      return availabilityHours.day_of_week === daySelectedFormated;
    }
  );

  //If no day is found then return false
  if (!availabilityForDaySelected) return false;

  //Compare if availability is after the start period and before the end period in one of the time periods provided
  const isStillAvailableWithDateSelected =
    availabilityForDaySelected.time_periods.find((timePeriod) => {
      const dateFormatted = dayjs(due_date).toDate();

      const startPeriod = formatDateWithHourAndMinutes(
        timePeriod.start_time,
        dateFormatted
      ).minute(-1);
      const endPeriod = formatDateWithHourAndMinutes(
        timePeriod.end_time,
        dateFormatted
      );

      return (
        dayjs(startPeriod).isBefore(due_date) &&
        dayjs(endPeriod).isAfter(due_date)
      );
    });

  return !!isStillAvailableWithDateSelected;
};
