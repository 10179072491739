import dayjs from "dayjs";
import ORDER_MODES from "shared/constants/orderModes.const";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import { getOrderDelayRestaurantFromOrderMode } from "../restaurant";

/**
 * Check if selected due date from the order has expired
 * In comparaison of local date + potential delays from restaurant
 * @param localOrder
 * @param restaurant
 * @returns boolean
 */
export default (
  localOrder: ILocalOrder,
  restaurant: IRestaurant | undefined
): boolean => {
  let orderMode = "";
  const orderModeId = ORDER_MODES.find(
    (mode) => mode.value === localOrder?.orderMode
  );

  orderMode = orderModeId?.id || "";
  const orderDelay = getOrderDelayRestaurantFromOrderMode(
    orderMode,
    restaurant
  );

  return dayjs()
    .add((restaurant?.extra_delay ?? 0) + orderDelay, "minute")
    .isAfter(localOrder?.due_date);
};
