import dayjs from "dayjs";

export default (tokenExpirationDate: Date): boolean => {
  //Compare with a margin to not renew token at the last minute
  const tokenExpDateWithMargin = dayjs(tokenExpirationDate).subtract(
    15,
    "minute"
  );
  return dayjs().isAfter(tokenExpDateWithMargin);
};
