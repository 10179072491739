import axios from "axios";
import i18next from "i18next";
import authenticate from "services/api/authenticate";
import {
  checkTokenIsExpired,
  convertTokenToItsExpirationDate,
} from "shared/utils/api";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Before every api call, check if the token is expired
 * Renew it before the call if needed
 * if the request is the token's request itself(authenticate()),
 * ignore it to avoid infinite loop
 */
apiInstance.interceptors.request.use(async (request) => {
  if (request.params === "authenticate") return request;
  //set for every request the params with the current lang
  request.params = {
    locale: i18next.language,
  };
  if (request.headers) {
    const { Authorization }: any = request.headers.common;
    if (Authorization) {
      const tokenExpirationDate =
        convertTokenToItsExpirationDate(Authorization);

      checkTokenIsExpired(tokenExpirationDate) && authenticate();
    }
  }
  return request;
});

/**
 * Force a delay if REACT_APP_DEBUG_API_DELAY is set
 */
apiInstance.interceptors.response.use(async (response) => {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_DEBUG_API_DELAY
  ) {
    return await new Promise((resolve) => {
      setTimeout(() => {
        return resolve(response);
      }, parseInt(`${process.env.REACT_APP_DEBUG_API_DELAY}`));
    });
  } else {
    return response;
  }
});

export default apiInstance;
