import { Modal } from "react-bootstrap";

export const ItemModalHeader = ({ name }: { name: string }) => {
  return (
    <Modal.Header closeButton className="d-flex">
      <Modal.Title
        id="contained-modal-title-vcenter"
        className="w-100 text-center fw-bold fs-5"
      >
        {name}
      </Modal.Title>
    </Modal.Header>
  );
};
