import { useTranslation } from "react-i18next";
import { formatPriceForBasket } from "shared/utils/commons";

export const MinimumAmountBasket = ({
  minimumPrice,
}: {
  minimumPrice: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <>{t("basketSummary.minimumOrderBasket")} : </>
      <>{formatPriceForBasket(minimumPrice)}</>
    </div>
  );
};
