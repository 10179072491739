const EVENT_TAGS = {
  SET_MODE: "setMode",
  SET_RESTAURANT: "setRestaurant",
  PAGE_VIEW: "pageview",
};
export default EVENT_TAGS;

export const PAGE_VIEW_MAPPING = [
  { path: "/restaurants/" },
  { path: "/restaurants/:restaurantId" },
  { path: "/restaurants/:restaurantId/checkout" },
  { path: "/orders/:orderId" },
];
