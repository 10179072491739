import "./restaurant-catalog.scss";

import { mdiBasket, mdiSilverware } from "@mdi/js";
import Error from "pages/Error";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import moduleState from "shared/atoms/module.atom";
import BasketMobileButton from "shared/components/BasketMobileButton";
import CatalogPreloader from "shared/components/CatalogPreloader";
import DishModal from "shared/components/DishModal";
import ItemModal from "shared/components/ItemModal";
import RestaurantBasket from "shared/components/RestaurantBasket";
import RestaurantCatalogCategory from "shared/components/RestaurantCatalogCategory";
import RestaurantModal from "shared/components/RestaurantModal";
import RestaurantNav from "shared/components/RestaurantNav";
import useFetchCatalog from "shared/hooks/useFetchCatalog.hook";
import useFetchRestaurant from "shared/hooks/useFetchRestaurant.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import {
  IHydratedItemDish,
  IHydratedItemMenu,
  IHydratedTag,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { formatPriceForBasket } from "shared/utils/commons";
import { checkIsTimeExpired, getTotalPrice } from "shared/utils/localOrder";
import {
  getDisabledItemsInLocalOrder,
  itemFilteredByAvailability,
  removeDisabledItemsInLocalOrder,
} from "shared/utils/restaurant";

import RestaurantOrderInformationsMobile from "./components/RestaurantOrderInformationsMobile";

export function RestaurantCatalog() {
  const params = useParams<{ restaurantId: string; orderMode: string }>();
  const { module } = useRecoilValue(moduleState);
  const restaurantId = params.restaurantId;
  const [activePill, setActivePill] = useState<number>(0);
  const [visible, setVisible] = useState(true);
  const { addItem, localOrder, removeItemById, setLocalOrder } =
    useLocalOrder();

  const { t } = useTranslation();

  const {
    data: catalog,
    isLoading: loadingCatalog,
    isError: catalogError,
  } = useFetchCatalog(`${restaurantId}`);
  const [addingItem, setAddingItem] = useState<
    IHydratedItemMenu | IHydratedItemDish | undefined
  >(undefined);
  const [modifyItemOrder, setModifyItemOrder] = useState<
    IOrderItem | undefined
  >(undefined);

  const [modifyMenuOrder, setModifyMenuOrder] = useState<
    ICurrentMenuState | undefined
  >(undefined);

  const [displayRestaurantModal, setDisplayRestaurantModal] =
    useState<IRestaurant | null>(null);

  const [, setShowBasket] = useState<boolean | undefined>(false);

  const localOrderIds = Object.keys(localOrder.items);
  const totalAmountBasket = getTotalPrice(
    localOrderIds.map((id) => localOrder.items[id])
  );
  const totalBasket = formatPriceForBasket(totalAmountBasket);
  const minimumPrice = module.minimum_price;
  const canCheckout = totalAmountBasket >= minimumPrice;

  useEffect(() => {
    const itemsDisabled = getDisabledItemsInLocalOrder(catalog, localOrder);

    if (itemsDisabled.length) {
      removeDisabledItemsInLocalOrder(
        itemsDisabled,
        localOrder,
        removeItemById
      );
      handleDisplayToast();
    }
  }, [catalog]);

  const handleDisplayToast = () => {
    toast(t<string>("basketSummary.toasterOnRemoveItem"), {
      duration: 5000,
      icon: "ℹ️",
    });
  };

  function handleVisible(index: number, isVisible: boolean) {
    if (isVisible) {
      setVisible(true);
      setActivePill(index);
    }
  }

  const {
    data: restaurant,
    isLoading: loadingRestaurant,
    isError: restaurantError,
  } = useFetchRestaurant(`${restaurantId}`);

  const checkIsBasketExpired = useMemo(
    () => checkIsTimeExpired(localOrder, restaurant),
    [localOrder, restaurant]
  );

  const onChange = (index: number, tag: IHydratedTag) => {
    setActivePill(index);
    let section = document.getElementById(tag.id);
    section && section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleOnSelectionUpdate = (updatedDate: Date) => {
    setLocalOrder({
      ...localOrder,
      due_date: updatedDate,
    });
    setDisplayRestaurantModal(null);
  };

  // 404 page if the restaurant does not exists
  if (restaurantError) {
    return <Error icon={mdiBasket} translation="errors.restaurantNotFound" />;
  }

  if (loadingCatalog || loadingRestaurant || !restaurant) {
    return <CatalogPreloader />;
  }

  return (
    <div className="restaurant-catalog">
      {!catalogError && (
        <RestaurantNav
          {...{ restaurant, catalog, onChange, visible, activePill }}
        />
      )}
      <Container>
        <Row className="mt-4">
          <Col className="display-catalogue">
            {catalogError ? (
              <div className="pt-4">
                <Error
                  icon={mdiSilverware}
                  translation="errors.catalogNotFound"
                  disabledButton={true}
                />
              </div>
            ) : (
              <Row>
                {catalog &&
                  catalog.tags.map((tag: IHydratedTag, index: number) => {
                    const categoryHasItemsAvailable = tag.items.filter((item) =>
                      itemFilteredByAvailability(item, localOrder.due_date)
                    );

                    return categoryHasItemsAvailable.length ? (
                      <RestaurantCatalogCategory
                        key={tag.id}
                        {...{
                          index,
                          handleVisible,
                          tag,
                          catalog,
                          addItem,
                          setAddingItem,
                        }}
                      />
                    ) : null;
                  })}
              </Row>
            )}
          </Col>
          <Col md={4} className="d-none d-lg-block">
            <RestaurantBasket
              allowEditRestaurant={true}
              {...{
                localOrder,
                localOrderIds,
                totalBasket,
                setModifyItemOrder,
                setAddingItem,
                setModifyMenuOrder,
                restaurant,
                setShowBasket,
                catalog,
                canCheckout,
                minimumPrice,
                handleDisplayToast,
                totalAmountBasket,
                catalogError,
              }}
            />
          </Col>
        </Row>
        <Col className="d-flex d-lg-none flex-column container-mobile">
          <RestaurantOrderInformationsMobile
            restaurant={restaurant}
            displayRestaurantModal={displayRestaurantModal}
            setDisplayRestaurantModal={setDisplayRestaurantModal}
            catalog={catalog}
            handleDisplayToast={handleDisplayToast}
          />
          <BasketMobileButton
            {...{
              totalBasket,
              localOrderIds,
              localOrder,
              restaurant,
              setModifyItemOrder,
              setAddingItem,
              setModifyMenuOrder,
              catalog,
              canCheckout,
              handleDisplayToast,
              minimumPrice,
              totalAmountBasket,
              catalogError,
            }}
          />
        </Col>
      </Container>

      {addingItem && catalog ? (
        addingItem.type === "menu" ? (
          <ItemModal
            item={addingItem}
            onClose={() => {
              setAddingItem(undefined);
              setModifyItemOrder(undefined);
              setModifyMenuOrder(undefined);
            }}
            {...{ modifyItemOrder, modifyMenuOrder, catalog }}
          />
        ) : (
          <DishModal
            item={addingItem}
            modifyItemOrder={modifyItemOrder}
            onClose={() => {
              setAddingItem(undefined);
              setModifyItemOrder(undefined);
            }}
            aLaCarte={true}
          />
        )
      ) : null}
      {checkIsBasketExpired && (
        <RestaurantModal
          activeRestaurant={restaurant}
          onHide={() => {
            setDisplayRestaurantModal(null);
          }}
          onSelectionUpdate={handleOnSelectionUpdate}
        />
      )}
      <Toaster position="bottom-center" />
    </div>
  );
}
