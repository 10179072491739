import dayjs from "dayjs";
import IRestaurant from "shared/interfaces/Restaurant.interface";

/**
 * Check if the date is within the restaurant closure dates
 * @param date
 * @param restaurant
 * @returns {boolean}
 */
export default (date: Date, restaurant: IRestaurant): boolean => {
  for (const closure of restaurant.closures) {
    const from = dayjs(closure.start_date);
    const to = dayjs(closure.end_date);

    if (dayjs(date).isBetween(from, to)) {
      return true;
    }
  }
  return false;
};
