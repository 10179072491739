import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

export default function RestaurantWebsiteLink(props: { url: string }) {
  const { t } = useTranslation();

  return (
    <a href={props.url}>
      <Icon path={mdiChevronLeft} size={1} />{" "}
      {t<string>("commons.returnRestaurantWebsite")}
    </a>
  );
}
