import ICustomer from "shared/interfaces/Customer.interface";
import IModule from "shared/interfaces/Module.interface";
import { IPaymentMethod } from "shared/interfaces/Order.interface";

import displayCgvPrivacy from "./displayCgvPrivacy";

export default (
  customerCheckout: ICustomer,
  module: IModule,
  userPaymentMethod: IPaymentMethod[],
  methodsPayment: IPaymentMethod[] | undefined
): boolean => {
  const { first_name, last_name, email, phone, cgv } = customerCheckout;
  let inputValidation = first_name && last_name && phone && email;

  let cgvCondition = displayCgvPrivacy(module) === "cgv_url" && cgv;
  let noCgvCondition = module.cgv_url === null || module.cgv_url === "";
  let cgvPrivacyCondition = displayCgvPrivacy(module) === "cgv_privacy" && cgv;

  return !!(
    ((cgvCondition && inputValidation) ||
      (displayCgvPrivacy(module) === null && inputValidation) ||
      (cgvPrivacyCondition && inputValidation) ||
      (noCgvCondition && inputValidation)) &&
    (userPaymentMethod.length || !methodsPayment)
  );
};
