import { findIndex } from "lodash";
import ICatalog, {
  IHydratedOption,
  IOption,
} from "shared/interfaces/Catalog.interface";

export default (option: IOption, catalog: ICatalog): IHydratedOption => {
  let hydratedOption: IHydratedOption = { ...option, values: [] };

  for (const valueId of option.value_ids) {
    const valueIndex = findIndex(catalog.option_values, { id: valueId });
    if (~valueIndex) {
      hydratedOption.values.push(catalog.option_values[valueIndex]);
    }
  }

  return hydratedOption;
};
