import dayjs from "dayjs";
import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { v4 as uuidv4 } from "uuid";

import { itemModifiersComparison } from ".";

export default (
  localOrder: ILocalOrderState,
  item: IOrderItem,
  quantity: number
): ILocalOrderState => {
  const localOrderIds = Object.keys(localOrder.items);
  let newState = { ...localOrder };
  /* 
  check if localOrder has items already
  if not, create the new item
  **/
  if (localOrderIds.length === 0) {
    const entityId = uuidv4();
    const newDate = dayjs().toDate();
    return {
      ...newState,
      items: { [entityId]: { ...item, entityId, quantity } },
      created: newDate,
      modified: newDate,
    };
  }

  /*
    Check if the new item has the same modifiers
      If yes, just increment the quantity
      Otherwise, create a distinct new item
      **/
  if (
    localOrderIds.some(
      (localId) =>
        newState.items[localId].id === item.id &&
        itemModifiersComparison(newState.items[localId], item)
    )
  ) {
    localOrderIds.forEach((entityId: string) => {
      if (
        itemModifiersComparison(newState.items[entityId], item) &&
        newState.items[entityId].id === item.id
      ) {
        newState.items[entityId].quantity =
          newState.items[entityId].quantity + quantity;
      }
    });
  } else {
    const entityId = uuidv4();
    const newItemsState = {
      ...localOrder.items,
      [entityId]: { ...item, entityId, quantity },
    };

    newState = { ...localOrder, items: newItemsState };
  }

  return newState;
};
