export const HorizontalSeparator = ({
  hideOnMobile,
}: {
  hideOnMobile?: boolean;
}) => {
  return (
    <div
      className={`bg-light horizontal-separator mt-2 mb-4 ${
        hideOnMobile ? "hide-mobile" : ""
      }`}
    />
  );
};
