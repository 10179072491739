type TObjectWithKey = {
  [key: number]: string;
};
const DAYS_OF_WEEK_FORMATED: TObjectWithKey = {
  0: "monday",
  1: "tuesday",
  2: "wednesday",
  3: "thursday",
  4: "friday",
  5: "saturday",
  6: "sunday",
};

export default DAYS_OF_WEEK_FORMATED;
