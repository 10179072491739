import "./restaurant-list-input.scss";

import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

interface RestaurantListInputProps {
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RestaurantsListInput = ({
  handleSearch,
}: RestaurantListInputProps) => {
  const { t } = useTranslation();

  return (
    <div className="input-search-container mt-2">
      <input
        type="text"
        placeholder={t<string>("restaurantsList.inputPlaceholder")}
        onChange={handleSearch}
      />
      <Icon path={mdiMagnify} size={0.9} className="me-2" />
    </div>
  );
};
