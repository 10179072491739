import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import { IOrderItem } from "shared/interfaces/Order.interface";

export default (
  localOrder: ILocalOrderState,
  item: IOrderItem
): ILocalOrderState => {
  const localOrderIds = Object.keys(localOrder.items);
  let newState = { ...localOrder };

  /*
  compare localorder and current item to modify
  overwrite all its fields but its quantity
      **/

  localOrderIds.forEach((entityId: string) => {
    if (entityId === item.entityId) {
      newState.items[entityId] = {
        ...item,
        quantity: newState.items[entityId].quantity,
      };
    }
  });

  return newState;
};
