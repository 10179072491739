import IModule from "shared/interfaces/Module.interface";

/**
 * Tell wether or not the mode {modeId} is available for the given module
 * @param modeId
 * @param module
 * @returns
 */
export default (modeId: number, module: IModule): boolean =>
  module.allowed_order_mode.includes(modeId);
