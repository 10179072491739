export const OptionCustom = ({
  value,
  today,
  text,
}: {
  value: string;
  today?: string | null;
  text: string;
}) => {
  return (
    <option value={value}>
      {text} {today ? `(${today})` : null}
    </option>
  );
};
