import {
  IHydratedOption,
  IOptionValue,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

/**
 * Convert a catalog option Value into a order item ready to be used (as a modifier for example)
 * @param item
 * @returns
 */
export default (item: IOptionValue, option: IHydratedOption): IOrderItem => {
  return {
    id: item.id,
    group_id: option.id,
    name: item.name,
    unit_price: item.price || 0,
    modifiers: [],
    type: "option_value",
    quantity: 1,
    disabled: item.disabled,
  };
};
