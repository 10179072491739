import { findIndex } from "lodash";
import ORDER_MODES from "shared/constants/orderModes.const";
import IOrderMode from "shared/interfaces/OrderMode.interface";

/**
 * Return an order mode props based on it's index (value)
 * @param id
 * @returns
 */
export default (index?: number): IOrderMode | null => {
  const modeIndex = findIndex(ORDER_MODES, (mode) => mode.value === index);
  if (~modeIndex) {
    return ORDER_MODES[modeIndex];
  }
  return null;
};
