import { mdiAlertCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export const RestaurantClosures = (props: { restaurant: IRestaurant }) => {
  const { t } = useTranslation();
  const restaurant = props.restaurant;

  return (
    <div className="restaurant-closures">
      {restaurant.closures.map((closure, closureIndex: number) => {
        const singleDate = dayjs(closure.start_date).isSame(
          dayjs(closure.end_date),
          "day"
        );

        return (
          <div key={closureIndex} className="pt-2 item-restaurant d-flex">
            <Icon className="me-2" path={mdiAlertCircleOutline} size={0.7} />
            <div>
              <div>
                {t<string>(
                  `restaurantsList.${
                    singleDate
                      ? "closureDateTemplateSingle"
                      : "closureDateTemplate"
                  }`,
                  {
                    startDate: dayjs(closure.start_date).format("DD/MM/YYYY"),
                    endDate: dayjs(closure.end_date).format("DD/MM/YYYY"),
                  }
                )}
              </div>
              {closure.message && <div>{closure.message}</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};
