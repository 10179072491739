import dayjs from "dayjs";
import ICatalog from "shared/interfaces/Catalog.interface";
import ICustomer from "shared/interfaces/Customer.interface";
import IModule from "shared/interfaces/Module.interface";
import IRestaurant, {
  IHoursCollection,
} from "shared/interfaces/Restaurant.interface";

export const dummyCustomer: ICustomer = {
  email: "johndoe@zelty.fr",
  first_name: "John",
  last_name: "Doe",
  id: 1,
  addresses: [],
  mail_optin: true,
  sms_optin: true,
  phone: "0612345678",
};

export const dummyOpeningHours: IHoursCollection[] = [
  {
    day_of_week: "monday",
    time_periods: [
      {
        start_time: "08:00",
        end_time: "18:00",
      },
    ],
  },
  {
    day_of_week: "tuesday",
    time_periods: [
      {
        start_time: "08:00",
        end_time: "18:00",
      },
    ],
  },
  {
    day_of_week: "wednesday",
    time_periods: [
      {
        start_time: "08:00",
        end_time: "18:00",
      },
    ],
  },
  {
    day_of_week: "thursday",
    time_periods: [
      {
        start_time: "08:00",
        end_time: "18:00",
      },
    ],
  },
  {
    day_of_week: "friday",
    time_periods: [
      {
        start_time: "10:00",
        end_time: "23:00",
      },
    ],
  },
  {
    day_of_week: "saturday",
    time_periods: [
      {
        start_time: "10:00",
        end_time: "23:00",
      },
    ],
  },
];

export const dummyModule: IModule = {
  allowed_order_mode: [1, 2],
  authorized_restaurants: [],
  domain_name: "",
  id: "dummy",
  langs: ["fr", "en"],
  organization_id: "",
  color: "#26a69a",
  deactivate_map: false,
  description: "Lorem ipsum",
  name: "Mac Dolan",
  logo_url: "placeholder.png",
  force_display_modal_dish: false,
  comments: [],
  catalog_id: "catalog_id mocked",
  cgv_url: "http://url-mocked.com",
  privacy_policy_url: "http://url-mocked.com",
  slug: "slug mocked",
  force_online_payment: false,
  default_lang: "fr",
  max_days: 3,
  minimum_price: 0,
  transaction_method_id: 0,
  metadata: {
    title: " title HEAD",
    image: "url image HEAD",
    description: "description HEAD",
    favicon: "url favicon HEAD",
  },
};

export const dummyCatalog: ICatalog = {
  description: "Lorem ipsum",
  name: "Catalog",
  id: "0122ffezfez",
  locale: "fr",
  option_values: [
    {
      id: "option-value-1",
      name: "Sauce Barbecue",
      price: 11,
      price_override: [],
      disabled: false,
      is_sold_out: false,
      price_is_fixed: true,
      type: "option_value",
      availability_hours_of_weeks: dummyOpeningHours,
    },
    {
      id: "option-value-2",
      name: "Sauce Curry",
      price: 11,
      price_override: [],
      disabled: false,
      is_sold_out: false,
      price_is_fixed: true,
      type: "option_value",
      availability_hours_of_weeks: dummyOpeningHours,
    },
  ],
  currency: "Eur",
  menu_part: [
    {
      id: "menu-part-1",
      name: "Entrée",
      dish_ids: ["dish-1"],
      maximum_choices: 4,
      minimum_choices: 1,
      type: "menu_part",
      kitchen_position: 1,
      description: "l'entrée du menu",
      image: "https://picsum.photos/200",
    },
  ],
  dishes: [
    {
      type: "dish",
      allergens: [],
      id: "dish-1",
      price_override: [],
      availability_hours_of_weeks: [],
      option_ids: ["option-1"],
      price: 11,
      name: "toto",
      description: "Lorem ipsum",
      image: {
        small: {
          url: "https://www.shutterstock.com/image-photo/different-autumn-soups-set-assortment-vegetable-1924341950",
        },
        original: {
          url: "https://www.shutterstock.com/image-photo/different-autumn-soups-set-assortment-vegetable-1924341950",
        },
      },
      color: "#ffffff",
      disabled: false,
      is_sold_out: false,
    },
  ],
  menus: [
    {
      menu_parts_ids: ["menu-part-1"],
      name: "Formule midi",
      color: "#ffffff",
      disabled: false,
      is_sold_out: false,

      price_is_fixed: false,

      price: 11,
      id: "menu-1",
      price_override: [],
      price_prevent_discounts: false,
      availability_hours_of_weeks: [],

      type: "menu",
      description: "Lorem ipsum",
    },
  ],
  tags: [
    {
      id: "tag-1",
      name: "toto",
      image_url: "http://lorempixel.com/400/200/food/",
      item_ids: [],
      disabled: false,
    },
  ],
  options: [
    {
      id: "option-1",
      type: "option_value",
      name: "Sauces",
      minimum_choices: 0,
      maximum_choices: 5,
      disabled: false,
      value_ids: ["option-value-1"],
      pos_settings_do_not_present_automatically: false,
      pick_same_value: true,
    },
  ],
  taxes: [
    {
      id: "123e4567-e89b-12d3-a456-426614174000",
      name: "taxe",
      rate: 55,
    },
  ],
};

export const dummyRestaurant: IRestaurant[] = [
  {
    id: 11,
    name: "Toto",
    public_name: "public tata",
    ordering_available: false,
    logo_url: "https://logo-url.fr",
    cover_url: "https://logo-url.fr",
    address: "61 rue de Cléry",
    phone: "0101010101",
    delivery_time: 0,
    delivery_charge: 0,
    delivery_minimum: 0,
    coordinates: { lat: 48.94, lng: 2.49 },
    closures: [
      {
        start_date: dayjs().add(1, "day").toDate(),
        end_date: dayjs().add(2, "day").toDate(),
        message: "Closed",
      },
    ],
    opening_hours: dummyOpeningHours,
    takeaway_delay: 15,
    delivery_delay: 15,
    extra_delay: 15,
    opening_hours_text: "Opening",
    delivery_zones: [
      {
        id: 1,
        restaurant_id: 11,
        name: "test",
        zip_code: "75010",
        delivery_time: 15,
        delivery_charge: 10,
        min_price: 150,
        city: "Paris",
        deleted: false,
        type: 1,
        polygon: [],
        all_streets: true,
        force_web_payment: false,
        disabled: false,
      },
    ],
  },
  {
    id: 12,
    name: "Tata",
    public_name: "public tata",
    ordering_available: false,
    logo_url: "https://logo-url.fr",
    address: "8 rue de paradis",
    phone: "0101010101",
    cover_url: "https://logo-url.fr",
    delivery_time: 0,
    delivery_charge: 0,
    delivery_minimum: 0,
    closures: [
      {
        // force closed restaurant for testing
        start_date: dayjs().subtract(1, "day").toDate(),
        end_date: dayjs().add(2, "day").toDate(),
        message: "Closed",
      },
    ],
    opening_hours: dummyOpeningHours,
    takeaway_delay: 15,
    delivery_delay: 15,
    extra_delay: 15,
    opening_hours_text: "OPEN",
    coordinates: { lat: 48.876899, lng: 2.382 },
    delivery_zones: [
      {
        id: 2,
        restaurant_id: 12,
        name: "test tata",
        zip_code: "75010",
        delivery_time: 15,
        delivery_charge: 10,
        min_price: 150,
        city: "Paris",
        deleted: false,
        type: 1,
        polygon: [],
        all_streets: true,
        force_web_payment: false,
        disabled: false,
      },
    ],
  },
  {
    id: 13,
    name: "Test",
    public_name: "public tata",
    ordering_available: false,
    logo_url: "https://logo-url.fr",
    address: "8 rue de paradis",
    cover_url: "https://logo-url.fr",
    phone: "0101010101",
    delivery_time: 0,
    delivery_charge: 0,
    delivery_minimum: 0,
    closures: [
      {
        start_date: dayjs().add(2, "day").toDate(),
        end_date: dayjs().add(3, "day").toDate(),
        message: "Closed",
      },
    ],
    opening_hours: dummyOpeningHours,
    takeaway_delay: 15,
    delivery_delay: 15,
    extra_delay: 15,
    opening_hours_text: "YOLO on est open",
    coordinates: { lat: 48.8785, lng: 2.3451 },
    delivery_zones: [
      {
        id: 2,
        restaurant_id: 12,
        name: "test tata",

        zip_code: "75010",
        delivery_time: 15,
        delivery_charge: 10,
        min_price: 150,
        city: "Paris",
        deleted: false,
        type: 1,
        polygon: [],
        all_streets: true,
        force_web_payment: false,
        disabled: false,
      },
    ],
  },
  {
    id: 14,
    name: "Tata",
    public_name: "public tata",
    ordering_available: false,
    logo_url: "https://logo-url.fr",
    address: "8 rue de paradis",
    phone: "0101010101",
    cover_url: "https://logo-url.fr",
    delivery_time: 0,
    delivery_charge: 0,
    delivery_minimum: 0,
    coordinates: { lat: 48.873, lng: 2.3563 },
    closures: [
      {
        start_date: dayjs().add(1, "day").toDate(),
        end_date: dayjs().add(2, "day").toDate(),
        message: "Closed",
      },
    ],
    opening_hours: dummyOpeningHours,
    takeaway_delay: 15,
    delivery_delay: 15,
    extra_delay: 15,
    opening_hours_text: "YOLO on est open",
    delivery_zones: [
      {
        id: 2,
        restaurant_id: 12,
        name: "test tata",
        zip_code: "75010",
        delivery_time: 15,
        delivery_charge: 10,
        min_price: 150,
        city: "Paris",
        deleted: false,
        type: 1,
        polygon: [],
        all_streets: true,
        force_web_payment: false,
        disabled: false,
      },
    ],
  },
  {
    id: 15,
    name: "Tata",
    public_name: "public tata",
    ordering_available: false,
    logo_url: "https://logo-url.fr",
    address: "8 rue de paradis",
    phone: "0101010101",
    delivery_time: 0,
    delivery_charge: 0,
    cover_url: "https://logo-url.fr",
    delivery_minimum: 0,
    coordinates: { lat: 48.872, lng: 2.3456 },
    closures: [
      {
        start_date: dayjs().add(1, "day").toDate(),
        end_date: dayjs().add(2, "day").toDate(),
        message: "Closed",
      },
    ],
    opening_hours: dummyOpeningHours,
    takeaway_delay: 15,
    delivery_delay: 15,
    extra_delay: 15,
    opening_hours_text: "YOLO on est open",
    delivery_zones: [
      {
        id: 2,
        restaurant_id: 12,
        name: "test tata",
        zip_code: "75010",
        delivery_time: 15,
        delivery_charge: 10,
        min_price: 150,
        city: "Paris",
        deleted: false,
        type: 1,
        polygon: [],
        all_streets: true,
        force_web_payment: false,
        disabled: false,
      },
    ],
  },
];
