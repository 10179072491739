import "./payment-stripe.scss";

import * as Sentry from "@sentry/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface StripeModalProps {
  show: boolean;
  totalBasketSummary: string;
  clientSecret: string;
  orderId: string;
  resetLocalOrder: () => void;
  setClientSecret: Dispatch<SetStateAction<string | null>>;
}

export const StripeModal = ({
  show,
  totalBasketSummary,
  clientSecret,
  orderId,
  resetLocalOrder,
  setClientSecret,
}: StripeModalProps) => {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();

  const handleClose = () => {
    setClientSecret(null);
  };

  const cardHandleChange = (e: any) => {
    const { error } = e;
    Sentry.captureException(error);
    setError(error ? error.message : null);
  };

  const handleCheckout = async () => {
    if (!stripe || !elements || !clientSecret) return;
    const card = elements.getElement(CardElement);
    if (!card) return;
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (payload.error) {
      Sentry.captureException(payload.error);
      setError(`Payment Failed: ${payload.error.message}`);
    } else {
      resetLocalOrder();
      navigate(`/orders/${orderId}`);
    }
  };

  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header>
        <h4>{t<string>("order.paymentDetails")}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="stripe-card">
          <CardElement
            className="card"
            options={{ hidePostalCode: true }}
            onChange={cardHandleChange}
          />
        </div>
        <Modal.Footer>
          <div className="m-auto">
            <button
              onClick={handleCheckout}
              className="btn btn-primary px-5"
              disabled={processing}
            >
              {processing
                ? t<string>("order.processing")
                : `${t<string>("order.pay")} : ${totalBasketSummary}`}
            </button>
          </div>

          {error ? <p className="error-message">{error} </p> : null}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
