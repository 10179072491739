import { mdiStorefrontOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import { getOrderModeFromIndex } from "shared/utils/module";

interface OrderSummaryProps {
  date: string;
  hours: string;
  restaurantName: string;
  order: ILocalOrder;
  isToday: boolean;
}

export const OrderSummary = ({
  isToday,
  date,
  hours,
  restaurantName,
  order,
}: OrderSummaryProps) => {
  const { t } = useTranslation();

  const orderMode = getOrderModeFromIndex(order.orderMode);

  return (
    <>
      <Row>
        <h5 className="order-subtitle">{t<string>("checkout.orderSummary")}</h5>
        <Col md={12} className="checkout-summary-item">
          <Icon path={mdiStorefrontOutline} size={0.8} />
          {restaurantName}
        </Col>
        <Col md={12} className="checkout-summary-item">
          {orderMode && <Icon path={orderMode.icon} size={0.8} />}
          {isToday
            ? t<string>(`checkout.summary.${orderMode?.id}.today`, {
                time: hours,
              })
            : t<string>(`checkout.summary.${orderMode?.id}.other`, {
                time: hours,
                date,
              })}
        </Col>
      </Row>
    </>
  );
};
