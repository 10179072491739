import { mdiStorefrontOutline, mdiWalk } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import moduleState from "shared/atoms/module.atom";
import RestaurantModal from "shared/components/RestaurantModal";
import ORDER_MODES from "shared/constants/orderModes.const";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import { IHydratedCatalog } from "shared/interfaces/Catalog.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { getClosestOpeningDate } from "shared/utils/date";

export interface RestaurantOrderInformationsProps {
  restaurant: IRestaurant;
  displayRestaurantModal: IRestaurant | null;
  setDisplayRestaurantModal: React.Dispatch<
    React.SetStateAction<IRestaurant | null>
  >;
  catalog: IHydratedCatalog | undefined;
  handleDisplayToast?: () => void;
}

export const RestaurantOrderInformations = ({
  restaurant,
  displayRestaurantModal,
  setDisplayRestaurantModal,
  catalog,
  handleDisplayToast,
}: RestaurantOrderInformationsProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { localOrder, setLocalOrder } = useLocalOrder();
  const { module } = useRecoilValue(moduleState);

  const closestDate = getClosestOpeningDate(
    restaurant,
    module.max_days,
    localOrder
  );

  const dateToDisplay = localOrder.due_date ?? closestDate;
  const dateToDisplayIsToday = dayjs().isSame(dateToDisplay, "day");

  const getIconMode = () => {
    const found = ORDER_MODES.find(
      (mode) => mode.value === localOrder.orderMode
    );
    if (found) {
      return found.icon;
    }
    return mdiWalk;
  };

  const handleOnSelectionUpdate = (updatedDate: Date) => {
    setLocalOrder({
      ...localOrder,
      due_date: updatedDate,
    });
    setDisplayRestaurantModal(null);
  };

  return (
    <>
      <div className="d-flex flex-column px-2">
        <div className="d-flex justify-content-between">
          <div>
            <span className="basket-icon-information">
              <Icon path={getIconMode()} size={0.8} />
            </span>

            {`${
              dateToDisplayIsToday
                ? t<string>("dates.today")
                : dayjs(dateToDisplay)
                    .locale(i18n.language)
                    .format(t<string>("topBar.deliveryDateFormat"))
            } ${t<string>("dates.at")}  
            ${dayjs(dateToDisplay).locale(i18n.language).format("HH:mm")}`}
          </div>
          <div
            className="modify-link"
            onClick={() => setDisplayRestaurantModal(restaurant)}
          >
            {" " + t<string>("commons.edit")}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>
            <span className="basket-icon-information">
              <Icon path={mdiStorefrontOutline} size={0.8} />
            </span>
            {restaurant.public_name}
          </div>
          <div
            onClick={() => navigate(routes.restaurants.path)}
            className="modify-link"
          >
            {" " + t<string>("commons.edit")}
          </div>
          <RestaurantModal
            activeRestaurant={displayRestaurantModal}
            onHide={() => {
              setDisplayRestaurantModal(null);
            }}
            onSelectionUpdate={(updatedDate) =>
              handleOnSelectionUpdate(updatedDate)
            }
            catalog={catalog}
            handleDisplayToast={handleDisplayToast}
          />
        </div>
      </div>
    </>
  );
};
