import "./preloader.scss";

import classNames from "classnames";
import Spinner from "shared/components/Spinner";

export function Preloader(props: { loaded: boolean }) {
  return (
    <div className={classNames("preloader", { loaded: props.loaded })}>
      <img className="preloader-logo" src="/logo64.png" alt="Zelty" />
      <Spinner visible={true} size="lg" />
    </div>
  );
}
