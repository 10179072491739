import { IOrderItem, IPostOrderItem } from "shared/interfaces/Order.interface";

import { convertModifiersToPostOrder } from ".";

/**
 * Convert a modifier item into a modifier ready to be used in post/order
 * @param modifiers
 * @returns
 */
export default (modifiers: IOrderItem[]): IPostOrderItem[] => {
  return modifiers.map((modifier: IOrderItem) => {
    return {
      ...(modifier.group_id && { group_id: modifier.group_id }),
      item_id: modifier.id,
      name: modifier.name,
      modifiers: convertModifiersToPostOrder(modifier.modifiers),
      unit_price: modifier.unit_price,
      type: modifier.type,
      quantity: modifier.quantity,
      disabled: modifier.disabled,
    };
  });
};
