import "./error.styles.scss";

import { mdiTruckFast } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ErrorProps {
  icon: string;
  translation: string;
  disabledButton?: boolean;
}

export const Error = ({
  icon = mdiTruckFast,
  translation = "errors.404",
  disabledButton = false,
}: ErrorProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const displayButton = disabledButton ? "d-none" : "d-block";
  return (
    <div className="error-container d-flex flex-column justify-content-center align-items-center mx-auto mt-4">
      <Icon path={icon} size={5} className="error-icon" />
      <p className="my-4 error-text">{t<string>(translation)}</p>
      <Button
        variant="primary"
        onClick={() => navigate("/")}
        className={`back-button ${displayButton}`}
      >
        {t<string>("commons.back")}
      </Button>
    </div>
  );
};
