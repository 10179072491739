import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ItemModalHeader from "shared/components/ItemModalHeader";
import ZImage from "shared/components/ZImage";
import {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { getItemImage } from "shared/utils/restaurant";

interface DishModalProps {
  item: IHydratedItemMenu | IHydratedItemDish;
  onClose: () => void;
}

export const ItemModalReadOnly = ({ item, onClose }: DishModalProps) => {
  const [show, setShow] = useState(true);
  function handleClose() {
    onClose();
    setShow(false);
  }
  const { t } = useTranslation();

  return (
    <Modal
      className="item-modal"
      show={show}
      size="sm"
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <ItemModalHeader name={item.name} />

      <Modal.Body className="modal-dish-body">
        <div className="dish-presentation-container">
          <ZImage src={getItemImage(item.image)} />
          <div className="dish-presentation">
            {item.description && (
              <Row className="mt-2">
                <span>{item.description}</span>
              </Row>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Col>
          <Button variant="default" className="btn-block" onClick={handleClose}>
            {t<string>("commons.back")}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
