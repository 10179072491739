//Style
import "./restaurant-basket.scss";

import { mdiBasket } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import HorizontalSeparator from "shared/components/HorizontalSeparator";
import RestaurantBasketSummary from "shared/components/RestaurantBasketSummary/index";
//Components
/**features for suggested products and promo are not yet ready for production */
// import RestaurantComplementaryProduct from "./RestaurantComplementaryProduct/index";
// import RestaurantPromoDesktop from "./RestaurantPromoDesktop/index";
// import RestaurantPromoMobile from "./RestaurantPromoMobile/index";
import RestaurantItemBasketCard from "shared/components/RestaurantItemBasketCard";
import {
  IHydratedCatalog,
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
//Utils
import { countQuantityItems } from "shared/utils/localOrder";
import { getOrderModeFromIndex } from "shared/utils/module";

import ConfirmButtonBasket from "./components/ConfirmButtonDesktop";
import MinimumAmountBasket from "./components/MinimumAmountBasket";
import RestaurantOrderInformations from "./components/RestaurantOrderInformations";

interface RestaurantBasketProps {
  localOrder: ILocalOrderState;
  localOrderIds: string[];
  totalBasket: string;
  setModifyItemOrder: React.Dispatch<
    React.SetStateAction<IOrderItem | undefined>
  >;
  setAddingItem: React.Dispatch<
    React.SetStateAction<IHydratedItemMenu | IHydratedItemDish | undefined>
  >;
  setModifyMenuOrder: React.Dispatch<
    React.SetStateAction<ICurrentMenuState | undefined>
  >;
  restaurant: IRestaurant;
  setShowBasket: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  allowEditRestaurant: boolean;
  catalog: IHydratedCatalog | undefined;
  canCheckout: boolean;
  handleDisplayToast?: () => void;
  minimumPrice: number;
  totalAmountBasket: number;
  catalogError?: boolean;
}

export const RestaurantBasket = ({
  localOrder,
  localOrderIds,
  totalBasket,
  setModifyItemOrder,
  setAddingItem,
  setModifyMenuOrder,
  restaurant,
  setShowBasket,
  allowEditRestaurant,
  catalog,
  canCheckout,
  handleDisplayToast,
  minimumPrice,
  totalAmountBasket,
  catalogError,
}: RestaurantBasketProps) => {
  const { t } = useTranslation();

  const orderMode = getOrderModeFromIndex(localOrder.orderMode);

  const [displayRestaurantModal, setDisplayRestaurantModal] =
    useState<IRestaurant | null>(null);
  useEffect(() => {
    if (!localOrder.due_date) {
      setDisplayRestaurantModal(restaurant);
    }
    // eslint-disable-next-line
  }, [localOrder.due_date]);

  return (
    <Container className="bg-white p-0 pb-1 custom-sticky-position position-sticky">
      <div className="p-2 text-center pt-4 basket-title-container">
        <div className="basket-icon">
          <Icon path={mdiBasket} size={1} className="color-zelty" />
        </div>
        <div className="basket-title">
          <span className="fw-bold">
            {t<string>("restaurantBasket.mainTitle")}
          </span>
        </div>
        <div className="basket-count-items">
          <small className="text-muted font-italic">
            ({countQuantityItems(localOrder)})
          </small>
        </div>
      </div>
      {allowEditRestaurant && (
        <RestaurantOrderInformations
          restaurant={restaurant}
          displayRestaurantModal={displayRestaurantModal}
          setDisplayRestaurantModal={setDisplayRestaurantModal}
          catalog={catalog}
          handleDisplayToast={handleDisplayToast}
        />
      )}
      <HorizontalSeparator />
      <div className="items-list-container px-4 ">
        {localOrderIds.length === 0 && (
          <div className="basket-empty-basket">
            <Icon path={mdiBasket} size={5} />
            <div>{t<string>("checkout.emptyBasket")}</div>
          </div>
        )}
        {localOrderIds.map((id) => (
          <RestaurantItemBasketCard
            key={id}
            item={localOrder.items[id]}
            setModifyItemOrder={setModifyItemOrder}
            setAddingItem={setAddingItem}
            setModifyMenuOrder={setModifyMenuOrder}
            isReadOnly={catalogError}
          />
        ))}
      </div>
      <div className="basket-total-container">
        <HorizontalSeparator />
        {/* features promo and suggested are not yet ready for production */}
        {/*
        <RestaurantComplementaryProduct />
        <div className="d-sm-none">
          <RestaurantPromoMobile />
        </div>
        <div className="d-none d-sm-block">
          <RestaurantPromoDesktop />
        </div>
        <HorizontalSeparator /> */}
        <RestaurantBasketSummary
          total={totalBasket}
          orderMode={orderMode?.id}
        />
        <div className="px-4">
          <ConfirmButtonBasket
            canCheckout={canCheckout}
            setShowBasket={setShowBasket}
          />
        </div>
        {!canCheckout && !!totalAmountBasket && (
          <MinimumAmountBasket minimumPrice={minimumPrice} />
        )}
      </div>
    </Container>
  );
};
