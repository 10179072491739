import { IOrderItem, IPostOrderItem } from "shared/interfaces/Order.interface";

import { convertModifiersToPostOrder } from ".";

/**
 * Convert an local order item into a order item ready to be used in post order
 * @param item
 * @returns
 */
export default (items: IOrderItem[]): IPostOrderItem[] => {
  return items.map((value: IOrderItem) => {
    return {
      ...(value.group_id && { group_id: value.group_id }),
      item_id: value.id,
      name: value.name,
      modifiers: convertModifiersToPostOrder(value.modifiers),
      unit_price: value.unit_price,
      type: value.type,
      quantity: value.quantity,
      disabled: value.disabled,
    };
  });
};
