import { Location } from "history";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import usePathname from "shared/hooks/usePathname.hook";

interface AnalyticsEventsProps {
  children: JSX.Element;
  trackingId?: string;
}

export const AnalyticsEvents = ({
  children,
}: AnalyticsEventsProps): JSX.Element => {
  const history = usePathname();
  const location = useLocation();

  const sendPageView = (location: Location): void => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
    ReactPixel.pageView();
  };

  useEffect((): void => {
    sendPageView(location);
    //  eslint-disable-next-line
  }, [history]);

  return children;
};
