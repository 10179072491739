import "./spinner.scss";

import classNames from "classnames";

interface SpinnerProps {
  visible?: boolean;
  size?: "sm" | "md" | "lg";
}

export function Spinner({ visible, size = "md" }: SpinnerProps) {
  return (
    <div
      className={classNames("spinner", `spinner-${size}`, {
        visible,
      })}
    >
      <div className="spinner-circle" />
    </div>
  );
}
