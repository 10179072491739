import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IModule from "shared/interfaces/Module.interface";

interface CheckoutCgvPrivacyProps {
  cgvPrivacy: string | null;
  module: IModule;
  cgv: boolean;
}

export const CheckoutCgvPrivacy = ({
  cgvPrivacy,
  module,
  cgv,
}: CheckoutCgvPrivacyProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  if (!cgvPrivacy) return null;

  return (
    <>
      {(cgvPrivacy === "cgv_url" || cgvPrivacy === "cgv_privacy") && (
        <>
          <div className="d-flex align-items-center mt-sm-3">
            <Form.Check.Label className="opt-in custom-label-optin">
              <Controller
                control={control}
                name="cgv"
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Check
                    {...field}
                    type="checkbox"
                    className="me-2 h-25 custom-checkbox d-flex align-items-center"
                    checked={cgv}
                    required
                  />
                )}
              />

              <span
                dangerouslySetInnerHTML={{
                  __html: t<string>(`checkout.cgv.cgv_url`, {
                    cgv: module.cgv_url,
                  }),
                }}
              />
            </Form.Check.Label>
          </div>
        </>
      )}
    </>
  );
};
