import "./dish-option-title.scss";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IHydratedOption } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { countModifierOption } from "shared/utils/order";

interface DishOptionTitleProps {
  option: IHydratedOption;
  currentDish: IOrderItem;
}

export const DishOptionTitle = ({
  option,
  currentDish,
}: DishOptionTitleProps) => {
  const { t } = useTranslation();
  const maxChoiceIsOne = option.maximum_choices === 1;
  const quantityMandatoryText = useMemo(() => {
    if (option.minimum_choices) {
      return maxChoiceIsOne
        ? null
        : t<string>("itemModal.optionMinAndMax", {
            minimum: option.minimum_choices,
            maximum: option.maximum_choices,
          });
    }

    if (option.maximum_choices) {
      return t<string>("itemModal.optionMaximum", {
        maximum: option.maximum_choices,
      });
    }
  }, [option.minimum_choices, option.maximum_choices]);

  return (
    <div className="option-value-title mt-4 mb-3 d-flex flex-column align-items-end mx-1">
      <div className="d-flex justify-content-between ms-3 w-100 mb-1">
        <h5 className="custom-option-title overflow-hidden">{option.name}</h5>
        {option.maximum_choices && !maxChoiceIsOne ? (
          <h5 className="custom-option-title">
            {countModifierOption(option, currentDish)}/{option.maximum_choices}
          </h5>
        ) : (
          <h5 className="minimum-choice mb-0 d-flex text-nowrap">
            {t<string>("itemModal.optionMinimum")}
          </h5>
        )}
      </div>
      {quantityMandatoryText && (
        <h5 className="minimum-choice mb-0 d-flex text-nowrap flex-grow-1">
          {quantityMandatoryText}
        </h5>
      )}
    </div>
  );
};
