import "./basket-mobile-button.scss";

import { mdiBasket } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import RestaurantBasket from "shared/components/RestaurantBasket";
import {
  IHydratedCatalog,
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

interface BasketMobileButtonProps {
  totalBasket: string;
  localOrderIds: string[];
  localOrder: ILocalOrderState;
  setModifyItemOrder: React.Dispatch<
    React.SetStateAction<IOrderItem | undefined>
  >;
  setAddingItem: React.Dispatch<
    React.SetStateAction<IHydratedItemMenu | IHydratedItemDish | undefined>
  >;
  setModifyMenuOrder: React.Dispatch<
    React.SetStateAction<ICurrentMenuState | undefined>
  >;
  restaurant: IRestaurant;
  catalog: IHydratedCatalog | undefined;
  canCheckout: boolean;
  minimumPrice: number;
  totalAmountBasket: number;
  catalogError?: boolean;
}

export const BasketMobileButton = ({
  totalBasket,
  localOrderIds,
  localOrder,
  setModifyItemOrder,
  setAddingItem,
  setModifyMenuOrder,
  restaurant,
  catalog,
  canCheckout,
  minimumPrice,
  totalAmountBasket,
  catalogError,
}: BasketMobileButtonProps) => {
  const { t } = useTranslation();

  const [showBasket, setShowBasket] = useState<boolean | undefined>(false);
  return (
    <>
      <Modal
        show={showBasket}
        onHide={() => setShowBasket(false)}
        size="xl"
        centered
        scrollable
      >
        <RestaurantBasket
          allowEditRestaurant={false}
          {...{
            localOrder,
            localOrderIds,
            totalBasket,
            setModifyItemOrder,
            setAddingItem,
            setModifyMenuOrder,
            restaurant,
            setShowBasket,
            catalog,
            canCheckout,
            minimumPrice,
            totalAmountBasket,
            catalogError,
          }}
        />
      </Modal>

      <div className="d-flex justify-content-center pb-2">
        {localOrderIds.length ? (
          <button
            className="btn btn-primary btn-mobile-basket d-flex justify-content-between align-items-center"
            onClick={() => setShowBasket(!showBasket)}
          >
            <Icon path={mdiBasket} size={1} className="mx-2" />
            <span>{t<string>("checkout.displayBasket")}</span>
            <span>{totalBasket}</span>
          </button>
        ) : (
          <button
            className="btn btn-secondary btn-mobile-basket d-flex justify-content-between align-items-center"
            disabled
          >
            <Icon path={mdiBasket} size={1} className="mx-2" />
            <span>{t<string>("checkout.displayBasket")}</span>
            <span>{totalBasket}</span>
          </button>
        )}
      </div>
    </>
  );
};
