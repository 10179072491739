import dayjs from "dayjs";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

/**
 * Check if the current order is valid for the current context
 *
 * The current time must not exceed the order due_date + the delay period specified by the restaurant
 *
 * @param order
 * @returns {boolean} order status
 */
export default (order: ILocalOrder, restaurant: IRestaurant): boolean => {
  const dueDate = order.due_date;
  const delay = restaurant.delivery_time || 0;
  if (!dueDate) {
    return false;
  }

  // if due date + delivery delay is in the past
  if (dayjs(dueDate).isAfter(dayjs().add(delay, "minutes"))) {
    return true;
  }

  return false;
};
