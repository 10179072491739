import "./input.scss";

import React from "react";
import { FloatingLabel, Form, FormControlProps } from "react-bootstrap";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

interface IInputProps extends FormControlProps {
  label?: string;
  errorMessages?: string[];
  infoLabel?: string;
  controlId?: string;
}

export const Input: BsPrefixRefForwardingComponent<"input", IInputProps> =
  React.forwardRef<FormControlElement, IInputProps>(
    ({ label, errorMessages, infoLabel, ...props }, ref) => {
      return (
        <FloatingLabel
          label={label}
          controlId={props.controlId}
          className="input-container"
        >
          <Form.Control {...props} ref={ref} />
          {infoLabel && (
            <Form.Text className="text-muted">{infoLabel}</Form.Text>
          )}
          {errorMessages?.map((message, index) => {
            return (
              <Form.Control.Feedback type="invalid" key={index}>
                {message}
              </Form.Control.Feedback>
            );
          })}
        </FloatingLabel>
      );
    }
  );
