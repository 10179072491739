import { findIndex } from "lodash";
import ICatalog, {
  IHydratedItemDish,
  IItemDish,
} from "shared/interfaces/Catalog.interface";

import { hydrateOption } from ".";

export default (
  dish: IItemDish,
  catalog: ICatalog,
  group_id?: string
): IHydratedItemDish => {
  let hydratedItemDish: IHydratedItemDish = {
    ...dish,
    ...(group_id && { group_id }),
    options: [],
  };

  for (const optionId of dish.option_ids) {
    const optionIndex = findIndex(catalog.options, { id: optionId });
    if (~optionIndex) {
      const hydratedOption = hydrateOption(
        catalog.options[optionIndex],
        catalog
      );
      hydratedItemDish.options.push(hydratedOption);
    }
  }

  return hydratedItemDish;
};
