import ReactPixel from "react-facebook-pixel";
import { ga } from "react-ga";
import { ANALYTICS_KEYS, FB_KEYS } from "shared/constants/analytics.const";
import { SessionEvent } from "shared/interfaces/Analytics.interface";

export default (eventKey: ANALYTICS_KEYS, sessionEvent: SessionEvent): void => {
  const price = sessionEvent.price || 0;

  ga("send", {
    eventLabel: eventKey,
    price,
    items: sessionEvent.items,
  });

  const fbEventKey = FB_KEYS[eventKey] || eventKey;

  ReactPixel.fbq("track", fbEventKey, {
    content_type: sessionEvent.items,
    value: price,
    currency: "EUR",
  });
};
