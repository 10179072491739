import { IOptionValue } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

import { findOptionValue } from ".";

export default (
  currentDish: IOrderItem,
  currentValue: IOptionValue
): number => {
  const modifierFound = findOptionValue(currentDish, currentValue);
  return modifierFound ? modifierFound.quantity : 0;
};
