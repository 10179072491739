import amplitude from "amplitude-js";

const amplitudeInit = (): void => {
  amplitude
    .getInstance()
    .init(`${process.env.REACT_APP_AMPLITUDE_KEY}`, undefined, {
      serverZone: "EU",
      serverZoneBasedApi: true,
    });
};

export default amplitudeInit;
