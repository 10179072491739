import { TFunction } from "react-i18next";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

export default (t: TFunction<"translation", undefined>) =>
  Yup.object().shape({
    first_name: Yup.string().required(
      t("checkout.firstName.formErrorRequired")
    ),
    last_name: Yup.string().required(t("checkout.lastName.formErrorRequired")),
    email: Yup.string()
      .email(t("checkout.email.formErrorWrongFormat"))
      .required(t("checkout.email.formErrorRequired")),
    phone: Yup.string()
      .test(
        "isValidPhoneNumber",
        t("checkout.phoneNumber.formErrorWrongFormat"),
        (value) => isValidPhoneNumber(value || "")
      )
      .required(t("checkout.phoneNumber.formErrorRequired")),
    comment: Yup.string(),
    mail_optin: Yup.boolean(),
    sms_optin: Yup.boolean(),
    cgv: Yup.boolean(),
  });
