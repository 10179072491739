import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import routes from "routes/routes";
import localOrderState, {
  ILocalOrderState,
} from "shared/atoms/localOrder.atom";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import restaurantsListAtom from "shared/atoms/restaurantsList.atom";
import { IOrderItem } from "shared/interfaces/Order.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { getClosestOpeningDate } from "shared/utils/date";
import {
  addItemAndReturnNewState,
  isValidDueDate,
  modifyItemAndReturnNewState,
  removeItemAndReturnNewState,
  removeItemByIdAndReturnNewState,
  resetBasketIfModifiedIsExpired,
  updateLocalOrderModified,
} from "shared/utils/localOrder";
import { getOrderModeFromId, isValidOrderMode } from "shared/utils/module";
import { getRestaurantById } from "shared/utils/restaurant";

const useLocalOrder = () => {
  const [localOrder, sSetLocalOrder] = useRecoilState(localOrderState);

  const navigate = useNavigate();

  const params = useParams<{ orderMode: string; restaurantId?: string }>();
  const restaurantsListState = useRecoilValue(restaurantsListAtom);
  const restaurants = restaurantsListState.restaurants;
  const { module, moduleLoaded }: IModuleState = useRecoilValue(moduleState);

  if (moduleLoaded && routes.checkUrlParams) {
    checkOrderUrlParameters();
  }

  const setLocalOrder = (orderState: ILocalOrderState) =>
    sSetLocalOrder(orderState);

  useEffect(() => {
    checkOrderDueDate();
    // eslint-disable-next-line
  }, [restaurants]);

  function checkOrderDueDate() {
    // redirect to restaurants list if invalid due_date
    const dueDate = localOrder.due_date;

    if (!dueDate) {
      return;
    }

    const restaurant = getRestaurantById(
      localOrder.restaurant_id || 0,
      restaurants
    );
    if (!restaurant) {
      return;
    }

    if (!isValidDueDate(module, restaurant, dueDate.toString())) {
      // order date has expired, try to find the next one available

      const nextDate = getClosestOpeningDate(
        restaurant,
        module.max_days,
        localOrder,
        true
      );

      // no next date, mark the order as expired
      if (!nextDate) {
        setLocalOrder({ ...localOrder, expired: true, due_date: undefined });
        return;
      }

      // update the order with the next available date
      setLocalOrder({ ...localOrder, due_date: nextDate });
    }
    //Check if basket last modified date is expired and reformat it if needed
    const newState = resetBasketIfModifiedIsExpired(localOrder);
    setLocalOrder(newState);
  }
  function modifyItem(item: IOrderItem) {
    const newState = modifyItemAndReturnNewState(localOrder, item);
    if (!newState) return;
    const newStateDateUpdated = updateLocalOrderModified(newState);
    setLocalOrder(newStateDateUpdated);
  }

  function addItem(item: IOrderItem, quantity: number = 1) {
    const newState = addItemAndReturnNewState(localOrder, item, quantity);
    if (!newState) return;
    const newStateDateUpdated = updateLocalOrderModified(newState);
    setLocalOrder(newStateDateUpdated);
  }

  function removeItem(item: IOrderItem) {
    const newState = removeItemAndReturnNewState(localOrder, item);
    if (!newState) return;
    const newStateDateUpdated = updateLocalOrderModified(newState);
    setLocalOrder(newStateDateUpdated);
  }

  function removeItemById(itemId: string) {
    const newState = removeItemByIdAndReturnNewState(localOrder, itemId);
    if (!newState) return;
    const newStateDateUpdated = updateLocalOrderModified(newState);
    setLocalOrder(newStateDateUpdated);
  }

  function setRestaurant(restaurant: IRestaurant) {
    setLocalOrder({
      ...localOrder,
      restaurant_id: restaurant.id,
    });
  }

  function resetLocalOrder() {
    setLocalOrder({
      items: {},
      expired: false,
      restaurant_id: 0,
    });
  }

  /**
   * Check the url parameters and set the local order state if needed
   *
   * If a parameter is invalid, redirect to the needed page
   */
  function checkOrderUrlParameters() {
    if (params.orderMode) {
      const orderMode = getOrderModeFromId(params.orderMode);
      // redirect to mode page if invalid order mode
      if (!isValidOrderMode(orderMode, module)) {
        navigate("/");
        return;
      }

      // update the local order type if it has been changed
      if (orderMode?.value !== localOrder.orderMode) {
        setLocalOrder({
          ...localOrder,
          orderMode: orderMode?.value || undefined,
        });
      }
    }

    if (params.restaurantId && restaurants.length > 0) {
      // check if restaurant is valid
      const restaurant = restaurants.find(
        (r) => r.id === parseInt(String(params.restaurantId))
      );

      // return to restaurants list if restaurant is invalid
      if (!restaurant) {
        navigate("/");
        return;
      }

      if (restaurant.id !== localOrder.restaurant_id) {
        setLocalOrder({
          ...localOrder,
          restaurant_id: restaurant.id,
          delivery_address: { formatted_address: restaurant.address },
        });
      }
    }
  }

  return {
    localOrder,
    setLocalOrder,
    setRestaurant,
    removeItem,
    addItem,
    modifyItem,
    resetLocalOrder,
    removeItemById,
  };
};

export default useLocalOrder;
