import "./items-recap.scss";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import useCurrentMenu from "shared/hooks/useCurrentMenu.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import { IHydratedItemMenu } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { formatPriceForBasket } from "shared/utils/commons";
import { getTotalPrice } from "shared/utils/localOrder";

import ItemRecapCard from "./ItemRecapCard";

interface ItemRecapProps {
  handleClose: () => void;
  item: IHydratedItemMenu;
  modifyMenuOrder: ICurrentMenuState | undefined;
  modifyItemOrder: IOrderItem | undefined;
}

export const ItemsRecap = ({
  handleClose,
  item,
  modifyMenuOrder,
  modifyItemOrder,
}: ItemRecapProps) => {
  const { t } = useTranslation();

  const { currentMenuState } = useCurrentMenu();
  const { addItem, modifyItem } = useLocalOrder();

  const basketHasMinimumMenuParts = currentMenuState.modifiers.every(
    (menuPart) => {
      return menuPart.items.length >= menuPart.min;
    }
  );
  const isBasketEmpty = !currentMenuState.modifiers.some(
    (menuPart) => menuPart.items.length
  );

  const canConfirmBasket = basketHasMinimumMenuParts && !isBasketEmpty;

  const listItems = currentMenuState.modifiers
    .map((menuPart) => menuPart.items.length > 0 && menuPart.items)
    .filter((item) => item);
  const totalPrice = listItems.reduce(
    (sum, item) =>
      sum +
      (item
        ? item.reduce(
            (insideSum, insideItem) => getTotalPrice([insideItem]) + insideSum,
            0
          )
        : 0),
    currentMenuState.unit_price
  );
  const totalBasket = basketHasMinimumMenuParts
    ? formatPriceForBasket(totalPrice * currentMenuState.quantity)
    : null;

  const handleSubmit = (): void => {
    const flatItemsList = listItems.flat() as IOrderItem[];
    if (modifyMenuOrder && modifyItemOrder) {
      modifyItem({
        id: currentMenuState.id,
        entityId: modifyItemOrder.entityId,
        unit_price: currentMenuState.unit_price,
        quantity: currentMenuState.quantity,
        name: currentMenuState.name,
        type: "menu",
        modifiers: flatItemsList,
        disabled: currentMenuState.disabled,
        modalItem: { addedItem: item, addedMenu: currentMenuState },
      });
      return handleClose();
    }
    addItem({
      id: currentMenuState.id,
      unit_price: currentMenuState.unit_price,
      quantity: currentMenuState.quantity,
      name: currentMenuState.name,
      type: "menu",
      modifiers: flatItemsList,
      disabled: currentMenuState.disabled,
      modalItem: { addedItem: item, addedMenu: currentMenuState },
    });

    handleClose();
  };

  return (
    <div className="bg-white p-4 pb-5 position-sticky top-0 container-recap d-flex flex-column justify-content-between my-4 my-sm-0">
      <div className="main">
        <h5 className="color-zelty fw-bold py-2">
          {t<string>("menuSummaryBasket.title")}
        </h5>
        <div className="recap-body mb-4 ">
          {currentMenuState.modifiers.length > 0 && (
            <ul>
              {currentMenuState.modifiers.map(
                (itemSelection: {
                  min: number;
                  max: number;
                  menuPartName: string;
                  menuPartId: string;
                  items: IOrderItem[];
                }) => {
                  return itemSelection.items.length > 0
                    ? itemSelection.items.map((item: IOrderItem) => (
                        <ItemRecapCard key={item.id} {...item} />
                      ))
                    : null;
                }
              )}
            </ul>
          )}
        </div>
      </div>
      <div className="button-container d-none d-sm-block">
        <Button
          variant="default"
          className="btn-block fw-bold my-2 mt-5 text-button"
          onClick={handleClose}
        >
          {t<string>("commons.cancel")}
        </Button>

        <Button
          variant={canConfirmBasket ? "primary" : "secondary"}
          type="submit"
          className="btn-block fw-bold text-button"
          disabled={!canConfirmBasket}
          onClick={handleSubmit}
        >
          {t<string>("commons.confirm")}
          {canConfirmBasket && ` (${totalBasket})`}
        </Button>
      </div>
      <div className="container-button-mobile d-flex justify-content-center d-sm-none position-fixed w-100">
        <Button
          variant={canConfirmBasket ? "primary" : "secondary"}
          type="submit"
          className="fw-bold text-button w-100"
          onClick={handleSubmit}
          disabled={!canConfirmBasket}
        >
          {t<string>("commons.confirm")}
          {canConfirmBasket && ` (${totalBasket})`}
        </Button>
      </div>
    </div>
  );
};
