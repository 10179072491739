import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import ICustomer from "shared/interfaces/Customer.interface";

const { persistAtom } = recoilPersist();

const customer = {
  first_name: "",
  last_name: "",
  email: "",
};
export interface IUserState {
  customer: ICustomer;
  token?: string;
  isLoggedIn: boolean;
}

const userState = atom<IUserState>({
  key: "userState",
  default: { customer, isLoggedIn: false },
  effects_UNSTABLE: [persistAtom],
});

export default userState;
