import { AxiosResponse } from "axios";
import api from "services/api/api";
import IModule from "shared/interfaces/Module.interface";

interface IAuthenticateResponse {
  data: {
    token: string;
    module: IModule;
  };
}

function authenticate() {
  let host = window.location.host;
  if (process.env.NODE_ENV === "development") {
    // host = "test-restaurant.fr";
    host = "order.test.zelty.co";
    // host = "demo.web-ordering.staging.zelty.co";
  }

  return api.get(`/modules/authenticate?domain=${host}`, {
    params: "authenticate",
  }) as Promise<AxiosResponse<IAuthenticateResponse>>;
}

const authenticateWithBearer = () =>
  authenticate().then((res) => {
    // update the api instance to include the token in each request
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.data.data.token}`;
    return res;
  });
export default authenticateWithBearer;
