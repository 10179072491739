import "./z-image.scss";

import classNames from "classnames";
import { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Spinner from "shared/components/Spinner";

const fallbackUrl = "/missing.png";

interface ZImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  disablespinner?: boolean;
}
/**
 * Replace the <img> tag
 *
 * Support all the classic props
 *
 * @returns
 */
export default function ZImage({
  className = "",
  alt,
  width,
  height,
  src,
  disablespinner,
}: ZImageProps) {
  const [loaded, setLoaded] = useState(false);
  // indicate if the image "can" be loaded
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);

  function handleError() {
    if (loaded) {
      return;
    }

    setError(true);
    // prevent handlers to rerun
    setLoaded(true);
  }

  function handleVisible(isVisible: boolean) {
    if (isVisible) {
      setVisible(true);
    }
  }

  /**
   * Force error mode if no url is provided
   */
  useEffect(() => {
    if (!src) {
      handleError();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <VisibilitySensor onChange={handleVisible}>
      <div
        className={classNames("z-image", {
          loaded,
          visible,
          error,
        })}
        style={{
          width,
          height,
        }}
      >
        {!disablespinner && <Spinner visible={true} size={"md"} />}
        {visible && !error && (
          <img
            className={`z-image-original ${className}`}
            {...{ src, alt, width, height }}
            onLoad={() => {
              setLoaded(true);
            }}
            onError={handleError}
            style={{
              width,
              height,
            }}
          />
        )}

        {error && (
          <img
            className={`z-image-original ${className}`}
            {...{ alt, width, height }}
            src={fallbackUrl}
            style={{
              width,
              height,
            }}
          />
        )}
      </div>
    </VisibilitySensor>
  );
}
