import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";

import "./restaurants-list.scss";

import { MapContainer, Marker, TileLayer } from "@monsonjeremy/react-leaflet";
import L from "leaflet";
import { useEffect, useState } from "react";
import { Instagram } from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import localOrderState from "shared/atoms/localOrder.atom";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import useFetchRestaurants from "shared/hooks/useFetchRestaurants.hook";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { canOrder } from "shared/utils/restaurant";

import {
  ChangeView,
  LocationMarker,
  RestaurantsListHeader,
} from "./components";

export function RestaurantsList() {
  // const [, setCoordinates] = useState<number[] | null>();
  const navigate = useNavigate();
  const localOrder = useRecoilValue(localOrderState);
  const [allData, setAllData] = useState<IRestaurant[]>([]);
  const [filteredData, setFilteredData] = useState<IRestaurant[]>(allData);
  const [
    filteredDataHasRestaurantWithCoordinates,
    setFilteredDataHasRestaurantWithCoordinates,
  ] = useState<boolean>(false);

  const { data: restaurants, isLoading: loadingRestaurants } =
    useFetchRestaurants();

  const { module }: IModuleState = useRecoilValue(moduleState);

  //    Function unused yet

  // const { data: restaurantAddress, isLoading } = useFetchAddress(address);

  // useEffect(() => {
  //   if (restaurantAddress) {
  //     setCoordinates(restaurantAddress.features[0].geometry.coordinates);
  //   }
  // }, [restaurantAddress]);

  const customMarker = new L.Icon({
    iconUrl: "https://fonts.gstatic.com/s/i/materialicons/place/v17/24px.svg",
    iconRetinaUrl:
      "https://fonts.gstatic.com/s/i/materialicons/place/v17/24px.svg",
    iconSize: [36, 36],
    iconAnchor: [18, 36],
  });

  useEffect(() => {
    if (restaurants) {
      if (
        restaurants.length === 1 &&
        canOrder(restaurants[0], module.max_days, localOrder)
      ) {
        return navigate(`${routes.restaurants.path}/${restaurants[0].id}`);
      }
      setAllData(restaurants);
      setFilteredData(restaurants);
      const restaurantHasCoordinates = restaurants.find(
        (r) => r.coordinates.lat && r.coordinates.lng
      );
      setFilteredDataHasRestaurantWithCoordinates(!!restaurantHasCoordinates);
    }
  }, [restaurants]);

  const handleMapClick = (id: string) => {
    let element = document.getElementById(id);
    if (element) {
      element.classList.add("clicked-plan");
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        element && element.classList.remove("clicked-plan");
      }, 1000);
    }
  };

  return (
    <div className="restaurants-map">
      {restaurants ? (
        <RestaurantsListHeader
          {...{
            allData,
            setFilteredData,
            restaurants,
            setFilteredDataHasRestaurantWithCoordinates,
            loadingRestaurants,
            filteredData,
            hasMap: !module.deactivate_map,
          }}
        />
      ) : null}

      {loadingRestaurants && (
        <div>
          <Instagram viewBox={"0 100 300 300"} />
        </div>
      )}
      {!loadingRestaurants &&
        !module.deactivate_map &&
        filteredDataHasRestaurantWithCoordinates && (
          <div className="map-container-absolute">
            <MapContainer
              className="map-container"
              center={[48.86, 2.333]}
              zoom={12}
              scrollWheelZoom={true}
              style={{ height: "100%" }}
            >
              <ChangeView markers={filteredData} />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
              />
              <LocationMarker />
              {filteredData.map((restaurant: IRestaurant) => {
                return (
                  restaurant.coordinates.lat &&
                  restaurant.coordinates.lng && (
                    <Marker
                      key={restaurant.id}
                      eventHandlers={{
                        click: () =>
                          handleMapClick(`restaurant-${restaurant.id}`),
                      }}
                      position={[
                        restaurant.coordinates.lat,
                        restaurant.coordinates.lng,
                      ]}
                      icon={customMarker}
                    />
                  )
                );
              })}
            </MapContainer>
          </div>
        )}
    </div>
  );
}
