import "./comment-form.scss";

import { useState } from "react";
import { Collapse, FloatingLabel, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const CommentForm = () => {
  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <span
        className={`collapse-form-comment-text my-1 ${
          showCommentForm ? "d-none" : "d-block"
        }`}
        onClick={() => setShowCommentForm(true)}
        aria-controls="formComment"
        aria-expanded={showCommentForm}
      >
        {t<string>("checkout.addComment")}
      </span>
      <Collapse in={showCommentForm}>
        <FloatingLabel
          id="formComment"
          controlId="floatingTextarea2"
          label={t<string>("checkout.comment")}
          className="input-font-size-custom"
        >
          <Form.Control
            as="textarea"
            placeholder={t<string>("checkout.comment")}
            style={{ height: "100px" }}
            className="input-font-size-custom comment-input"
            maxLength={50}
            name="comment"
          />
        </FloatingLabel>
      </Collapse>
    </>
  );
};
