interface IORDER_STATUS {
  description: string;
  orderStatus: string;
  reactOrderStatus: string;
  traductionKey: string;
}

const ORDER_STATUS: IORDER_STATUS[] = [
  {
    description:
      "La commande est enregistrée par le back et doit être payée en ligne avant d'être validée",
    orderStatus: "waiting_for_payment",
    reactOrderStatus: "waiting",
    traductionKey: "order.status.waiting",
  },

  {
    description: "La commande est validée et va être transmis au restaurant",
    orderStatus: "payed_or_validated",
    reactOrderStatus: "validated",
    traductionKey: "order.status.validated",
  },

  {
    description: "transmise à la caisse",
    orderStatus: "sent_to_pos",
    reactOrderStatus: "validated",
    traductionKey: "order.status.validated",
  },

  {
    description: "envoyée en production",
    orderStatus: "in_prod",
    reactOrderStatus: "preparation",
    traductionKey: "order.status.preparation",
  },

  {
    description: "commande marquée comme prête (quand le restaurant a un KDS)",
    orderStatus: "ready",
    reactOrderStatus: "ready",
    traductionKey: "order.status.ready",
  },

  {
    description:
      "en cours de livraison (pour les commandes en livraison uniquement)",
    orderStatus: "delivery_in_progress",
    reactOrderStatus: "delivery",
    traductionKey: "order.status.delivery",
  },

  {
    description: "livrée ou récupérée",
    orderStatus: "delivered_or_collected",
    reactOrderStatus: "completed",
    traductionKey: "order.status.completed",
  },

  {
    description: "commande annulée",
    orderStatus: "cancelled",
    reactOrderStatus: "canceled",
    traductionKey: "order.status.canceled",
  },

  {
    description: "reçue par la caisse",
    orderStatus: "accepted_by_pos",
    reactOrderStatus: "validated",
    traductionKey: "order.status.validated",
  },
  {
    description: "refusée par la caisse (erreur de syncho par exemple)",
    orderStatus: "denied_by_pos",
    reactOrderStatus: "canceled",
    traductionKey: "order.status.canceled",
  },
];

export default ORDER_STATUS;
