import { IOrderItem } from "shared/interfaces/Order.interface";

export default (currentItem: IOrderItem, otherItem: IOrderItem) => {
  if (currentItem.modifiers.length !== otherItem.modifiers.length) return false;

  return (
    JSON.stringify(currentItem.modifiers) ===
    JSON.stringify(otherItem.modifiers)
  );
};
