import dayjs from "dayjs";
import { findIndex } from "lodash";
import ORDER_MODES from "shared/constants/orderModes.const";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IRestaurant, { DayOfWeek } from "shared/interfaces/Restaurant.interface";
import { formatDateWithHourAndMinutes } from "shared/utils/date";
import { getOrderDelayRestaurantFromOrderMode } from "shared/utils/restaurant";

/**
 * Generate a list of all available opening hours for the restaurant for the given date
 * @param restaurant
 * @param date
 * @returns
 */
export default (
  restaurant: IRestaurant,
  date: Date,
  localOrder?: ILocalOrder,
  //TODO: fix with dynamic data from back when its ready
  timeInterval: number = 15
): Date[] => {
  let orderMode = "";
  const orderModeId = ORDER_MODES.find(
    (mode) => mode.value === localOrder?.orderMode
  );
  orderMode = orderModeId?.id || "";

  let orderDelay = getOrderDelayRestaurantFromOrderMode(orderMode, restaurant);
  const isToday = dayjs().isSame(date, "day");
  const times: Date[] = [];
  const dayOfWeek = dayjs(date).day();
  //get the weekday in english and lowercase
  const dayLabel = dayjs()
    .locale("en")
    .weekday(dayOfWeek)
    .format("dddd")
    .toLowerCase();

  const entryIndex = findIndex(restaurant.opening_hours, {
    day_of_week: dayLabel as DayOfWeek,
  });

  if (~entryIndex) {
    const openingDay = restaurant.opening_hours[entryIndex];

    for (const timePeriod of openingDay.time_periods) {
      const dateFormatted = dayjs(date).toDate();

      const start = formatDateWithHourAndMinutes(
        timePeriod.start_time,
        dateFormatted
      )
        .add(orderDelay, "minute")
        .second(0);

      let current = start;
      const end = formatDateWithHourAndMinutes(
        timePeriod.end_time,
        dateFormatted
      ).second(0);

      while (current.isBefore(end, "hour")) {
        // if it's today, skip all times before now
        if (isToday) {
          if (
            current.isBefore(
              dayjs().add((restaurant?.extra_delay ?? 0) + orderDelay, "minute")
            )
          ) {
            current = current.add(timeInterval, "minutes");
            continue;
          }
        }

        times.push(current.toDate());
        current = dayjs(current).add(timeInterval, "minutes");
      }
    }
  }

  return times;
};
