import { useTranslation } from "react-i18next";
import IRestaurant from "shared/interfaces/Restaurant.interface";

interface CheckoutButtonProps {
  formIsCompleted: boolean;
  canCheckout: boolean;
  restaurant: IRestaurant | undefined;
  restaurantId: string | undefined;
}

export const CheckoutButtons = ({
  formIsCompleted,
  canCheckout,
}: CheckoutButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="buttons-container">
      <button
        className={`btn ${
          !formIsCompleted ? "btn-secondary" : "btn-primary"
        } w-100`}
        type="submit"
        disabled={!formIsCompleted && canCheckout}
      >
        {t<string>("checkout.validationBasket")}
      </button>
    </div>
  );
};
