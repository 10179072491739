import dayjs from "dayjs";
import { useMemo } from "react";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { latestHourOfToday } from "shared/utils/date";

export default (restaurant: IRestaurant) =>
  useMemo(() => {
    const lastHour = latestHourOfToday(restaurant);
    return dayjs().isBetween(
      dayjs(lastHour).subtract(15, "minute"),
      dayjs(lastHour)
    );
  }, []);
