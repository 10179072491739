import { findIndex } from "lodash";
import ICatalog, {
  IHydratedMenuPart,
  IMenuPart,
} from "shared/interfaces/Catalog.interface";

import { hydrateDish } from ".";

export default (menuPart: IMenuPart, catalog: ICatalog): IHydratedMenuPart => {
  let hydratedMenuPart: IHydratedMenuPart = {
    ...menuPart,
    dishes: [],
  };

  for (const dishId of menuPart.dish_ids) {
    const dishIndex = findIndex(catalog.dishes, { id: dishId });
    if (~dishIndex) {
      const hydratedDish = hydrateDish(
        catalog.dishes[dishIndex],
        catalog,
        menuPart.id
      );
      hydratedMenuPart.dishes.push(hydratedDish);
    }
  }

  return hydratedMenuPart;
};
