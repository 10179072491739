import dayjs from "dayjs";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { formatDateWithHourAndMinutes } from "shared/utils/date";

export default (restaurant: IRestaurant) => {
  //TODO : supprimer ligne 9 et 10 point interrogation fallback quand le backend sera fonctionnel
  let timePeriods = restaurant.opening_hours[dayjs().weekday()]?.time_periods;
  if (!timePeriods?.length) return null;
  if (timePeriods.length === 1) return timePeriods[0];
  if (timePeriods.length >= 2) {
    return timePeriods.find((timeSlot, index) => {
      const formattedEndTime = formatDateWithHourAndMinutes(timeSlot.end_time);
      if (dayjs().isBefore(formattedEndTime)) {
        return timePeriods[index];
      }
    });
  }
};
