import { useRecoilState } from "recoil";
import currentMenuAtom, {
  ICurrentMenuState,
} from "shared/atoms/currentMenu.atom";
import { IHydratedItemMenu } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

const useCurrentMenu = () => {
  const [currentMenuState, setCurrentMenuAtom] =
    useRecoilState(currentMenuAtom);

  const incrementModifyOrderToCurrentMenu = (item: ICurrentMenuState): void => {
    setCurrentMenuAtom({
      ...currentMenuState,
      name: item.name,
      quantity: 1,
      unit_price: item.unit_price,
      id: item.id,
      modifiers: item.modifiers,
    });
  };
  const incrementEmptyMenuPart = (item: IHydratedItemMenu): void => {
    const newMenuParts = item.menu_parts.map((menuPart) => {
      return {
        menuPartName: menuPart.name,
        menuPartId: menuPart.id,
        max: menuPart.maximum_choices,
        min: menuPart.minimum_choices,
        items: [],
      };
    });
    setCurrentMenuAtom({
      ...currentMenuState,
      name: item.name,
      quantity: 1,
      unit_price: item.price,
      price_override: item.price_override,
      id: item.id,
      modifiers: newMenuParts,
    });
  };

  const addMenuPartItem = (item: IOrderItem, currentMenuPartName: string) => {
    const newModifiersState = currentMenuState.modifiers.map((menuPart) => {
      if (menuPart.menuPartName === currentMenuPartName) {
        if (menuPart.max === 1) {
          menuPart.items = [item];
        } else if (menuPart.items.length < menuPart.max) {
          menuPart.items = [...menuPart.items, item];
        }
      }

      return menuPart;
    });
    setCurrentMenuAtom({
      ...currentMenuState,

      modifiers: newModifiersState,
    });
  };

  return {
    currentMenuState,
    setCurrentMenuAtom,
    incrementEmptyMenuPart,
    addMenuPartItem,
    incrementModifyOrderToCurrentMenu,
  };
};

export default useCurrentMenu;
