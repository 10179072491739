import "./dish-option-body.scss";

import { Form } from "react-bootstrap";
import {
  IHydratedOption,
  IOptionValue,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { countModifierOption } from "shared/utils/order";

interface DishOptionBodyProps {
  value: IOptionValue;
  option: IHydratedOption;
  handleOptions: (
    value: IOptionValue,
    option: IHydratedOption,
    addDisabled: boolean
  ) => void;
  hasModifier: (value: IOptionValue) => boolean;

  displayPrice: (valuePrice: number) => string;
  children?: React.ReactNode;
  currentDish: IOrderItem;
  addDisabled: boolean;
}

export const DishOptionBody = ({
  value,
  option,
  handleOptions,
  hasModifier,
  displayPrice,
  children,
  currentDish,
  addDisabled,
}: DishOptionBodyProps) => {
  const getType =
    option.maximum_choices === 1 && option.minimum_choices === 1
      ? "radio"
      : "checkbox";

  const disabledDiv =
    getType === "checkbox" &&
    !hasModifier(value) &&
    countModifierOption(option, currentDish) >= option.maximum_choices;

  const addDisabledOnCheckbox = addDisabled && getType === "checkbox";

  const classNameDiv = `input-dish-option-custom p-3 my-3 ${
    hasModifier(value) ? "option-checked" : ""
  }
    ${disabledDiv ? "disabled-input" : ""}`;

  return (
    <div
      className={classNameDiv}
      onClick={() => handleOptions(value, option, addDisabledOnCheckbox)}
    >
      <div className="d-flex" role="button">
        <Form.Check
          type={getType}
          id={value.id}
          value={value.id}
          name={`option-${option.id}`}
          checked={hasModifier(value)}
          readOnly={true}
          disabled={disabledDiv}
          className="custom-checkbox"
        />
        <Form.Check.Label className="ms-2">
          {value.name}
          <span className="dish-price-supplement">
            {displayPrice(value.price)}
          </span>
        </Form.Check.Label>
      </div>
      {children}
    </div>
  );
};
