import "./restaurant-basket-summary.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";

interface RestaurantBasketSummaryProps {
  total: string;
  orderMode?: string;
}

export const RestaurantBasketSummary = ({
  total,
  orderMode = "",
}: RestaurantBasketSummaryProps) => {
  const { t } = useTranslation();
  const [promo] = useState<number>(0);
  return (
    <div className="px-4">
      {/* features fidelity and promo are not yet ready for production */}
      {/* <div className="promo-container text-muted d-flex justify-content-between text-small">
        <div className="code-promo">{t<string>("basketSummary.noDiscount")}</div>
        <div className="promo">{promo}€</div>
      </div> */}

      {/* <div className="fidelity-container text-muted d-flex justify-content-between text-small">
        <div className="code-fidelity">FIDELITE</div>
        <div className="fidelity">{promo}€</div>
      </div> */}

      {orderMode === "delivery" && (
        <div className="delivery-container text-muted d-flex justify-content-between text-small">
          <div className="code-delivery">
            {t<string>("basketSummary.deliveryFee")}
          </div>
          <div className="delivery">{promo}€</div>
        </div>
      )}

      <div className="total fw-bold d-flex justify-content-between mt-3">
        <p className="text-total">{t<string>("basketSummary.total")}</p>
        <p>{total}</p>
      </div>
    </div>
  );
};
