import * as Sentry from "@sentry/react";
import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import geoLocState from "shared/atoms/geoLoc.atom";
import { geoLocIsAvailable } from "shared/utils/geoLoc";

const useGeoLocalization = () => {
  const [geoLoc, setGeoLoc] = useRecoilState(geoLocState);

  useEffect(() => {
    if (geoLocIsAvailable()) {
      setGeoLoc({ ...geoLoc, available: true });
    }
    // eslint-disable-next-line
  }, []);

  async function getAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      setGeoLoc({ ...geoLoc, loading: true });
      navigator.geolocation.getCurrentPosition((position) => {
        setGeoLoc({ ...geoLoc, position });
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          )
          .then((res: any) => {
            return resolve(res.data.results[0]?.formatted_address);
          })
          .catch((err) => {
            Sentry.captureException(err);
            return reject(err);
          })
          .finally(() => {
            setGeoLoc({ ...geoLoc, loading: false });
          });
      });
    });
  }

  return { geoLoc, getAddress };
};

export default useGeoLocalization;
