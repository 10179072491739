import { findIndex } from "lodash";
import IRestaurant from "shared/interfaces/Restaurant.interface";

/**
 * Find a restaurant in the given list by it's id and return it
 * @param id
 * @param restaurants
 * @returns
 */

export default (id: number, restaurants: IRestaurant[]): IRestaurant | null => {
  const i = findIndex(restaurants, { id });
  if (~i) {
    return restaurants[i];
  }
  return null;
};
