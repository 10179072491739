import { mdiCalendarOutline } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import localOrderState from "shared/atoms/localOrder.atom";
import moduleState from "shared/atoms/module.atom";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import {
  formatAvailableDates,
  formatDate,
  getIncludedDates,
  updateDate,
} from "shared/utils/date";

import OptionCustom from "../OptionCustom";

interface RestaurantDatePickerProps {
  restaurant: IRestaurant;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedDate: Date | null;
}
export function RestaurantDatePicker({
  selectedDate,
  restaurant,
  setSelectedDate,
}: RestaurantDatePickerProps) {
  const { t, i18n } = useTranslation();
  const { module } = useRecoilValue(moduleState);
  const localOrder = useRecoilValue(localOrderState);
  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  const availableDates = getIncludedDates(restaurant, module.max_days);

  const availableDatesFormated = formatAvailableDates(
    availableDates,
    restaurant,
    module.max_days
  );

  const handleChangeDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedDate) return;
    setSelectedDate(
      updateDate(e.target.value, selectedDate, restaurant, localOrder)
    );
  };

  return (
    <div className="restaurant-input-container">
      <Icon className="input-icon" path={mdiCalendarOutline} size={1} />
      <select
        className="form-select"
        aria-label={formatDate(selectedDate ?? dayjs().toDate())}
        defaultValue={formatDate(selectedDate ?? dayjs().toDate())}
        onChange={(e) => handleChangeDate(e)}
      >
        {availableDatesFormated.map((date, key) => (
          <OptionCustom
            key={`date_picker_${key}`}
            value={String(date)}
            text={formatDate(date)}
            today={
              dayjs().isSame(dayjs(date), "day")
                ? t<string>("dates.today")
                : null
            }
          />
        ))}
      </select>
    </div>
  );
}
