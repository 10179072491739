import ICatalog, {
  IHydratedCatalog,
} from "shared/interfaces/Catalog.interface";

import {
  hydrateDish,
  hydrateMenu,
  hydrateMenuPart,
  hydrateOption,
  hydrateTag,
} from ".";

export default (catalog: ICatalog): IHydratedCatalog => {
  const hydratedCatalog: IHydratedCatalog = {
    ...catalog,
    menus: [],
    dishes: [],
    options: [],
    menu_part: [],
    tags: [],
  };

  // hydrate all menus
  if (catalog.menus) {
    for (const menu of catalog.menus) {
      hydratedCatalog.menus.push(hydrateMenu(menu, catalog));
    }
  }

  // hydrate all dishes
  if (catalog.dishes) {
    for (const dish of catalog.dishes) {
      hydratedCatalog.dishes.push(hydrateDish(dish, catalog));
    }
  }

  // hydrate all dishes
  if (catalog.options) {
    for (const option of catalog.options) {
      hydratedCatalog.options.push(hydrateOption(option, catalog));
    }
  }

  // hydrate all dishes
  if (catalog.menu_part) {
    for (const part of catalog.menu_part) {
      hydratedCatalog.menu_part.push(hydrateMenuPart(part, catalog));
    }
  }

  if (catalog.tags) {
    for (const tag of catalog.tags) {
      hydratedCatalog.tags.push(hydrateTag(tag, catalog));
    }
  }

  return hydratedCatalog;
};
