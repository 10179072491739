import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import {
  IHydratedCatalog,
  IHydratedTag,
} from "shared/interfaces/Catalog.interface";

export default (
  catalog: IHydratedCatalog | undefined,
  localOrder: ILocalOrderState
): string[] => {
  const localOrderIds = Object.keys(localOrder.items);
  return localOrderIds.filter((id: string) =>
    catalog?.tags.find((tag: IHydratedTag) =>
      tag.items.find(
        (item) => item.id === localOrder.items[id].id && item.disabled
      )
    )
  );
};
