import "./mode.scss";

import Icon from "@mdi/react";
import RestaurantWebsiteLink from "pages/Mode/components/RestaurantWebsiteLink";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import EVENT_TAGS from "services/amplitude/eventTags";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import ORDER_MODES from "shared/constants/orderModes.const";
import useFetchRestaurants from "shared/hooks/useFetchRestaurants.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import IOrderMode from "shared/interfaces/OrderMode.interface";
import { tracker } from "shared/utils/amplitude";
import {
  getAvailableModes,
  orderingModeIsAvailable,
} from "shared/utils/module";

export function Mode() {
  useFetchRestaurants();
  const { localOrder, setLocalOrder } = useLocalOrder();
  const { module }: IModuleState = useRecoilValue(moduleState);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (mode: IOrderMode) => {
    setLocalOrder({
      ...localOrder,
      orderMode: mode.value,
    });
    tracker(EVENT_TAGS.SET_MODE, {
      module_id: module.id,
      slug: location.pathname,
      mode: mode.id,
      step: "Mode",
    });
  };

  useEffect(() => {
    const availableModes: IOrderMode[] = getAvailableModes(module);

    if (availableModes.length === 1) {
      setLocalOrder({
        ...localOrder,
        orderMode: availableModes[0].value,
      });
      navigate(routes.restaurants.path);
    }
  }, [navigate, module]);

  return (
    <div className="mode-container">
      <div className="mode">
        {ORDER_MODES.map((mode, modeIndex) => {
          if (!orderingModeIsAvailable(mode.value, module)) {
            return null;
          }

          return (
            <Link
              key={modeIndex}
              className="mode-item"
              to={routes.restaurants.path}
              onClick={() => handleClick(mode)}
            >
              <div className="mode-item-label">
                <Icon size={1.5} path={mode.icon} />
                <div>{t<string>(mode.label)}</div>
              </div>
              <div className="mode-item-backdrop" />
            </Link>
          );
        })}
      </div>
      {module.website_url && (
        <div className="return-url">
          <RestaurantWebsiteLink url={module.website_url} />
        </div>
      )}
    </div>
  );
}
