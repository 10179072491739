import "./counter.scss";

import { mdiMinusCircleOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";

interface CounterProps {
  add(): void;
  remove(): void;
  max?: number;
  min?: number;
  quantity: number;
  addDisabled?: boolean;
  removeDisabled?: boolean;
  isReadOnly?: boolean;
  maxi?: boolean;
}

export const Counter = ({
  add,
  remove,
  quantity,
  addDisabled,
  removeDisabled,
  isReadOnly,
  maxi,
}: CounterProps) => {
  return (
    <div className="quantity-container align-items-center d-flex h-25 justify-content-center">
      {!isReadOnly && (
        <button
          className="button-no-style"
          onClick={(e) => {
            e.stopPropagation();
            if (!removeDisabled) {
              remove();
            }
          }}
        >
          <Icon
            path={mdiMinusCircleOutline}
            size={maxi ? 1.5 : 1}
            className={removeDisabled ? "disabled" : "icon-quantity-color"}
          />
        </button>
      )}
      <div className="quantity mx-2 fw-bold">{quantity}</div>
      {!isReadOnly && (
        <button
          className="button-no-style"
          onClick={(e) => {
            e.stopPropagation();
            if (!addDisabled) {
              add();
            }
          }}
        >
          <Icon
            path={mdiPlusCircle}
            size={maxi ? 1.5 : 1}
            className={addDisabled ? "disabled" : "icon-quantity-color"}
          />
        </button>
      )}
    </div>
  );
};
