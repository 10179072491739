import { mdiClockOutline } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import localOrderState from "shared/atoms/localOrder.atom";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { getIncludedTimesForDate, updateTime } from "shared/utils/date";

import OptionCustom from "../OptionCustom";

interface RestaurantTimePickerProps {
  restaurant: IRestaurant;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedDate: Date | null;
}
export function RestaurantTimePicker({
  restaurant,
  selectedDate,
  setSelectedDate,
}: RestaurantTimePickerProps) {
  const localOrder = useRecoilValue(localOrderState);
  let availableTimes = getIncludedTimesForDate(
    restaurant,
    selectedDate ?? dayjs().toDate(),
    localOrder
  );
  useEffect(() => {
    // eslint-disable-next-line
    availableTimes = getIncludedTimesForDate(
      restaurant,
      selectedDate ?? dayjs().toDate(),
      localOrder
    );
  }, [selectedDate, restaurant]);

  const handleChangeTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedDate) return;
    setSelectedDate(updateTime(e.target.value, selectedDate));
  };

  return (
    <div className="restaurant-input-container">
      <Icon className="input-icon" path={mdiClockOutline} size={1} />
      <select
        className="form-select"
        value={dayjs(selectedDate).format()}
        onChange={(e) => {
          handleChangeTime(e);
        }}
      >
        {availableTimes.map((time, key) => (
          <OptionCustom
            key={`time_picker_${key}`}
            value={dayjs(time).format()}
            text={dayjs(time).format("HH:mm")}
          />
        ))}
      </select>
    </div>
  );
}
