import dayjs from "dayjs";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import {
  dateIsWithinClosureDate,
  getIncludedDates,
  getIncludedTimesForDate,
} from ".";

/**
 * Return the first available opening hour
 * @param restaurant
 * @param todayOnly if true, will ony return the opening hours for today
 * @returns
 */
export default (
  restaurant: IRestaurant,
  maxDays: number,
  localOrder: ILocalOrder,
  todayOnly?: boolean
): Date | null => {
  let days = getIncludedDates(restaurant, maxDays);

  // only keep the first day if parameter is set
  if (todayOnly) {
    days = [days[0]];
  }

  const formatedDaysWithoutDayBeforeToday = days.find((day) =>
    getIncludedTimesForDate(restaurant, dayjs(day).toDate()).length &&
    restaurant.ordering_available
      ? dayjs().isSameOrBefore(day, "day")
      : dayjs().isBefore(day, "day")
  );

  if (!formatedDaysWithoutDayBeforeToday) return null;

  const times = getIncludedTimesForDate(
    restaurant,
    formatedDaysWithoutDayBeforeToday,
    localOrder
  );

  for (const time of times) {
    // skip if the time is before now
    if (dayjs().isAfter(time, "day")) {
      continue;
    }

    // skip if time is within a closure date
    if (dateIsWithinClosureDate(time, restaurant)) {
      continue;
    }
    // return the first found valid time
    return time;
  }

  return null;
};
