import "./ui-kit.scss";

import { mdiBike, mdiMinus, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import RestaurantDescription from "pages/RestaurantsList/components/RestaurantsListHeader/components/RestaurantListRender/RestaurantDescription";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { dummyRestaurant } from "shared/components/Debug/dummy";
import Input from "shared/components/Input";
import ZImage from "shared/components/ZImage";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export default function UiKit() {
  const testRestaurant: IRestaurant = dummyRestaurant[0];

  return (
    <div className="ui-kit">
      <Container className="mb-4">
        <h1>Ui Kit</h1>
        <Row className="mb-2">
          <h3>Texts</h3>
          <h1>H1 Title</h1>
          <h2>H2 Title</h2>
          <h3>H3 Title</h3>
          <h4>H4 Title</h4>
          <h5>H5 Title</h5>
          <h6>H6 Title</h6>

          <Col>
            <Link className="custom-link" to="/">
              Custom Link
            </Link>
          </Col>

          <Col>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
            <a href="/">Example link</a>
          </Col>

          <Col>
            <Alert variant="danger">Danger</Alert>
          </Col>
        </Row>
        <Row>
          <h3>Buttons</h3>
          <Col>
            <Button variant="default">Default</Button>
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="warning">Warning</Button>
            <Button variant="danger">Danger</Button>
            <Button variant="link">Button Link</Button>
            <br />
            <br />
            <h6>Quantity Buttons</h6>
            <div className="d-flex">
              <button className="btn btn-primary btn-circle m-2">
                <Icon path={mdiMinus} size={1} />
              </button>
              <button className="btn btn-primary btn-circle m-2">
                <Icon path={mdiPlus} size={1} />
              </button>
              <button className="btn btn-primary btn-circle m-2" disabled>
                <Icon path={mdiPlus} size={1} />
              </button>
              <button className="btn btn-primary btn-circle btn-circle-20 m-2">
                <Icon path={mdiPlus} size={1} />
              </button>
            </div>

            <br />
            <br />
            <Button variant="primary" type="submit" className="btn-block">
              Button Block
            </Button>
            <br />
            <br />
            <Col md="6">
              <Button variant="default" className="mode-btn btn-block">
                <Icon size={1} path={mdiBike} /> Mode Button (col-6)
              </Button>

              <Button variant="active" className="mode-btn btn-block">
                <Icon size={1} path={mdiBike} /> Mode Button active
              </Button>
            </Col>
          </Col>
        </Row>
        <Row>
          <h3>Form</h3>
          <Col>
            <Form>
              <Col md={4} xs={8}>
                <Input
                  controlId="formEmail"
                  className="mb-2"
                  type="email"
                  placeholder="Placeholder"
                  label="email"
                />

                <Form.Group controlId="formForgotPassword">
                  <Form.Text>
                    <u>Form text helper</u>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Form>
          </Col>
        </Row>
        <Row>
          <h4>Restaurants list card</h4>
          <Col md="4">
            {/* <Card className="bordered shadowed restaurant-card mt-4 p-3">
              <RestaurantDescription restaurant={testRestaurant} />
            </Card>
          </Col>
          <Col md="4">
            <Card className="bordered shadowed card-primary restaurant-card mt-4 p-3">
              <RestaurantDescription restaurant={testRestaurant} />
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-disabled restaurant-card mt-4 p-3">
              <RestaurantDescription restaurant={testRestaurant} />
            </Card> */}
          </Col>
        </Row>

        <Row>
          <h4>Images Loader</h4>
          <Col>
            Basic
            <ZImage
              src="https://picsum.photos/150/150"
              width={150}
              height={150}
            />
          </Col>

          <Col>
            No Spinner (manual)
            <ZImage
              src="https://picsum.photos/150/150"
              width={150}
              height={150}
              disablespinner
            />
          </Col>

          <Col>
            Error handling
            <ZImage
              src="https://fakeurl.toto/test.png"
              width={150}
              height={150}
            />
          </Col>
        </Row>
        <Row>
          <h4>Nav</h4>
          <ul className="nav nav-pills mt-2 mb-2">
            <li className="nav-item d-flex">
              <button className="nav-link" aria-current="page">
                Nav Link
              </button>
              <button className="nav-link active" aria-current="page">
                Nav Link
              </button>
              <button className="nav-link" aria-current="page">
                Nav Link
              </button>
            </li>
          </ul>
        </Row>
        <Row>
          <Col>
            <Badge pill bg="primary">
              Badge
            </Badge>
            <Badge bg="danger">Badge Danger</Badge>
            <span className="badge badge-custom me-1">Badge Custom</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
