import "./restaurant-modal.scss";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import moduleState from "shared/atoms/module.atom";
import RestaurantTimePicker from "shared/components/RestaurantModal/components/RestaurantTimePicker";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import { IHydratedCatalog } from "shared/interfaces/Catalog.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { getClosestOpeningDate } from "shared/utils/date";
import { getUnavailableLocalOrderItemIds } from "shared/utils/localOrder";

import RestaurantDatePicker from "./components/RestaurantDatePicker";

interface RestaurantModalProps {
  activeRestaurant: IRestaurant | null;
  onHide: () => void;
  onSelectionUpdate: (orderDate: Date, restauranId: number) => void;
  catalog?: IHydratedCatalog;
  handleDisplayToast?: () => void;
}

export function RestaurantModal({
  activeRestaurant,
  onHide,
  onSelectionUpdate,
  catalog,
  handleDisplayToast,
}: RestaurantModalProps) {
  const { t } = useTranslation();
  const { module } = useRecoilValue(moduleState);
  const { localOrder, removeItemById } = useLocalOrder();

  let closestDate = activeRestaurant
    ? getClosestOpeningDate(activeRestaurant, module.max_days, localOrder)
    : null;
  useEffect(() => {
    if (activeRestaurant) {
      // eslint-disable-next-line
      closestDate = getClosestOpeningDate(
        activeRestaurant,
        module.max_days,
        localOrder
      );
      setSelectedDate(closestDate);
    }
  }, [activeRestaurant]);

  const [selectedDate, setSelectedDate] = useState(closestDate);
  const onSubmit = () => {
    if (!activeRestaurant || !selectedDate) {
      return;
    }
    // clear the existing products if there was another restaurant selected before
    if (localOrder.restaurant_id !== activeRestaurant.id) {
      localOrder.items = {};
    }
    if (catalog) {
      // check the local order item with the catalog's time availabilities
      const localOrderItemIdsNotAvailableAnymore =
        getUnavailableLocalOrderItemIds(localOrder, catalog, selectedDate);

      // remove from the cart the items not available on the selected date
      if (localOrderItemIdsNotAvailableAnymore.length) {
        localOrderItemIdsNotAvailableAnymore.forEach((id) =>
          removeItemById(localOrder.items[id].id)
        );
        handleDisplayToast && handleDisplayToast();
      }
    }
    onSelectionUpdate(selectedDate, activeRestaurant.id);
  };

  return (
    <Modal
      show={!!activeRestaurant}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="restaurant-modal"
      centered
    >
      {activeRestaurant && (
        <Modal.Body>
          <p className="retrait-commande">
            {t<string>("restaurantsList.modal.orderWithdrawal")}
          </p>

          <form onSubmit={onSubmit}>
            <div>
              <RestaurantDatePicker
                restaurant={activeRestaurant}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className="mt-2 w-100">
              <RestaurantTimePicker
                restaurant={activeRestaurant}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div>
              <button
                type="button"
                className="btn btn-primary btn-block mt-2"
                onClick={onSubmit}
              >
                {t<string>("restaurantsList.modal.validateButton")}
              </button>
            </div>
          </form>
        </Modal.Body>
      )}
    </Modal>
  );
}
