import "dayjs/locale/fr";
import "dayjs/locale/en";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import fr from "./fr.json";

export default function locales() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)

    .init({
      resources: {
        fr: { translation: fr },
        en: { translation: en },
      },
      fallbackLng: false,
      interpolation: {
        escapeValue: false,
      },
    });

  dayjs.locale(i18n.language);
  dayjs.extend(utc);
  dayjs.extend(updateLocale);
  dayjs.extend(isBetween);
  dayjs.extend(weekday);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isToday);
}
