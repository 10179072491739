import { mdiMapMarker, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RestaurantOpening from "shared/components/RestaurantOpening";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import RestaurantClosures from "./RestaurantClosures";

interface RestaurantDescriptionProps {
  restaurant: IRestaurant;
  handleSelectRestaurant: (restaurant: IRestaurant) => void;
  isOrderAvailable: boolean;
}

export const RestaurantDescription = ({
  restaurant,
  handleSelectRestaurant,
  isOrderAvailable,
}: RestaurantDescriptionProps) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState<number[] | null>(null);

  function getDistanceFromLatLng(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    let R = 6371; // Radius of the earth in km
    let dLat = deg2rad(lat2 - lat1); // deg2rad below
    let dLon = deg2rad(lon2 - lon1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    if (d < 1) {
      return `${(d * 1000).toFixed(0)}m`;
    }
    return `${d.toFixed(1)}km`;
  }

  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  function getDistance(coordinates: any) {
    if (position && coordinates.lat && coordinates.lng) {
      return getDistanceFromLatLng(
        position[0],
        position[1],
        coordinates.lat,
        coordinates.lng
      );
    }
    return null;
  }

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition([position.coords.latitude, position.coords.longitude]);
      });
    }
  }, []);

  return (
    <div className="d-flex flex-row w-100 align-items-center justify-content-between">
      <div className="d-flex flex-column restaurant-container">
        <div className="restaurant-title-container">
          <div className="restaurant-title-text">
            <span>{restaurant.public_name}</span>
          </div>
          <div className="restaurant-title-distance">
            {getDistance(restaurant.coordinates)}
          </div>
        </div>

        <div className="m-0">
          <div className="pt-2 item-restaurant">
            <Icon className="me-2" size={0.7} path={mdiMapMarker} />
            {restaurant.address}
          </div>
          {restaurant.phone ? (
            <div className="pt-2 item-restaurant">
              <Icon className="me-2" size={0.7} path={mdiPhone} />
              {restaurant.phone}
            </div>
          ) : null}

          <div className="pt-2 item-restaurant opening-hours-container d-flex">
            <RestaurantOpening restaurant={restaurant} />
          </div>
          <RestaurantClosures restaurant={restaurant} />
        </div>
        <div className="d-flex justify-content-end">
          <span
            className={classNames("card-restaurant-order-link", {
              "card-restaurant-order-link-disabled": !isOrderAvailable,
            })}
            onClick={() => {
              handleSelectRestaurant(restaurant);
            }}
          >
            {t<string>("restaurantsList.orderLink")}
          </span>
        </div>
      </div>
    </div>
  );
};
