import dayjs from "dayjs";
import { ILocalOrderState } from "shared/atoms/localOrder.atom";

//Check if basket last modified date is expired and reformat it if needed
export default (currentLocalOrder: ILocalOrderState): ILocalOrderState => {
  const newState = { ...currentLocalOrder };
  const dateLimit = dayjs(newState.modified).add(6, "hour");

  if (dayjs().isAfter(dateLimit)) {
    newState.items = {};
  }
  return { ...newState };
};
