import {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";

/** canAddDirectlyItemWithoutDisplay
 *
 * Will check if the item displayed in the catalog can be add without showing the modal
 * 6 conditions :
 * isMenuReadOnly : used by restaurants to display the menu card and can't order from item
 * item.is_sold_out : item is out of stock
 * item.type: item is not a menu where user will have to choose menu parts
 * item.options.length: user has not options to choose
 * force_display_modal_dish: restaurant has choosen to display this particular item
 * item.disabled: item is disabled by the restaurant
 * @param item
 * @param isMenuReadOnly
 * @returns boolean : if true, item can be add directly in the local order
 */
export default (
  item: IHydratedItemDish | IHydratedItemMenu,
  isMenuReadOnly: boolean,
  force_display_modal_dish: boolean
): boolean =>
  !isMenuReadOnly &&
  !item.is_sold_out &&
  item.type !== "menu" &&
  item.options.length === 0 &&
  !force_display_modal_dish &&
  !item.disabled;
