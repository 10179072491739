import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import { IOrderItem } from "shared/interfaces/Order.interface";

export default (
  localOrder: ILocalOrderState,
  item: IOrderItem
): ILocalOrderState | undefined => {
  const localOrderIds = Object.keys(localOrder.items);

  //Prevent to continue, if the local items List is empty
  if (!localOrderIds.length) return;
  const newState = { ...localOrder };
  localOrderIds.forEach((entityId: string) => {
    if (JSON.stringify(localOrder.items[entityId]) === JSON.stringify(item)) {
      //if quantity is one, remove completly the item from the listItems
      //Otherwise, decrement its quantity
      if (newState.items[entityId].quantity === 1) {
        delete newState.items[entityId];
      } else {
        newState.items[entityId].quantity =
          newState.items[entityId].quantity - 1;
      }
    }
  });
  return { ...newState };
};
