import {
  IHydratedOption,
  IOptionValue,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

import { convertCatalogOptionValueToOrderItem, countModifierOption } from ".";

export default (
  option: IHydratedOption,
  value: IOptionValue,
  currentDish: IOrderItem
): IOrderItem => {
  // If radio button
  if (option.maximum_choices === 1 && option.minimum_choices === 1) {
    const index = currentDish.modifiers.findIndex(
      (modifier) => modifier.group_id === option.id
    );
    // Find option by finding it with group_id props
    // if exist remove it
    if (~index) {
      currentDish.modifiers.splice(index, 1);
    }
    currentDish.modifiers.push(
      convertCatalogOptionValueToOrderItem(value, option)
    );
    return { ...currentDish, modifiers: currentDish.modifiers };
    //If is not a radio input
  } else {
    const index = currentDish.modifiers.findIndex(
      (modifier) => modifier.id === value.id
    );

    if (!~index) {
      currentDish.modifiers.push(
        convertCatalogOptionValueToOrderItem(value, option)
      );
      return { ...currentDish, modifiers: currentDish.modifiers };
    } else if (
      countModifierOption(option, currentDish) <= option.maximum_choices
    ) {
      currentDish.modifiers[index].quantity =
        currentDish.modifiers[index].quantity + 1;
      return { ...currentDish, modifiers: currentDish.modifiers };
    } else if (
      countModifierOption(option, currentDish) <= option.maximum_choices
    ) {
      currentDish.modifiers.push(
        convertCatalogOptionValueToOrderItem(value, option)
      );
      return { ...currentDish, modifiers: currentDish.modifiers };
    }
  }

  return currentDish;
};
