import "../../restaurant-checkout.scss";

import { mdiCreditCard } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IPaymentMethod } from "shared/interfaces/Order.interface";

import CheckoutPaymentMethod from "./CheckoutPaymentMethod";

interface CheckoutPaymentProps {
  onlyOnlinePayment: boolean;
  orderMode: string;
  setUserPaymentMethod: Dispatch<SetStateAction<IPaymentMethod[]>>;
  methodsPayment: IPaymentMethod[] | undefined;
  errorBms: boolean;
}

export const CheckoutPayment = ({
  onlyOnlinePayment,
  orderMode,
  setUserPaymentMethod,
  methodsPayment = [],
  errorBms,
}: CheckoutPaymentProps) => {
  const { t } = useTranslation();

  const [selectRadioButton, setSelectRadioButton] = useState("");
  const [methodsPaymentState, setMethodsPaymentState] =
    useState(methodsPayment);

  const isRadioSelected = (value: string): boolean =>
    selectRadioButton === value;

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSelectRadioButton(e.currentTarget.value);

  useEffect(() => {
    const name: string =
      orderMode === "delivery"
        ? `${t<string>("checkout.paymentType.uponReceipt")}`
        : `${t<string>("checkout.paymentType.onSite")}`;

    if (!onlyOnlinePayment && !errorBms) {
      setMethodsPaymentState([
        ...methodsPaymentState,
        {
          identifier: "offline",
          name,
        },
      ]);
    }
    if (errorBms && onlyOnlinePayment) {
      setMethodsPaymentState([
        ...methodsPaymentState,
        {
          identifier: t<string>("checkout.paymentMethodError"),
          name: t<string>("checkout.paymentMethodErrorMessage"),
        },
      ]);
    }

    if (errorBms && !onlyOnlinePayment) {
      setMethodsPaymentState([
        ...methodsPaymentState,
        {
          identifier: "offline",
          name,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (
      methodsPaymentState.length &&
      methodsPaymentState[0].identifier !== "error"
    ) {
      setSelectRadioButton(methodsPaymentState[0].identifier),
        setUserPaymentMethod([methodsPaymentState[0]]);
    }
  }, [methodsPaymentState]);

  const handlePayment = (value: IPaymentMethod) => {
    setUserPaymentMethod([value]);
  };

  return (
    <>
      <div className="d-flex my-4">
        <Icon path={mdiCreditCard} size={0.8} className="me-1 icon-payment" />
        <h5 className="order-subtitle">
          {t<string>("checkout.paymentType.title")}
        </h5>
      </div>
      <Col xs={12} className="mb-2 method">
        {methodsPaymentState.map((method, index) => (
          <CheckoutPaymentMethod
            key={method.name + index}
            method={method}
            isRadioSelected={isRadioSelected}
            handleRadioClick={handleRadioClick}
            handlePayment={handlePayment}
          />
        ))}
      </Col>
    </>
  );
};
