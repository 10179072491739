import "./item-modal.scss";

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import DishModal from "shared/components/DishModal";
import ItemModalHeader from "shared/components/ItemModalHeader";
import useCurrentMenu from "shared/hooks/useCurrentMenu.hook";
import ICatalog, {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

import ItemMenu from "./ItemMenu";

interface ItemModalProps {
  item: IHydratedItemMenu;
  onClose: () => void;
  catalog: ICatalog;
  modifyItemOrder: IOrderItem | undefined;
  modifyMenuOrder: ICurrentMenuState | undefined;
}

export function ItemModal({
  item,
  catalog,
  onClose,
  modifyItemOrder,
  modifyMenuOrder,
}: ItemModalProps) {
  const { incrementEmptyMenuPart, incrementModifyOrderToCurrentMenu } =
    useCurrentMenu();

  useEffect(() => {
    if (modifyMenuOrder) {
      incrementModifyOrderToCurrentMenu(modifyMenuOrder);
    } else {
      incrementEmptyMenuPart(item);
    }
  }, []);

  const [show, setShow] = useState(true);

  /**
   * Child item, (can only be a dish)
   */
  const [addingItem, setAddingItem] = useState<IHydratedItemDish>();
  const [currentMenuPartName, setCurrentMenuPartName] = useState<string>();

  function handleClose() {
    onClose();
    setShow(false);
  }

  /**
   * Re display the modal when the child is closed
   */
  function handleChildClose() {
    setAddingItem(undefined);
    setShow(true);
  }

  return (
    <div>
      <Modal
        className="item-modal"
        show={show}
        size={item.type === "menu" ? "xl" : "lg"}
        onHide={handleClose}
        centered
        backdrop="static"
        keyboard={false}
        scrollable
      >
        <ItemModalHeader name={item.name} />
        {item.type === "menu" && (
          <ItemMenu
            item={item as IHydratedItemMenu}
            catalog={catalog}
            setShow={setShow}
            setAddingItem={setAddingItem}
            setCurrentMenuPartName={setCurrentMenuPartName}
            handleClose={handleClose}
            modifyMenuOrder={modifyMenuOrder}
            modifyItemOrder={modifyItemOrder}
          />
        )}
      </Modal>
      {/* child item */}
      {addingItem && (
        <DishModal
          item={addingItem}
          modifyItemOrder={modifyItemOrder}
          onClose={handleChildClose}
          currentMenuPartName={currentMenuPartName}
        />
      )}
    </div>
  );
}
