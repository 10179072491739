import "./restaurant-item.scss";

import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ZImage from "shared/components/ZImage";
import useCurrentMenu from "shared/hooks/useCurrentMenu.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import ICatalog, {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { formatPrice } from "shared/utils/commons";
import { getItemImage } from "shared/utils/restaurant";

interface RestaurantItemProps {
  item: IHydratedItemDish | IHydratedItemMenu;
  catalog: ICatalog;
  isFromMenu?: boolean;
  onClick?: () => void;
  isMenuReadOnly?: boolean;
  forceDisplayModalDish?: boolean;
}

export function RestaurantItem({
  item,
  catalog,
  isFromMenu,
  onClick,
  isMenuReadOnly = false,
  forceDisplayModalDish = false,
}: RestaurantItemProps) {
  const { t } = useTranslation();
  const { currentMenuState } = useCurrentMenu();
  const { localOrder } = useLocalOrder();
  const isProductSelected = currentMenuState.modifiers.find((modifier) =>
    modifier.items.some((modifierItem) => item.id === modifierItem.id)
  );

  const localOrderIds = Object.keys(localOrder.items);
  const localOrderItems = localOrderIds.map((id) => localOrder.items[id]);
  const isItemSelected =
    !isFromMenu &&
    localOrderItems.find((currentItem) => currentItem.id === item.id);

  const quantity =
    isItemSelected && isItemSelected.quantity ? (
      <span className="item-quantity">x{isItemSelected.quantity}</span>
    ) : null;

  const isProductSelectedStyle =
    !isMenuReadOnly && (isProductSelected || isItemSelected)
      ? "product-selected"
      : null;

  const itemIsDisabled = item.disabled && forceDisplayModalDish;

  return (
    <Card
      className={`restaurant-item-card p-3 mt-2 ${
        item.is_sold_out || item.disabled
          ? `card-disabled ${
              itemIsDisabled
                ? "item-disabled-force-dish-modal"
                : "item-disabled-not-force-dish-modal"
            }`
          : `bordered card-primary ${isProductSelectedStyle}`
      }`}
      onClick={onClick}
    >
      <div className="item-image">
        <ZImage
          src={getItemImage(item.image, "small")}
          alt={item.name}
          width={75}
          height={75}
          disablespinner
        />
      </div>
      <div className="item-infos ms-3">
        <h5 className="item-name mb-1">
          {!isMenuReadOnly && quantity}
          {item.name}
        </h5>
        <p className="mb-1">{item.description}</p>
        {!isFromMenu && (
          <div className="d-flex">
            <span className="item-price">
              {formatPrice(item.price, catalog.currency)}
            </span>
            {item.disabled && (
              <i className="ms-2">{t("commons.unavailable")}</i>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}
