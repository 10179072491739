import { findIndex } from "lodash";
import ICatalog, {
  IHydratedItemMenu,
  IItemMenu,
} from "shared/interfaces/Catalog.interface";

import { hydrateMenuPart } from ".";

export default (menu: IItemMenu, catalog: ICatalog): IHydratedItemMenu => {
  let hydratedMenu: IHydratedItemMenu = { ...menu, menu_parts: [] };

  for (const menuPartId of menu.menu_parts_ids) {
    const partIndex = findIndex(catalog.menu_part, { id: menuPartId });
    if (~partIndex) {
      const hydratedPart = hydrateMenuPart(
        catalog.menu_part[partIndex],
        catalog
      );
      hydratedMenu.menu_parts.push(hydratedPart);
    }
  }
  return hydratedMenu;
};
