import "./menu.scss";

import { mdiBasket, mdiSilverware } from "@mdi/js";
import dayjs from "dayjs";
import Error from "pages/Error";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import moduleState from "shared/atoms/module.atom";
import BasketMobileButton from "shared/components/BasketMobileButton";
import CatalogPreloader from "shared/components/CatalogPreloader";
import DishModal from "shared/components/DishModal";
import ItemModal from "shared/components/ItemModal";
import RestaurantBasket from "shared/components/RestaurantBasket";
import RestaurantCatalogCategory from "shared/components/RestaurantCatalogCategory";
import RestaurantNav from "shared/components/RestaurantNav";
import useFetchCatalog from "shared/hooks/useFetchCatalog.hook";
import useFetchRestaurant from "shared/hooks/useFetchRestaurant.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import {
  IHydratedItemDish,
  IHydratedItemMenu,
  IHydratedTag,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { formatPriceForBasket } from "shared/utils/commons";
import { getClosestOpeningDate } from "shared/utils/date";
import { getTotalPrice } from "shared/utils/localOrder";
import {
  isRestaurantOpen,
  itemFilteredByAvailability,
} from "shared/utils/restaurant";

import ItemModalReadOnly from "./components/ItemModalReadOnly";

export const Menu = () => {
  const [searchParams] = useSearchParams();
  const restaurantId = searchParams.get("restaurantId");
  const {
    data: restaurant,
    isLoading: loadingRestaurant,
    isError: restaurantError,
  } = useFetchRestaurant(`${restaurantId}`);
  const { module } = useRecoilValue(moduleState);
  const { addItem, localOrder, setLocalOrder } = useLocalOrder();

  const [activePill, setActivePill] = useState<number>(0);
  const [visible, setVisible] = useState(true);
  const closestDate =
    restaurant &&
    getClosestOpeningDate(restaurant, module.max_days, localOrder, true);
  useEffect(() => {
    if (restaurant) {
      setLocalOrder({
        ...localOrder,
        due_date: closestDate ?? dayjs().toDate(),
      });
    }
  }, [restaurant]);
  const { t } = useTranslation();

  const {
    data: catalog,
    isLoading: loadingCatalog,
    isError: catalogError,
  } = useFetchCatalog(`${restaurantId}`);
  const [addingItem, setAddingItem] = useState<
    IHydratedItemMenu | IHydratedItemDish | undefined
  >(undefined);
  const [modifyItemOrder, setModifyItemOrder] = useState<
    IOrderItem | undefined
  >(undefined);

  const [modifyMenuOrder, setModifyMenuOrder] = useState<
    ICurrentMenuState | undefined
  >(undefined);

  const [, setShowBasket] = useState<boolean | undefined>(false);
  if (loadingCatalog || loadingRestaurant || !restaurant) {
    return <CatalogPreloader />;
  }

  //Allow to pass an order or just read the menu
  //If the restaurant is not open or other reason to not let the client order
  //TODO: replace boolean, make it dynamic with server info
  const isMenuReadOnly: boolean = !isRestaurantOpen(restaurant) || true;

  const localOrderIds = Object.keys(localOrder.items);
  const totalAmountBasket = getTotalPrice(
    localOrderIds.map((id) => localOrder.items[id])
  );
  const totalBasket = formatPriceForBasket(totalAmountBasket);
  const minimumPrice = module.minimum_price;
  const canCheckout = !!totalAmountBasket && totalAmountBasket >= minimumPrice;

  const handleDisplayToast = () => {
    toast(t<string>("basketSummary.toasterOnRemoveItem"), {
      duration: 5000,
      icon: "ℹ️",
    });
  };

  function handleVisible(index: number, isVisible: boolean) {
    if (isVisible) {
      setVisible(true);
      setActivePill(index);
    }
  }

  const onChange = (index: number, tag: IHydratedTag) => {
    setActivePill(index);
    let section = document.getElementById(tag.id);
    section && section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  // 404 page if the restaurant does not exists
  if (restaurantError) {
    return <Error icon={mdiBasket} translation="errors.restaurantNotFound" />;
  }

  return (
    <div className="menu-catalog">
      {!catalogError && (
        <RestaurantNav
          {...{ restaurant, catalog, onChange, visible, activePill }}
        />
      )}
      <Container fluid={isMenuReadOnly}>
        <Row className="mt-4 d-flex justify-content-center ">
          <Col md={isMenuReadOnly ? 10 : 8} className="display-catalogue">
            {catalogError ? (
              <div className="pt-4">
                <Error
                  icon={mdiSilverware}
                  translation="errors.catalogNotFound"
                  disabledButton={true}
                />
              </div>
            ) : (
              <Row>
                {catalog &&
                  catalog.tags.map((tag: IHydratedTag, index: number) => {
                    const categoryHasItemsAvailable = tag.items.filter((item) =>
                      itemFilteredByAvailability(item, localOrder.due_date)
                    );

                    return categoryHasItemsAvailable.length ? (
                      <RestaurantCatalogCategory
                        key={tag.id}
                        {...{
                          index,
                          handleVisible,
                          tag,
                          catalog,
                          addItem,
                          setAddingItem,
                          isMenuReadOnly,
                        }}
                      />
                    ) : null;
                  })}
              </Row>
            )}
          </Col>
          {!isMenuReadOnly && (
            <Col className="d-none d-sm-block">
              <RestaurantBasket
                allowEditRestaurant={false}
                {...{
                  localOrder,
                  localOrderIds,
                  totalBasket,
                  setModifyItemOrder,
                  setAddingItem,
                  setModifyMenuOrder,
                  restaurant,
                  setShowBasket,
                  catalog,
                  canCheckout,
                  minimumPrice,
                  handleDisplayToast,
                  totalAmountBasket,
                  catalogError,
                }}
              />
            </Col>
          )}
        </Row>

        {!isMenuReadOnly && (
          <Col className="d-sm-none d-flex flex-column container-mobile">
            <BasketMobileButton
              {...{
                totalBasket,
                localOrderIds,
                localOrder,
                restaurant,
                setModifyItemOrder,
                setAddingItem,
                setModifyMenuOrder,
                catalog,
                canCheckout,
                handleDisplayToast,
                minimumPrice,
                totalAmountBasket,
                catalogError,
              }}
            />
          </Col>
        )}
      </Container>

      {addingItem && catalog ? (
        isMenuReadOnly ? (
          <ItemModalReadOnly
            item={addingItem}
            onClose={() => {
              setAddingItem(undefined);
              setModifyItemOrder(undefined);
            }}
          />
        ) : addingItem.type === "menu" ? (
          <ItemModal
            item={addingItem}
            onClose={() => {
              setAddingItem(undefined);
              setModifyItemOrder(undefined);
              setModifyMenuOrder(undefined);
            }}
            {...{ modifyItemOrder, modifyMenuOrder, catalog }}
          />
        ) : (
          <DishModal
            item={addingItem}
            modifyItemOrder={modifyItemOrder}
            onClose={() => {
              setAddingItem(undefined);
              setModifyItemOrder(undefined);
            }}
            aLaCarte={true}
          />
        )
      ) : null}

      <Toaster position="bottom-center" />
    </div>
  );
};
