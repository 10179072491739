import { atom } from "recoil";
import { dummyModule } from "shared/components/Debug/dummy";
import IModule from "shared/interfaces/Module.interface";

export interface IModuleState {
  module: IModule;
  jwtToken?: { token: string; tokenExpirationDate: Date };
  loading: boolean;
  moduleLoaded: boolean;
  error: boolean;
}

const moduleState = atom<IModuleState>({
  key: "moduleState",
  default: {
    module: dummyModule,
    loading: false,
    error: false,
    moduleLoaded: false,
  },
});

export default moduleState;
