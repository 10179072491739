import "react-phone-number-input/style.css";

import "./custom-phone-input.scss";

import { mdiAccount } from "@mdi/js";
import Icon from "@mdi/react";
import { CountryCode } from "libphonenumber-js/types";
import { Col, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import Input from "shared/components/Input";

export const CheckoutCustomer = () => {
  const { t, i18n } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Row>
      <div className="d-flex mt-4 mb-0">
        <Icon path={mdiAccount} size={0.8} className="me-1 icon-payment" />
        <h5 className="order-subtitle">{t("checkout.personalInformation")}</h5>
      </div>
      <Row className="my-3 pe-0 input-group-form">
        <Col xs={12} md={6} className="pe-0">
          <Controller
            control={control}
            name="first_name"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder={t("checkout.firstName.label")}
                label={t("checkout.firstName.label")}
                className="input-font-size-custom"
                errorMessages={[errors.first_name?.message]}
                isInvalid={!!errors.first_name}
              />
            )}
          />
        </Col>
        <Col className="pe-0">
          <Controller
            control={control}
            name="last_name"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder={t("checkout.lastName.label")}
                label={t("checkout.lastName.label")}
                className="input-font-size-custom"
                errorMessages={[errors.last_name?.message]}
                isInvalid={!!errors.last_name}
              />
            )}
          />
        </Col>
      </Row>
      <Row className="mb-3 pe-0 input-group-form">
        <Col xs={12} md={6} className="pe-0">
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                type="email"
                placeholder={t("checkout.email.label")}
                label={t("checkout.email.label")}
                className="input-font-size-custom"
                errorMessages={[errors.email?.message]}
                isInvalid={!!errors.email}
              />
            )}
          />
        </Col>
        <Col xs={12} md={6} className="pe-0 mb-3">
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                className={`input-font-size-custom ${
                  errors.phone ? "phone-input-error" : ""
                }`}
                placeholder={t("checkout.phoneNumber.label")}
                defaultCountry={i18n.language.toUpperCase() as CountryCode}
              />
            )}
          />
          {errors.phone && (
            <div className="input-font-size-custom invalid-feedback d-block">
              {errors.phone.message}
            </div>
          )}
        </Col>
      </Row>
    </Row>
  );
};
