import { Marker, useMap } from "@monsonjeremy/react-leaflet";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";

export const LocationMarker = () => {
  const [position, setPosition] = useState<number[]>();
  let firstRender = useRef(false);
  const map = useMap();
  let location = useRef(L.circleMarker([48.8802767, 2.3590744]));

  useEffect(() => {
    if (!firstRender.current) {
      map.locate().on("locationfound", function (e) {
        map.removeLayer(location.current);
        setPosition([e.latlng.lat, e.latlng.lng]);
        map.flyTo(e.latlng, 17);
        setTimeout(() => {
          const radius = e.accuracy;
          map.createPane("markerLocation");
          location.current = L.circle(e.latlng, {
            pane: "markerLocation",
            stroke: true,
            radius,
            interactive: false,
            fillColor: "#012999",
            fillOpacity: 0.2,
            color: "#012999",
            weight: 1,
            opacity: 0.8,
          });
          map.addLayer(location.current);
        }, 2000);
      });
      firstRender.current = true;
    }

    return () => {
      map.off();
    };
  }, [map, firstRender]);

  const locationMarker = new L.Icon({
    iconUrl: "https://media.zelty.fr/images/903/a5701.png",
    iconRetinaUrl: "https://media.zelty.fr/images/903/a5701.png",
    iconSize: [18, 18],
    iconAnchor: [9, 9],
  });

  if (position && position.length > 0) {
    return (
      <Marker
        // @ts-ignore
        position={position}
        icon={locationMarker}
      />
    );
  }

  return null;
};
