import { ILocalOrderState } from "shared/atoms/localOrder.atom";

export default (
  itemsAreUnavailabled: string[],
  localOrder: ILocalOrderState,
  removeItemById: (id: string) => void
) => {
  itemsAreUnavailabled.forEach((id: string) =>
    removeItemById(localOrder.items[id].id)
  );
};
