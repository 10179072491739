import "./address.scss";

import { mdiCrosshairsGps, mdiCrosshairsOff } from "@mdi/js";
import Icon from "@mdi/react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";
import Input from "shared/components/Input";
import Spinner from "shared/components/Spinner";
import useGeoLoc from "shared/hooks/useGeoLoc.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import IAddressResult, {
  IFeature,
} from "shared/interfaces/AddressResult.interface";

export function Address() {
  const { t } = useTranslation();
  const [addressResults, setAddressResults] = useState<IAddressResult | null>(
    null
  );
  const { localOrder, setLocalOrder } = useLocalOrder();
  const { geoLoc, getAddress } = useGeoLoc();
  const [addressProcessing, setAddressProcessing] = useState(false);
  let addressTo: NodeJS.Timeout | null = null;
  const addressInputRef = useRef<any>(null);
  const localizationIcon = useRef<any>(null);
  const navigate = useNavigate();

  const handleSuggestionSelected = (feature: IFeature) => {
    addressInputRef.current.value = feature.properties.label;
    setAddressResults(null);
  };

  useEffect(() => {
    // prefill the input field if an address already exists
    if (localOrder.delivery_address) {
      addressInputRef.current.value =
        localOrder.delivery_address.formatted_address;
    }
    // disabled to prevent a refill on state update
  }, []);

  const handleAddressChange = () => {
    if (addressTo) {
      clearTimeout(addressTo);
      addressTo = null;
    }
    if (addressProcessing) {
      return;
    }
    addressTo = setTimeout(() => {
      sendAddressQuery(addressInputRef.current.value);
    }, 1000);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // get the address from the current input value
    const delivery_address = addressInputRef.current.value;
    if (!delivery_address) {
      return;
    }

    setLocalOrder({ ...localOrder, delivery_address });
    navigate(routes.restaurants.path);
  };

  function handleGetGeoLoc() {
    // do nothing if geoloc not available
    if (!geoLoc.available) {
      return;
    }

    setAddressProcessing(true);
    getAddress()
      .then((address) => {
        if (address) {
          addressInputRef.current.value = address;
        }
      })
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setAddressProcessing(false);
      });
  }

  function sendAddressQuery(address: string) {
    setAddressProcessing(true);
    axios
      .get(
        "https://api-adresse.data.gouv.fr/search/?q=" +
          encodeURI(address) +
          "&type=housenumber&autocomplete=1"
      )
      .then((res) => {
        setAddressResults(res.data as IAddressResult);
      })
      .catch((err) => Sentry.captureException(err))
      .finally(() => {
        setAddressProcessing(false);
      });
  }

  return (
    <div className="address centered-component h-100 d-flex align-items-center">
      <Container>
        <Row>
          <Col md={4}>{/* <RestaurantHeader imageUrl={} /> */}</Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <h4>{t<string>("address.enterAddress")}</h4>
            <small>{t<string>("address.OrAddressGeolocation")}</small>
          </Col>
        </Row>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row>
            <Col md={4}>
              <Form.Group
                controlId="formAddress"
                className="address-input-container"
              >
                <Input
                  type="text"
                  autoComplete={"off"}
                  onKeyDown={handleAddressChange}
                  ref={addressInputRef}
                  placeholder={t<string>("address.addressInputPlaceholder")}
                  label={t<string>("address.addressInputPlaceholder")}
                />

                <Spinner visible={addressProcessing} />
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  delay={{ show: 250, hide: 0 }}
                  container={localizationIcon.current}
                  overlay={(props) => {
                    return (
                      <Tooltip id="localization-tooltip" {...props}>
                        {geoLoc.available && (
                          <>{t<string>("address.useCurrentLocation")}</>
                        )}
                        {!geoLoc.available && (
                          <>{t<string>("address.unavailableGeoLocation")}</>
                        )}
                      </Tooltip>
                    );
                  }}
                >
                  <Button
                    variant="link"
                    className={classNames("address-localize-icon-container", {
                      available: geoLoc.available,
                      discrete: addressInputRef.current?.value,
                    })}
                    onClick={handleGetGeoLoc}
                  >
                    {geoLoc.available && (
                      <Icon path={mdiCrosshairsGps} size={1} />
                    )}
                    {!geoLoc.available && (
                      <Icon path={mdiCrosshairsOff} size={1} />
                    )}
                  </Button>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mt-4">
              <Button variant="primary" type="submit" className="btn-block">
                {t<string>("address.addressConfirmButton")}
              </Button>
            </Col>
          </Row>

          {/* <Row className="mt-4">
            <RestaurantWebsiteLink />
          </Row> */}
          <Row className="address-suggestions-container">
            <Col md={6}>
              {addressResults?.features.map((feature, index) => {
                return (
                  <Row
                    key={index}
                    className="address-suggestion"
                    onClick={() => {
                      handleSuggestionSelected(feature);
                    }}
                  >
                    <Card>{feature.properties.label}</Card>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
}
