import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import getRestaurants from "services/api/restaurant/getRestaurants";
import localOrderState from "shared/atoms/localOrder.atom";
import moduleState from "shared/atoms/module.atom";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IModule from "shared/interfaces/Module.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { canOrder } from "shared/utils/restaurant";

/**
 * Sort the returned restaurants list
 *
 * Closed restaurants are put on the bottom of the list
 * @param restaurants
 * @returns
 */
function sortRestaurantsList(
  restaurants: IRestaurant[],
  localOrder: ILocalOrder,
  module: IModule
): IRestaurant[] {
  return restaurants.sort((a, b) => {
    if (!canOrder(a, module.max_days, localOrder)) {
      return 1;
    }
    if (canOrder(a, module.max_days, localOrder)) {
      return -1;
    }
    return 0;
  });
}

export default () => {
  const localOrder = useRecoilValue(localOrderState);
  const { module } = useRecoilValue(moduleState);

  return useQuery<IRestaurant[]>(["restaurants"], getRestaurants, {
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess: (restaurants) => {
      return sortRestaurantsList(restaurants, localOrder, module);
    },
    onError: (err) => {
      Sentry.captureException(err);
      return err;
    },
  });
};
