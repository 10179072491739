import { useMap } from "@monsonjeremy/react-leaflet";
import { LatLngBounds } from "leaflet";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export const ChangeView = (props: { markers: IRestaurant[] }) => {
  const map = useMap();
  const markers = props.markers;
  // const { module } = useRecoilValue(moduleState); to use after refacto restaurantlist
  let markerBounds = new LatLngBounds([]);

  if (markers.length === 0) {
    map.setView({ lat: 48.8802767, lng: 2.3590744 });
  }

  if (markers.length === 1) {
    map.setView({
      lat: markers[0].coordinates.lat,
      lng: markers[0].coordinates.lng,
    });
  } else if (markers.length && markers.length >= 1) {
    markers.forEach((marker: IRestaurant) => {
      markerBounds.extend([marker.coordinates.lat, marker.coordinates.lng]);
    });
    map.fitBounds(markerBounds, { padding: [100, 100] });
  }

  return null;
};
