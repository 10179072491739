import "./restaurant-order-informations-mobile.scss";

import { mdiStorefrontOutline, mdiWalk } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import moduleState from "shared/atoms/module.atom";
import RestaurantModal from "shared/components/RestaurantModal";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import { IHydratedCatalog } from "shared/interfaces/Catalog.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { getClosestOpeningDate } from "shared/utils/date";

export interface RestaurantOrderInformationsMobileProps {
  restaurant: IRestaurant;
  displayRestaurantModal: IRestaurant | null;
  setDisplayRestaurantModal: React.Dispatch<
    React.SetStateAction<IRestaurant | null>
  >;
  catalog: IHydratedCatalog | undefined;
  handleDisplayToast: () => void;
}

export const RestaurantOrderInformationsMobile = ({
  restaurant,
  displayRestaurantModal,
  setDisplayRestaurantModal,
  catalog,
  handleDisplayToast,
}: RestaurantOrderInformationsMobileProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { localOrder, setLocalOrder } = useLocalOrder();
  const { module } = useRecoilValue(moduleState);

  const closestDate = getClosestOpeningDate(
    restaurant,
    module.max_days,
    localOrder
  );

  const dateToDisplay = localOrder.due_date ?? closestDate;
  const dateToDisplayIsToday = dayjs().isSame(dateToDisplay, "day");

  return (
    <div className="px-4 pt-2">
      <div className="d-flex justify-content-between">
        <div>
          <Icon path={mdiWalk} size={0.8} />
          {`${
            dateToDisplayIsToday
              ? t<string>("dates.today")
              : dayjs(dateToDisplay)
                  .locale(i18n.language)
                  .format(t<string>("topBar.deliveryDateFormat"))
          } ${t<string>("dates.at")}  
            ${dayjs(dateToDisplay).locale(i18n.language).format("HH:mm")}`}
        </div>
        <div
          className="modify-link ms-3"
          onClick={() => setDisplayRestaurantModal(restaurant)}
        >
          {" " + t<string>("commons.edit")}
        </div>
      </div>
      <div className="d-flex mt-2 justify-content-between">
        <div>
          <Icon path={mdiStorefrontOutline} size={0.8} />
          {restaurant.public_name}
        </div>
        <div
          onClick={() => navigate(routes.restaurants.path)}
          className="modify-link ms-3"
        >
          {" " + t<string>("commons.edit")}
        </div>
        <RestaurantModal
          handleDisplayToast={handleDisplayToast}
          catalog={catalog}
          activeRestaurant={displayRestaurantModal}
          onHide={() => {
            setDisplayRestaurantModal(null);
          }}
          onSelectionUpdate={(updatedDate) => {
            setLocalOrder({
              ...localOrder,
              due_date: updatedDate,
            });
            setDisplayRestaurantModal(null);
          }}
        />
      </div>
    </div>
  );
};
