import "./restaurant-nav-item.scss";

import classNames from "classnames";
import { IHydratedTag } from "shared/interfaces/Catalog.interface";

interface IRestaurantNavItem {
  onChange: (index: number, tag: IHydratedTag) => void;
  tag: IHydratedTag;
  visible: boolean;
  activePill: number;
  index: number;
}

export const RestaurantNavItem = ({
  onChange,
  tag,
  visible,
  activePill,
  index,
}: IRestaurantNavItem) => {
  return (
    <li className="nav-item pill-item" key={tag.id}>
      <a
        className={classNames("nav-link", {
          active: visible && activePill === index,
        })}
        aria-current="page"
        href={`/`}
        onClick={(e) => {
          e.preventDefault();
          onChange(index, tag);
        }}
        data-scrollspy-id={tag.id}
      >
        {tag.name}
      </a>
    </li>
  );
};
