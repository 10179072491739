import "./item-recap-card.scss";

import { IOrderItem } from "shared/interfaces/Order.interface";

export const ItemRecapCard = (itemSelection: IOrderItem) => {
  const { name, modifiers } = itemSelection;
  return (
    <li>
      <div className="title-menu-part mb-1">{name}</div>
      {modifiers.length > 0 && (
        <ul>
          {modifiers.map((modifier: IOrderItem) => {
            return (
              <li className="modifier" key={modifier.id}>
                {modifier.name}
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};
