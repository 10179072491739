import { mdiEmoticonSadOutline } from "@mdi/js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Error from "pages/Error";
import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { routesArray } from "routes/routes";
import amplitudeInit from "services/amplitude/amplitude";
import initLocales from "services/locales/locales";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import Debug from "shared/components/Debug";
import Layout from "shared/components/Layout/Layout";
import OrderExpireModal from "shared/components/OrderExpiredModal";
import Preloader from "shared/components/Preloader";
import useAppInit from "shared/hooks/useAppInit.hook";

//I18n init
initLocales();
//Amplitude events tracker init
amplitudeInit();

// init sentry if the dsn is provided in the .env
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.05,
    environment: process.env.REACT_APP_ENV,
    release: `wo-front-${process.env.REACT_APP_BUILD_NUMBER}`,
    ignoreErrors: [/(?=.*Request)(?=.*40)/, /Network/],
  });
}

const App = () => {
  useAppInit();

  const {
    moduleLoaded,
    error: moduleError,
    module,
  }: IModuleState = useRecoilValue(moduleState);

  if (moduleError) {
    return (
      <div className="app">
        <Error
          disabledButton
          translation="errors.500"
          icon={mdiEmoticonSadOutline}
        />
      </div>
    );
  }

  return (
    <div className="app">
      <Preloader loaded={moduleLoaded} />

      {moduleLoaded && (
        <>
          <Routes>
            <Route path="/" element={<Layout module={module} />}>
              {routesArray().map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
              ))}
            </Route>
          </Routes>

          {process.env.NODE_ENV === "development" && <Debug />}
          <OrderExpireModal />
        </>
      )}
    </div>
  );
};

export default App;
