import dayjs from "dayjs";
import DAYS_OF_WEEK_FORMATED from "shared/constants/date/daysOfWeekFormated.const";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { formatDateWithHourAndMinutes } from "shared/utils/date";

/**
 * Check if the date is within the restaurant opening dates
 * @param restaurant
 * @returns {boolean}
 */
export default (date: Date, restaurant: IRestaurant): boolean => {
  const weekdayDate = DAYS_OF_WEEK_FORMATED[dayjs(date).weekday()];
  for (const opening of restaurant.opening_hours) {
    const isOpeningSameWeekDayThanDateSelected =
      opening.day_of_week === weekdayDate;

    if (isOpeningSameWeekDayThanDateSelected) {
      const dateIsBetweenTimePeriods = opening.time_periods.filter(
        (timePeriod) => {
          const dateFormatted = dayjs(date).toDate();

          const from = formatDateWithHourAndMinutes(
            timePeriod.start_time,
            dateFormatted
          );

          const to = formatDateWithHourAndMinutes(
            timePeriod.end_time,
            dateFormatted
          );

          return dayjs(date).isBetween(from, to);
        }
      );

      return !!dateIsBetweenTimePeriods.length;
    }
  }
  return false;
};
