import { Col, Container, Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";

export const CatalogPreloader = () => {
  return (
    <div className="restaurant-catalog preloader-component">
      <Container fluid className="restaurant-header">
        <Container>
          <div className="restaurant-catalog-header">
            <Col className="pb-2">
              <ContentLoader width={365} height={180}>
                <rect x="0" y="0" width="365" height="180" />
              </ContentLoader>
            </Col>
            <Col>
              <Row className="mt-2">
                <ContentLoader width={250} height={32}>
                  <rect x="0" y="0" width="250" height="32" />
                </ContentLoader>
              </Row>
              <Row className="mt-4">
                <ContentLoader width={250} height={16}>
                  <rect x="0" y="0" width="250" height="32" />
                </ContentLoader>
              </Row>
              <Row className="mt-4">
                <ContentLoader width={250} height={16}>
                  <rect x="0" y="0" width="250" height="32" />
                </ContentLoader>
              </Row>
              <Row className="mt-4">
                <ContentLoader width={250} height={16}>
                  <rect x="0" y="0" width="250" height="32" />
                </ContentLoader>
              </Row>
            </Col>
          </div>
        </Container>
      </Container>

      <Container fluid className="restaurant-nav-container p-2">
        <Container>
          <span className="restaurant-nav-separator mt-n2"></span>
          <ul className="nav nav-pills">
            <li className="nav-item p-2 pl-0 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pl-0 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pl-0 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pl-0 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
            <li className="nav-item p-2 pl-0 pt-0 pb-0">
              <ContentLoader width={60} height={35}>
                <rect x="0" y="0" width="60" height="35" />
              </ContentLoader>
            </li>
          </ul>
        </Container>
      </Container>

      <Container>
        <Row className="mt-4">
          <Col md={8}>
            <Row>
              <ContentLoader width={250} height={32}>
                <rect x="0" y="0" width="250" height="32" />
              </ContentLoader>
            </Row>
            <Row className="mt-2">
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
              <Col>
                <ContentLoader height={130}>
                  <rect x="0" y="0" width="400" height="130" />
                </ContentLoader>
              </Col>
            </Row>
          </Col>
          <Col>
            <ContentLoader width={350} height={200}>
              <rect x="0" y="0" width="350" height="300" />
            </ContentLoader>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
