import IModule from "shared/interfaces/Module.interface";
import IOrderMode from "shared/interfaces/OrderMode.interface";

/**
 * Check if the current mode is valid for the current context
 *
 * @param orderMode
 * @param restaurant
 * @param module
 * @returns
 */
export default (orderMode: IOrderMode | null, module: IModule): boolean => {
  if (!orderMode) {
    return false;
  }

  // if the selected mode is present in the allowed modes list from the module
  for (const allowedMode of module.allowed_order_mode) {
    if (orderMode.value === allowedMode) {
      return true;
    }
  }
  return false;
};
