import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import { IDeliveryAdress } from "shared/interfaces/Order.interface";

const { persistAtom } = recoilPersist();

export interface ILocalOrderState extends ILocalOrder {
  delivery_address?: IDeliveryAdress;
  expired?: boolean;
  due_date?: Date;
  restaurant_id: number;
  created?: Date;
  modified?: Date;
}

let defaultVal: ILocalOrderState = {
  items: {},
  expired: false,
  restaurant_id: 0,
};

const localOrderState = atom<ILocalOrderState>({
  key: "localOrderState",
  default: defaultVal,
  dangerouslyAllowMutability: true,
  effects_UNSTABLE: [persistAtom],
});

export default localOrderState;
