import { Outlet, matchPath, useLocation, useParams } from "react-router-dom";
import EVENT_TAGS, { PAGE_VIEW_MAPPING } from "services/amplitude/eventTags";
import HelmetApp from "shared/components/HelmetApp";
import IModule from "shared/interfaces/Module.interface";
import { tracker } from "shared/utils/amplitude";

import AnalyticsEvents from "./components/Analytics";
import TopBar from "./components/TopBar";

const Layout = ({ module }: { module: IModule }) => {
  const { pathname } = useLocation();
  const params = useParams();

  const pageToTrack = PAGE_VIEW_MAPPING.find((pathnameToTrack) =>
    matchPath(pathnameToTrack, pathname)
  );

  if (pageToTrack) {
    tracker(EVENT_TAGS.PAGE_VIEW, {
      ...params,
      ...pageToTrack,
      module_id: module.id,
    });
  }

  return (
    <>
      <HelmetApp module={module} />
      <TopBar />
      <div className="content">
        <AnalyticsEvents>
          <Outlet />
        </AnalyticsEvents>
      </div>
    </>
  );
};

export default Layout;
