import Address from "pages/Address";
import Error from "pages/Error";
import FollowOrder from "pages/FollowOrder";
import Login from "pages/Login";
import Menu from "pages/Menu";
import Mode from "pages/Mode";
import RestaurantCatalog from "pages/RestaurantCatalog";
import RestaurantCheckout from "pages/RestaurantCheckout";
import RestaurantsList from "pages/RestaurantsList";
import SignUp from "pages/SignUp";
import UiKit from "pages/UiKit";
import IRoute from "shared/interfaces/Route.interface";
import IRoutesCollection from "shared/interfaces/RoutesCollection.interface";

const routes: IRoutesCollection = {
  mode: { path: "/", name: "mode", Component: Mode, checkUrlParams: false },
  kit: { path: "/ui", name: "kit", Component: UiKit, checkUrlParams: false },

  // login: {
  //   path: "/login",
  //   name: "login",
  //   Component: Login,
  //   checkUrlParams: false,
  // },
  // signup: {
  //   path: "/signup",
  //   name: "signup",
  //   Component: SignUp,
  //   checkUrlParams: false,
  // },

  restaurants: {
    path: "/restaurants",
    name: "restaurants",
    Component: RestaurantsList,
    checkUrlParams: true,
  },
  catalog: {
    path: "/restaurants/:restaurantId",
    name: "catalog",
    Component: RestaurantCatalog,
    checkUrlParams: true,
  },
  checkout: {
    path: "/restaurants/:restaurantId/checkout",
    name: "checkout",
    Component: RestaurantCheckout,
    checkUrlParams: true,
  },
  menu: {
    path: "/menu",
    name: "menu",
    Component: Menu,
    checkUrlParams: false,
  },
  address: {
    path: "/address",
    name: "address",
    Component: Address,
    checkUrlParams: false,
  },
  followOrder: {
    path: "/orders/:orderId",
    name: "order",
    Component: FollowOrder,
    checkUrlParams: false,
  },
  fallback: {
    path: "/*",
    name: "fallback",
    Component: Error,
    checkUrlParams: false,
  },
};

export function routesArray() {
  const array: IRoute[] = [];

  Object.keys(routes).map((routeName) => {
    return array.push(routes[routeName]);
  });
  return array;
}

export default routes;
