import "./item-recap-follow-order.scss";

import { Container, Row } from "react-bootstrap";
import { IPostOrderItem } from "shared/interfaces/Order.interface";
import { formatPriceForBasket } from "shared/utils/commons";
import { getTotalPrice } from "shared/utils/localOrder";

export const ItemRecapFollowOrder = ({ item }: { item: IPostOrderItem }) => {
  //@ts-ignore
  const totalPrice = getTotalPrice([item]);

  return (
    <Container className="p-0 my-3 item-container">
      <div className="d-flex justify-content-between">
        <div className="item-title ">
          {item.quantity} x {item.name}
        </div>
        <div className="item-title text-end color-zelty">
          {formatPriceForBasket(totalPrice)}
        </div>
      </div>
      {item?.modifiers?.length > 0 ? (
        <Row className="px-4">
          <ul>
            {item.modifiers.map((modifierFirstLevel: IPostOrderItem) => {
              return (
                <li
                  className="modifier-first-level text-muted d-flex flex-column pb-1"
                  key={modifierFirstLevel.item_id}
                >
                  {modifierFirstLevel.name}
                  {modifierFirstLevel.modifiers &&
                  modifierFirstLevel.modifiers.length > 0 ? (
                    <Row>
                      <ul className="ps-4 ms-4">
                        {modifierFirstLevel.modifiers.map(
                          (modifierSecondLevel: IPostOrderItem) => {
                            return (
                              <li
                                className="modifier-second-level text-muted"
                                key={modifierSecondLevel.item_id}
                              >
                                {modifierSecondLevel.name}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </Row>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </Row>
      ) : null}
    </Container>
  );
};
