import { IItemImage } from "shared/interfaces/Catalog.interface";

const fallbackImageUrl = "/missing.png";

/**
 * Return an image for the item
 * @param imageObj
 * @returns
 */
export default (
  imageObj?: IItemImage,
  size: "small" | "original" = "original"
): string => {
  // if no image, return the default image
  if (!imageObj) {
    return fallbackImageUrl;
  }

  // return thumbnail if size is small
  if (imageObj.small && size === "small") {
    return imageObj.small.url;
  }

  // return original size if thumbnail not available
  if (imageObj.original) {
    return imageObj.original.url;
  }

  // return default image if no source found
  return fallbackImageUrl;
};
