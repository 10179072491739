import { mdiMapMarker, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import RestaurantOpening from "shared/components/RestaurantOpening";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export const RestaurantCatalogDescription = ({
  restaurant,
  isMenu,
}: {
  restaurant: IRestaurant;
  isMenu?: boolean;
}) => {
  return (
    <div className="m-0 d-flex flex-column">
      {!isMenu && (
        <div>
          <div className="mb-1 item-restaurant">
            <Icon size={0.7} className="me-1" path={mdiMapMarker} />
            {restaurant.address}
          </div>
          {restaurant.phone ? (
            <div className="mb-1 item-restaurant">
              <Icon size={0.7} className="me-1" path={mdiPhone} />
              {restaurant.phone}
            </div>
          ) : null}
        </div>
      )}
      <div className="mb-3 item-restaurant-opening-hours">
        <RestaurantOpening restaurant={restaurant} />
      </div>
    </div>
  );
};
