import "./restaurant-nav-header.scss";

import { Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ZImage from "shared/components/ZImage";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import RestaurantCatalogDescription from "./RestaurantCatalogDescription";

export const RestaurantNavHeader = ({
  restaurant,
}: {
  restaurant: IRestaurant;
}) => {
  const location = useLocation();
  const isMenu = location.pathname === "/menu";

  return (
    <Container fluid className="restaurant-header">
      <Container className="pt-4">
        <div className="restaurant-catalog-header">
          {!isMenu && (
            <Col>
              <div className="restaurant-header d-flex justify-content-end mb-2">
                <ZImage
                  src={restaurant.cover_url}
                  alt={`Cover ${restaurant.public_name}`}
                  className="img-fluid"
                  disablespinner
                />
              </div>
            </Col>
          )}
          <Col>
            <h2 className="title-restaurant">{restaurant.public_name}</h2>
            <RestaurantCatalogDescription
              restaurant={restaurant}
              isMenu={isMenu}
            />
          </Col>
        </div>
      </Container>
    </Container>
  );
};
