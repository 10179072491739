import { mdiMopedOutline, mdiSilverwareForkKnife, mdiWalk } from "@mdi/js";
import IOrderMode from "shared/interfaces/OrderMode.interface";

const ORDER_MODES: IOrderMode[] = [
  {
    value: 2,
    label: "orderMode.delivery",
    id: "delivery",
    icon: mdiMopedOutline,
  },
  {
    value: 0,
    label: "orderMode.eatIn",
    id: "eat_in",
    icon: mdiSilverwareForkKnife,
  },
  {
    value: 1,
    label: "orderMode.takeAway",
    id: "takeaway",
    icon: mdiWalk,
  },
];

export default ORDER_MODES;
