import "./dish-modal.scss";

import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Counter from "shared/components/Counter";
import ZImage from "shared/components/ZImage";
import useCurrentMenu from "shared/hooks/useCurrentMenu.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import {
  IHydratedItemDish,
  IHydratedOption,
  IOptionValue,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import {
  addModifier,
  countModifierOption,
  displayPrice,
  findOptionValueQuantity,
  hasMinimumCountModifierOption,
  hasModifier,
  removeModifier,
} from "shared/utils/order";
import { getItemImage } from "shared/utils/restaurant";

import {
  DishOptionBody,
  DishOptionTitle,
  DishQuantityCounter,
} from "./components";

interface ItemDishProps {
  item: IHydratedItemDish;
  orderItem: IOrderItem;
  handleClose: () => void;
  aLaCarte: boolean;
  currentMenuPartName: string;
  modifyItemOrder: IOrderItem | undefined;
}
export const ItemDish = ({
  item,
  orderItem,
  handleClose,
  aLaCarte,
  currentMenuPartName,
  modifyItemOrder,
}: ItemDishProps) => {
  const { t } = useTranslation();

  const [currentDish, setCurrentDish] = useState<IOrderItem>(
    aLaCarte && modifyItemOrder ? modifyItemOrder : orderItem
  );
  const { addItem, modifyItem } = useLocalOrder();
  const { addMenuPartItem, currentMenuState } = useCurrentMenu();

  useEffect(() => {
    const currentMenuPartModifier = currentMenuState.modifiers.find(
      (modifier) => modifier.menuPartId === currentDish.group_id
    );
    const itemFromMenuState = currentMenuPartModifier?.items.find(
      (item) => item.id === currentDish.id
    );
    itemFromMenuState && setCurrentDish(itemFromMenuState);
  }, []);

  const handleSubmitButton = (orderItem: IOrderItem): void => {
    if (aLaCarte && modifyItemOrder) {
      const addedItem = { addedItem: item };
      return modifyItem({ ...orderItem, modalItem: addedItem });
    }
    if (aLaCarte) {
      const addedItem = { addedItem: item };
      return addItem(
        { ...orderItem, modalItem: addedItem },
        currentDish.quantity
      );
    }
    addMenuPartItem(currentDish, currentMenuPartName);
  };

  const handleOptions = (
    value: IOptionValue,
    option: IHydratedOption,
    addDisabledOnCheckbox: boolean
  ): void => {
    if (hasModifier(value, currentDish)) {
      setCurrentDish(removeModifier(option, value, currentDish, true));
    } else if (!addDisabledOnCheckbox) {
      setCurrentDish(addModifier(option, value, currentDish));
    }
  };

  return (
    <>
      <Modal.Body className="modal-dish-body">
        <div className="dish-presentation-container">
          <div className="close-button">
            <CloseButton onClick={handleClose} />
          </div>
          <ZImage disablespinner src={getItemImage(item.image)} />
          <div className="dish-presentation">
            <div className="w-100 fw-bold fs-5">{item.name}</div>
            {item.description && (
              <Row className="mt-2">
                <span>{item.description}</span>
              </Row>
            )}
            {/* <Row>
              <Col className="my-2">
                <span className="badge badge-custom me-1">Custom Label</span>
                <span className="badge badge-custom me-1">Custom Label</span>
              </Col>
            </Row> */}
          </div>
        </div>
        <div className="dish-options-container">
          {item.options.map((option) => (
            <div key={option.id}>
              <DishOptionTitle option={option} currentDish={currentDish} />
              {option.values.map((value) => {
                const quantity = findOptionValueQuantity(currentDish, value);
                const addDisabled =
                  countModifierOption(option, currentDish) >=
                  option.maximum_choices;
                return (
                  <div key={value.id}>
                    <DishOptionBody
                      value={value}
                      option={option}
                      handleOptions={handleOptions}
                      hasModifier={() => hasModifier(value, currentDish)}
                      displayPrice={displayPrice}
                      currentDish={currentDish}
                      addDisabled={addDisabled}
                    >
                      {option.maximum_choices > 1 && option.pick_same_value && (
                        <Counter
                          add={() =>
                            setCurrentDish(
                              addModifier(option, value, currentDish)
                            )
                          }
                          remove={() =>
                            setCurrentDish(
                              removeModifier(option, value, currentDish)
                            )
                          }
                          quantity={quantity}
                          addDisabled={addDisabled || item.disabled}
                          removeDisabled={quantity === 0 || item.disabled}
                        />
                      )}
                    </DishOptionBody>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {aLaCarte && !modifyItemOrder && (
          <DishQuantityCounter
            currentDish={currentDish}
            setCurrentDish={setCurrentDish}
            item={item}
          />
        )}
        <Col>
          <Button
            variant={
              hasMinimumCountModifierOption(item, currentDish) && !item.disabled
                ? "primary"
                : "secondary"
            }
            className="btn-block my-1"
            onClick={() => {
              if (!item.disabled) {
                handleSubmitButton(orderItem);
                handleClose();
              }
            }}
            disabled={
              !hasMinimumCountModifierOption(item, currentDish) || item.disabled
            }
          >
            {t<string>(
              item.disabled ? "commons.itemDisabled" : "commons.confirm"
            )}
          </Button>
        </Col>
      </Modal.Footer>
    </>
  );
};
