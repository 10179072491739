import { TFunction } from "react-i18next";
import DAYS_OF_WEEK_FORMATED from "shared/constants/date/daysOfWeekFormated.const";
import { IHoursCollection } from "shared/interfaces/Restaurant.interface";
type TObjectWithKey = {
  [key: number]: string;
};

export default (
  hours: IHoursCollection[],
  t: TFunction<"translation", undefined>
) => {
  let d,
    str = "";
  let days_str: TObjectWithKey = {};

  for (d = 0; d <= 6; ++d) {
    let day_str = "";
    let first = true;

    if (hours && d in hours) {
      for (let i = 0; i < hours[d].time_periods.length; ++i) {
        const d1 = hours[d].time_periods[i].start_time;
        const d2 = hours[d].time_periods[i].end_time;
        if (!first) {
          day_str += ", ";
        } else {
          first = false;
        }

        day_str += `${d1}-${d2}`;
      }
    }
    if (!day_str) {
      day_str = t("commons.closed");
    }
    days_str[d] = day_str;
  }
  let last_d = 0;
  for (d = 0; d <= 7; ++d) {
    if (d === 7 || days_str[d] !== days_str[last_d]) {
      if (d - last_d === 1) {
        //If its day 7, equivalent to sunday but got 2 different interfaces for dates/strings
        if (d === 7) {
          str += `\n${t(`dates.days.${DAYS_OF_WEEK_FORMATED[6]}`)} : ${
            days_str[6]
          }`;
        } else {
          str += `\n${t(`dates.days.${DAYS_OF_WEEK_FORMATED[d - 1]}`)} : ${
            days_str[last_d]
          }`;
        }
      } else if (d - last_d === 2) {
        const d1 = t(`dates.days.${DAYS_OF_WEEK_FORMATED[last_d]}`);
        const d2 = t(`dates.days.${DAYS_OF_WEEK_FORMATED[d - 1]}`);
        str += `\n${t("dates.d1", { d1, d2 })}${days_str[last_d]}`;
      } else {
        const d1 = t(`dates.days.${DAYS_OF_WEEK_FORMATED[last_d]}`);
        const d2 = t(`dates.days.${DAYS_OF_WEEK_FORMATED[d - 1]}`);
        str += `\n${t("dates.dm", { d1, d2 })}${days_str[last_d]}`;
      }
      last_d = d;
    }
  }
  if (str.length) {
    return str.substring(1);
  }
  return "";
};
