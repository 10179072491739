import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import getOrder from "services/api/order/getOrder";

export default (orderId: string, refetchInterval: number | false = false) =>
  useQuery(["order", orderId], () => getOrder(orderId), {
    retry: false,
    refetchInterval,
    onError: (err) => {
      Sentry.captureException(err);
      return err;
    },
  });
