import { IHydratedItemDish } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

import { countModifierOption } from ".";

export default (item: IHydratedItemDish, currentDish: IOrderItem): boolean => {
  const hasMininimum = item.options.map((item) => {
    if (countModifierOption(item, currentDish) >= item.minimum_choices) {
      return false;
    } else {
      return true;
    }
  });

  const checkMinimumCount = (hasMininimum: boolean[]) =>
    hasMininimum.every((min: boolean) => !min);
  const isEnabled = checkMinimumCount(hasMininimum);

  return isEnabled;
};
