import { atom } from "recoil";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export interface IRestaurantsListState {
  loading?: boolean;
  error?: boolean;
  restaurants: IRestaurant[];
}

const restaurantState = atom<IRestaurantsListState>({
  key: "restaurantsListState",
  default: {
    restaurants: [],
  },
});

export default restaurantState;
