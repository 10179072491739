import dayjs from "dayjs";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import { filterDatestoExcludeClosingWeekday, latestHourOfToday } from ".";

export default (
  dates: Date[],
  restaurant: IRestaurant,
  maxDays: number
): Date[] => {
  const newFiltered = filterDatestoExcludeClosingWeekday(
    dates,
    restaurant
  ) as Date[];

  const hasStillHoursAvailableToday = latestHourOfToday(restaurant);

  const datesFromToday = newFiltered.filter((date) =>
    restaurant.ordering_available && hasStillHoursAvailableToday
      ? dayjs().isSameOrBefore(date, "day")
      : dayjs().isBefore(date, "day")
  );

  const maxDate = dayjs().add(maxDays, "day");

  return datesFromToday.filter((date) => dayjs(date).isBefore(maxDate));
};
