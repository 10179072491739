import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";

export function OrderExpireModal() {
  const { t } = useTranslation();
  const { localOrder, setLocalOrder } = useLocalOrder();
  const navigate = useNavigate();
  function handleClose() {
    setLocalOrder({ ...localOrder, expired: false, due_date: undefined });
    navigate("/");
  }
  return (
    <Modal show={localOrder.expired} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t<string>("orderExpired.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{t<string>("orderExpired.message")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          {t<string>("commons.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
