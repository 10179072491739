import { atom } from "recoil";

interface IGeoLocState {
  loading: boolean;
  available: boolean;
  position?: GeolocationPosition;
}

const geoLocState = atom<IGeoLocState>({
  key: "geoLocState",
  default: {
    loading: false,
    available: false,
  },
});

export default geoLocState;
