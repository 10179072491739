import { Card } from "react-bootstrap";
import ContentLoader from "react-content-loader";

export const RestaurantsListPreloader = () => {
  return (
    <>
      <Card className="bordered shadowed card-primary card-disabled restaurant-card mt-4 p-3">
        <ContentLoader />
      </Card>
      <Card className="bordered shadowed card-primary card-disabled restaurant-card mt-4 p-3">
        <ContentLoader />
      </Card>
    </>
  );
};
