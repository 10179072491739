import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import authenticate from "services/api/authenticate";
import moduleAtom from "shared/atoms/module.atom";
import IModule from "shared/interfaces/Module.interface";
import {
  checkTokenIsExpired,
  convertTokenToItsExpirationDate,
} from "shared/utils/api";

const useAppInit = () => {
  const [moduleState, setModuleState] = useRecoilState(moduleAtom);
  const { i18n } = useTranslation();
  const [, setInitGa] = useState<void>();
  const [, setFacebookPixel] = useState<void>();
  const [, setOverrideColor] = useState<void>();

  // override color-zelty by color informed by restaurant
  useEffect(() => {
    moduleState.module.color !== null &&
      setOverrideColor(
        document.body.style.setProperty(
          "--color-zelty",
          `${moduleState.module.color}`
        )
      );
  }, [moduleState.module.color]);

  // override css by css_custom_file from restaurant
  const handleOverrideCustomCssFile = () => {
    const overrideCssFile = document.createElement("link");
    overrideCssFile.rel = " stylesheet";
    overrideCssFile.type = "text/css";
    overrideCssFile.href = `${moduleState.module.custom_css_url}`;
    moduleState.module.custom_css_url !== null &&
      document.head.appendChild(overrideCssFile);
  };

  const handleAuthenticate = useCallback(async (init?: boolean) => {
    try {
      const auth = await authenticate();
      const { token, module } = auth.data.data;
      const tokenExpirationDate = convertTokenToItsExpirationDate(token);

      setModuleState({
        ...moduleState,
        jwtToken: { token, tokenExpirationDate },
        moduleLoaded: true,
        loading: false,
        module,
      });

      if (init) {
        initAnalytics(module);
        handleOverrideCustomCssFile();
        initLangs(module.langs ?? [], module.default_lang ?? "en");
      }
    } catch (err) {
      Sentry.captureException(err);
      setModuleState({
        ...moduleState,
        moduleLoaded: false,
        error: true,
        loading: false,
      });
    }
  }, []);

  /**
   * Retrieve the modules list
   */
  useEffect(() => {
    if (!moduleState.jwtToken && !moduleState.loading) {
      handleAuthenticate(true);
      //Regenerate the token if its expired
    } else if (
      moduleState.jwtToken?.tokenExpirationDate &&
      checkTokenIsExpired(moduleState.jwtToken.tokenExpirationDate) &&
      !moduleState.loading
    ) {
      handleAuthenticate();
    }
  }, []);

  function initAnalytics(module: IModule) {
    setInitGa(ReactGA.initialize(`${module.google_analytics_id}`));
    setFacebookPixel(ReactPixel.init(`${module.fb_analytics_id}`));
  }

  function initLangs(availableLanguages: string[], defaultLang: string) {
    const userLanguageIsAvailable = availableLanguages.some(
      (lang) => lang === i18n.language.split("-")[0]
    );

    i18n.init({
      fallbackLng: availableLanguages,
      supportedLngs: availableLanguages,
    });

    if (!userLanguageIsAvailable) {
      i18n.changeLanguage(defaultLang);
    }
  }
};

export default useAppInit;
