import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import getCatalog from "services/api/catalog/getCatalog";

export default (restaurantId: string) =>
  useQuery(["catalog", restaurantId], () => getCatalog(restaurantId), {
    retry: 1,
    refetchOnWindowFocus: false,
    onError: (err) => {
      Sentry.captureException(err);
      return err;
    },
  });
