import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import getPaymentMethods from "services/api/order/getPaymentMethods";

export default (restaurantId: string) =>
  useQuery(
    ["paymentMethods", restaurantId],
    () => getPaymentMethods(restaurantId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        Sentry.captureException(error);
        return error;
      },
    }
  );
