export enum ANALYTICS_KEYS {
  ORDER = "order",
}

export enum FACEBOOK_ANALYTICS_KEYS {
  ORDER = "Purchase",
}

export const FB_KEYS = {
  [ANALYTICS_KEYS.ORDER]: FACEBOOK_ANALYTICS_KEYS.ORDER,
};
