import classNames from "classnames";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import EVENT_TAGS from "services/amplitude/eventTags";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import RestaurantModal from "shared/components/RestaurantModal";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { tracker } from "shared/utils/amplitude";
import { canOrder } from "shared/utils/restaurant";

import RestaurantDescription from "./RestaurantDescription";

export const RestaurantsListRender = ({
  restaurants,
}: {
  restaurants: IRestaurant[];
}) => {
  const { localOrder, setLocalOrder } = useLocalOrder();
  const { module }: IModuleState = useRecoilValue(moduleState);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeRestaurant, setActiveRestaurant] = useState<IRestaurant | null>(
    null
  );

  function handleSelectRestaurant(restaurant: IRestaurant) {
    if (!canOrder(restaurant, module.max_days, localOrder)) {
      return;
    }

    tracker(EVENT_TAGS.SET_RESTAURANT, {
      module_id: module.id,
      slug: location.pathname,
      restaurant_name: restaurant.public_name,
      restaurant_id: restaurant.id,
      step: "Restaurant",
    });
    setActiveRestaurant(restaurant);
  }

  return (
    <div>
      {restaurants.map((restaurant: IRestaurant) => {
        const isOrderAvailable = canOrder(
          restaurant,
          module.max_days,
          localOrder
        );
        return (
          <Card
            key={restaurant.id}
            id={`restaurant-${restaurant.id}`}
            className={classNames(
              "restaurant bordered shadowed card-primary restaurant-card mt-2 mb-4 p-3",
              {
                "card-disabled": !isOrderAvailable,
              }
            )}
          >
            <RestaurantDescription
              restaurant={restaurant}
              handleSelectRestaurant={handleSelectRestaurant}
              isOrderAvailable={isOrderAvailable}
            />
          </Card>
        );
      })}
      <RestaurantModal
        activeRestaurant={activeRestaurant}
        onHide={() => {
          setActiveRestaurant(null);
        }}
        onSelectionUpdate={(updatedDate, restaurantId) => {
          setLocalOrder({
            ...localOrder,
            due_date: updatedDate,
            restaurant_id: restaurantId,
          });
          // setActiveRestaurant(null);
          navigate(`${routes.restaurants.path}/${String(restaurantId)}`);
        }}
      />
    </div>
  );
};
