import "./restaurant-catalog-category.scss";

import { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import { useRecoilValue } from "recoil";
import localOrderState from "shared/atoms/localOrder.atom";
import moduleState from "shared/atoms/module.atom";
import RestaurantItem from "shared/components/RestaurantItem";
import {
  IHydratedCatalog,
  IHydratedItemDish,
  IHydratedItemMenu,
  IHydratedTag,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import {
  canAddDirectlyItemWithoutDisplay,
  itemFilteredByAvailability,
} from "shared/utils/restaurant";

interface RestaurantCatalogCategoryProps {
  index: number;
  handleVisible: (index: number, isVisible: boolean) => void;
  tag: IHydratedTag;
  catalog: IHydratedCatalog;
  addItem: (item: IOrderItem) => void;
  setAddingItem: Dispatch<
    SetStateAction<IHydratedItemDish | IHydratedItemMenu | undefined>
  >;
  isMenuReadOnly?: boolean;
}
export const RestaurantCatalogCategory = ({
  index,
  handleVisible,
  tag,
  catalog,
  addItem,
  setAddingItem,
  isMenuReadOnly = false,
}: RestaurantCatalogCategoryProps) => {
  const { due_date } = useRecoilValue(localOrderState);
  const {
    module: { force_display_modal_dish },
  } = useRecoilValue(moduleState);
  const noForce = !force_display_modal_dish;

  return (
    <Row key={index}>
      <VisibilitySensor
        onChange={(isVisible: boolean) => handleVisible(index, isVisible)}
        offset={{ bottom: 200 }}
      >
        <>
          <h4 id={tag.id} className="title-tag mt-4">
            {tag.name}
          </h4>
          {tag.items
            .filter((item) => itemFilteredByAvailability(item, due_date))
            .map((item: IHydratedItemDish | IHydratedItemMenu) => (
              <Col md={6} key={item.id} className="mb-4">
                <RestaurantItem
                  item={item}
                  catalog={catalog}
                  isMenuReadOnly={isMenuReadOnly}
                  forceDisplayModalDish={force_display_modal_dish}
                  onClick={() => {
                    if (!force_display_modal_dish && item.disabled) {
                      return null;
                    } else {
                      canAddDirectlyItemWithoutDisplay(
                        item,
                        isMenuReadOnly,
                        force_display_modal_dish
                      )
                        ? addItem({
                            id: item.id,
                            name: item.name,
                            unit_price: item.price,
                            modifiers: [],
                            quantity: 1,
                            type: "dish",
                            comment: "",
                            disabled: item.disabled,
                          })
                        : setAddingItem(item);
                    }
                  }}
                />
              </Col>
            ))}
        </>
      </VisibilitySensor>
    </Row>
  );
};
