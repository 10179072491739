import IModule from "shared/interfaces/Module.interface";

export default (module: IModule) => {
  let displayTrad = null;

  const cgvSpecified = module.cgv_url !== null && module.cgv_url !== "";
  const privacyPolicySpecified =
    module.privacy_policy_url !== null && module.privacy_policy_url !== "";

  if (cgvSpecified && privacyPolicySpecified) {
    return (displayTrad = "cgv_privacy");
  }
  if (cgvSpecified) {
    return (displayTrad = "cgv_url");
  }
  if (privacyPolicySpecified) {
    return (displayTrad = "privacy_policy");
  }
  return displayTrad;
};
