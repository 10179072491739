import { atom } from "recoil";
import { IPriceOverride } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

export interface ICurrentMenuState {
  id: string;
  unit_price: number;
  quantity: number;
  name: string;
  disabled: boolean;
  modifiers: {
    min: number;
    max: number;
    menuPartName: string;
    menuPartId: string;
    items: IOrderItem[];
  }[];
  price_override: IPriceOverride[];
}

const currentMenuState = atom<ICurrentMenuState>({
  key: "currentMenuState",
  default: {
    id: "",
    unit_price: 0,
    quantity: 0,
    name: "",
    modifiers: [],
    price_override: [],
    disabled: false,
  },
  dangerouslyAllowMutability: true,
});

export default currentMenuState;
