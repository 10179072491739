import { useTranslation } from "react-i18next";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { hoursToString } from "shared/utils/date";

interface RestaurantHoursProps {
  restaurant: IRestaurant;
}
interface TextProps {
  data: string;
}

function Text(props: TextProps) {
  return (
    <div className="formated-text-container">
      {props.data.split("\n").map((str, index) => (
        <div key={index}>{str}</div>
      ))}
    </div>
  );
}

export function RestaurantHours({ restaurant }: RestaurantHoursProps) {
  const { t } = useTranslation();

  if (restaurant.opening_hours_text) {
    return (
      <div>
        <Text data={restaurant.opening_hours_text} />
      </div>
    );
  }

  return <Text data={hoursToString(restaurant.opening_hours, t)} />;
}
