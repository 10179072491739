import ORDER_MODES from "shared/constants/orderModes.const";
import IModule from "shared/interfaces/Module.interface";
import IOrderMode from "shared/interfaces/OrderMode.interface";

import { orderingModeIsAvailable } from ".";

/**
 * Return a list of available modes for the given module
 * @param module
 * @returns
 */
export default (module: IModule): IOrderMode[] => {
  const availableModes: IOrderMode[] = [];
  for (const mode of ORDER_MODES) {
    if (orderingModeIsAvailable(mode.value, module)) {
      availableModes.push(mode);
    }
  }
  return availableModes;
};
