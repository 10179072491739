import "./restaurant-opening.scss";

import { mdiChevronDown, mdiChevronUp, mdiClock } from "@mdi/js";
import Icon from "@mdi/react";
import { useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RestaurantHours from "shared/components/RestaurantHours";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import {
  currentTimeSlotToday,
  formatDateWithHourAndMinutes,
} from "shared/utils/date";
import {
  isRestaurantClosingSoon,
  isRestaurantOpen,
  isRestaurantOpeningSoon,
} from "shared/utils/restaurant";

interface RestaurantOpeningProps {
  restaurant: IRestaurant;
}

export const RestaurantOpening = ({ restaurant }: RestaurantOpeningProps) => {
  const { t } = useTranslation();

  const [showRestaurantHours, setShowRestaurantHours] =
    useState<boolean>(false);

  const currentTimeSlot = currentTimeSlotToday(restaurant);

  const startTimeTodayFormatted =
    currentTimeSlot && formatDateWithHourAndMinutes(currentTimeSlot.start_time);

  const restaurantIsOpen = isRestaurantOpen(restaurant);
  const openingSoon =
    startTimeTodayFormatted && isRestaurantOpeningSoon(startTimeTodayFormatted);
  const closingSoon = isRestaurantClosingSoon(restaurant);

  const restaurantOpeningStatus = useMemo(() => {
    switch (true) {
      case openingSoon:
        return "restaurantsList.openingSoon";
      case closingSoon:
        return "restaurantsList.closingSoon";
      case restaurantIsOpen:
        return "restaurantsList.open";
      default:
        return "restaurantsList.closed";
    }
  }, [restaurant, openingSoon, closingSoon, restaurantIsOpen]);

  return (
    <>
      <div
        className="hours-label"
        onClick={() => setShowRestaurantHours(!showRestaurantHours)}
        aria-controls="restaurantHours"
        aria-expanded={showRestaurantHours}
      >
        <Icon className="me-2 hours-icon" size={0.7} path={mdiClock} />
        <span>{t(restaurantOpeningStatus)}</span>
        <Icon
          className="me-2 hours-icon-custom"
          size={0.7}
          path={showRestaurantHours ? mdiChevronUp : mdiChevronDown}
        />
      </div>

      <Collapse in={showRestaurantHours}>
        <div className="pt-1 ps-4">
          <RestaurantHours restaurant={restaurant} />
        </div>
      </Collapse>
    </>
  );
};
