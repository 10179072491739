import { ILocalOrderState } from "shared/atoms/localOrder.atom";

export default (
  localOrder: ILocalOrderState,
  itemId: string
): ILocalOrderState | undefined => {
  const localOrderIds = Object.keys(localOrder.items);

  //Prevent to continue, if the local items List is empty
  if (!localOrderIds.length) return;
  const newState = { ...localOrder };
  localOrderIds.forEach((entityId: string) => {
    if (newState.items[entityId].id === itemId) {
      delete newState.items[entityId];
    }
  });
  return { ...newState };
};
