import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Counter from "shared/components/Counter";
import { IHydratedItemDish } from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

interface DishQuantityCounterProps {
  currentDish: IOrderItem;
  setCurrentDish: React.Dispatch<React.SetStateAction<IOrderItem>>;
  item: IHydratedItemDish;
}

export const DishQuantityCounter = ({
  currentDish,
  setCurrentDish,
  item,
}: DishQuantityCounterProps) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Row>
        <Counter
          maxi
          add={() =>
            setCurrentDish({
              ...currentDish,
              quantity: currentDish.quantity + 1,
            })
          }
          remove={() =>
            setCurrentDish({
              ...currentDish,
              quantity: currentDish.quantity - 1,
            })
          }
          quantity={currentDish.quantity}
          addDisabled={item.disabled}
          removeDisabled={currentDish.quantity === 1 || item.disabled}
        />
      </Row>
    </Col>
  );
};
