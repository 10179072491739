import "shared/components/RestaurantBasketSummary/restaurant-basket-summary.scss";

import { mdiBasket } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import HorizontalSeparator from "shared/components/HorizontalSeparator";
import RestaurantBasketSummary from "shared/components/RestaurantBasketSummary";
import RestaurantItemBasketCard from "shared/components/RestaurantItemBasketCard";
import { countQuantityItems } from "shared/utils/localOrder";

interface CheckoutBasketSummaryProps {
  totalBasketSummary: string;
  localOrder: ILocalOrderState;
  localOrderIds: string[];
  children?: React.ReactNode;
  summary?: React.ReactNode;
}

export const CheckoutBasketSummary = ({
  totalBasketSummary,
  localOrder,
  localOrderIds,
  summary,
  children,
}: CheckoutBasketSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Container className="bg-white p-0 pb-4 custom-sticky-position checkout-basket">
      <div className="p-2 text-center pt-4 hide-mobile basket-title-container">
        <div className="basket-icon">
          <Icon path={mdiBasket} size={1} className="color-zelty" />
        </div>

        <span className="fw-bold">
          {t<string>("restaurantBasket.mainTitle")}
        </span>
        <div className="basket-count-items">
          <small className="text-muted font-italic">
            ({countQuantityItems(localOrder)})
          </small>
        </div>
      </div>
      <HorizontalSeparator hideOnMobile />
      {summary && (
        <>
          <div className="items-list-container px-4 hide-mobile">{summary}</div>
          <HorizontalSeparator hideOnMobile />
        </>
      )}
      <div className="items-list-container px-4 hide-mobile">
        {localOrderIds.map((id) => (
          <RestaurantItemBasketCard
            key={id}
            item={localOrder.items[id]}
            isReadOnly
          />
        ))}
      </div>
      <HorizontalSeparator hideOnMobile />
      <RestaurantBasketSummary total={totalBasketSummary} />
      {children}
    </Container>
  );
};
