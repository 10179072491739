import ICatalog, {
  IItemDish,
  IItemMenu,
} from "shared/interfaces/Catalog.interface";

/**
 * Get a dish or a menu by it's id
 */
export default (
  id: string,
  catalog: ICatalog
): IItemDish | IItemMenu | null => {
  for (const menu of catalog.menus) {
    if (menu.id === id) {
      return menu;
    }
  }

  for (const dish of catalog.dishes) {
    if (dish.id === id) {
      return dish;
    }
  }

  return null;
};
