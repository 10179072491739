import { ILocalOrderState } from "shared/atoms/localOrder.atom";
import { IHydratedCatalog } from "shared/interfaces/Catalog.interface";

import { itemFilteredByAvailability } from "../restaurant";

/**
 * Compare the catalog info with the local order items
 * @param localOrder
 * @param catalog
 * @param selectedDate
 * @returns array of string with unavailable local order ids
 */
export default (
  localOrder: ILocalOrderState,
  catalog: IHydratedCatalog,
  selectedDate: Date
): string[] => {
  const localOrderIds = Object.keys(localOrder.items);
  const localOrderItemIdsNotAvailableAnymore = localOrderIds.filter((id) => {
    let itemMatch = null;
    //Try to find the catalog item with the local order item reference
    catalog.tags.find(
      (tag) =>
        (itemMatch = tag.items.find(
          (item) => item.id === localOrder.items[id].id
        ))
    );
    /* check if the corresponding local order item previously converted to catalog item 
    is still available on the selected date and return the opposite to get only the unavailable*/
    return itemMatch && !itemFilteredByAvailability(itemMatch, selectedDate);
  });

  return localOrderItemIdsNotAvailableAnymore;
};
