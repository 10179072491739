import dayjs from "dayjs";
import { useMemo } from "react";

export default (startTimeTodayFormatted: "" | dayjs.Dayjs) =>
  useMemo(
    () =>
      dayjs().isBetween(
        dayjs(startTimeTodayFormatted).subtract(15, "minute"),
        dayjs(startTimeTodayFormatted)
      ),
    []
  );
