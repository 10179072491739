import { IOrderItem } from "shared/interfaces/Order.interface";

/**
 * Recursive function to count item's price and its potentials modifiers included
 * @param listItems
 * @returns number
 */
export default function getTotalPrice(listItems: IOrderItem[]): number {
  return listItems.reduce((total, item) => {
    if (item.modifiers?.length > 0) {
      const totalModifiers = getTotalPrice(item.modifiers);
      const itemPriceWithModifiers = totalModifiers + item.unit_price;
      return total + item.quantity * itemPriceWithModifiers;
    }
    return total + item.quantity * item.unit_price;
  }, 0);
}
