import dayjs from "dayjs";
import IRestaurant from "shared/interfaces/Restaurant.interface";

export default (dates: Date[], restaurant: IRestaurant) => {
  const daysWithoutTimes = restaurant.opening_hours
    .map((openingDay) => {
      return !openingDay.time_periods.length && openingDay.day_of_week;
    })
    .filter(Boolean) as string[];
  const newDates = dates.map((date) => {
    const dateToDay: string = dayjs(date)
      .locale("en")
      .format("dddd")
      .toLowerCase();
    return !daysWithoutTimes.includes(dateToDay) && date;
  });

  return newDates;
};
