import "./restaurant-checkout.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { Elements as Stripe } from "@stripe/react-stripe-js";
import { Stripe as IStripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "routes/routes";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import userAtom from "shared/atoms/user.atom";
import RestaurantModal from "shared/components/RestaurantModal";
import Spinner from "shared/components/Spinner";
import { ANALYTICS_KEYS } from "shared/constants/analytics.const";
import useFetchRestaurant from "shared/hooks/useFetchRestaurant.hook";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import useFetchPaymentMethods from "shared/hooks/useUserOrder/useFetchPaymentMethods.hook";
import usePostOrder from "shared/hooks/useUserOrder/usePostOrder.hook";
import ICustomer from "shared/interfaces/Customer.interface";
import { IOrderComment } from "shared/interfaces/Module.interface";
import {
  IPaymentMethod,
  IUserPostOrder,
} from "shared/interfaces/Order.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { computeAnalyticsTrackEvent } from "shared/utils/analytics";
import { formatPriceForBasket } from "shared/utils/commons";
import {
  checkIsTimeExpired,
  getTotalPrice,
  orderIsValid,
} from "shared/utils/localOrder";
import { getOrderModeFromIndex } from "shared/utils/module";
import { convertLocalOrderToPostOrder } from "shared/utils/order";

import {
  CheckoutBasketSummary,
  CheckoutButtons,
  CheckoutCgvPrivacy,
  CheckoutCustomer,
  CheckoutOptins,
  CheckoutPayment,
  CommentForm,
  OrderSummary,
  StripeModal,
} from "./components";
import {
  checkoutFormSchemaYup,
  displayCgvPrivacy,
  isFormCompleted,
} from "./utils";

//Disabled stripe's tracker once the payment  is done
loadStripe.setLoadParameters({ advancedFraudSignals: false });

export function RestaurantCheckout() {
  const { t, i18n } = useTranslation();
  const CheckoutSchema = useMemo(() => checkoutFormSchemaYup(t), []);
  const userState = useRecoilValue(userAtom);
  const {
    customer: { first_name, last_name, email },
  } = userState;

  const defaultValues = {
    first_name,
    last_name,
    email,
    phone: "",
    comment: "",
    mail_optin: false,
    sms_optin: false,
    cgv: false,
  };
  const methods = useForm({
    resolver: yupResolver(CheckoutSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { isSubmitting },
  } = methods;
  const formWatcher = useWatch({ control });
  const customerCheckout = useMemo(() => getValues(), [formWatcher]);

  const [stripePromise, setStripePromise] =
    useState<Promise<IStripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);

  const navigate = useNavigate();
  const params = useParams<{ restaurantId: string; orderMode: string }>();
  const restaurantId = params.restaurantId;
  const { data: restaurant, isLoading: fetchRestaurantLoading } =
    useFetchRestaurant(`${restaurantId}`);
  const { module }: IModuleState = useRecoilValue(moduleState);
  const { localOrder, resetLocalOrder, setLocalOrder } = useLocalOrder();
  const localOrderIds = Object.keys(localOrder.items);

  const [userPaymentMethod, setUserPaymentMethod] = useState<IPaymentMethod[]>(
    []
  );
  const [, setDisplayRestaurantModal] = useState<IRestaurant | null>(null);

  const totalBasket = getTotalPrice(
    localOrderIds.map((id) => localOrder.items[id])
  );

  const [checkoutTotalBasket, setCheckoutTotalBasket] = useState(totalBasket);
  const minimumPrice = module.minimum_price;
  const canCheckout = checkoutTotalBasket >= minimumPrice;
  const {
    mutate,
    isLoading: postOrderIsLoading,
    data: dataResponsePostOrder,
  } = usePostOrder();

  useEffect(() => {
    if (
      !canCheckout &&
      !isSubmitting &&
      !postOrderIsLoading &&
      !dataResponsePostOrder
    )
      navigate(`${routes.restaurants.path}/${restaurantId}`);
  }, [localOrder]);

  useEffect(() => {
    restaurantId &&
      setLocalOrder({
        ...localOrder,
        restaurant_id: Number(`${restaurantId}`),
      });
  }, [restaurantId]);

  let methodsPayment: IPaymentMethod[] | undefined;
  let errorBms: boolean = false;
  let isLoadingPaymentMethods: boolean = false;

  if (module.transaction_method_id !== null) {
    ({
      data: methodsPayment,
      isLoading: isLoadingPaymentMethods,
      isError: errorBms,
    } = useFetchPaymentMethods(`${restaurantId}`));
  }

  const commentsDefault = module.comments?.map((comment) => ({
    ...comment,
    value: false,
  }));
  const [moduleComments, setModuleComments] = useState<IOrderComment[]>(
    commentsDefault ?? []
  );

  const totalBasketSummary = useMemo(
    () => formatPriceForBasket(checkoutTotalBasket),
    [checkoutTotalBasket]
  );

  const dateDelivery = dayjs(localOrder?.due_date)
    .locale(i18n.language)
    .format("dddd DD MMMM");

  const deliveryIsToday = dayjs(localOrder?.due_date)
    .locale(i18n.language)
    .isToday();

  const hoursDelivery = dayjs(localOrder?.due_date).format("HH:mm");

  const orderMode = getOrderModeFromIndex(localOrder.orderMode);
  const orderItems = localOrderIds.map((id) => localOrder.items[id]);
  const convertItems = useMemo(
    () => convertLocalOrderToPostOrder(orderItems),
    [orderItems]
  );
  const dueDate = dayjs(localOrder.due_date).format();
  const displayDate = dayjs(dueDate).toDate();

  const handleSubmitOrder = (customer_info: ICustomer) => {
    const online_payment_identifier = userPaymentMethod.length
      ? userPaymentMethod[0].identifier !== "offline"
        ? userPaymentMethod[0].identifier
        : null
      : null;
    restaurant && orderIsValid(localOrder, restaurant);
    mutate(
      {
        restaurant_id: localOrder.restaurant_id,
        total_price: checkoutTotalBasket,
        mode: orderMode?.id || "takeaway",
        due_date: displayDate,
        online_payment_identifier,
        total_discount: localOrder.total_discount || 0,
        discount_code: localOrder.discount_code || null,
        discount_id: localOrder.discount_id || null,
        catalog_id: module.catalog_id || "",
        customer_info,
        items: convertItems,
        origin_url: `${window.location.origin}`,
        comment: customerCheckout.comment,
        order_questions: moduleComments,
      },
      {
        onSuccess: (response: {
          data: {
            data: {
              order: IUserPostOrder;
              payment_instructions: {
                method: string;
                data: {
                  vars: { pk: string; intent_secret: string };
                  url: string;
                };
              };
            };
          };
        }) => {
          const {
            order: { id, items, total_price },
            payment_instructions,
          } = response.data.data;
          setCheckoutTotalBasket(total_price);
          setOrderId(id as string);
          computeAnalyticsTrackEvent(ANALYTICS_KEYS.ORDER, {
            items,
            price: total_price,
          });
          if (!payment_instructions) {
            resetLocalOrder();
            navigate(`/orders/${id}`);
          } else {
            switch (payment_instructions.method) {
              case "javascript":
                const { intent_secret, pk } = payment_instructions.data.vars;
                setStripePromise(
                  loadStripe(pk ?? `${process.env.REACT_APP_STRIPE_KEY}`)
                );
                setClientSecret(intent_secret);
                break;
              case "redirect":
                const url = payment_instructions.data.url;
                window.location.href = url;
                break;
              default:
                break;
            }
          }
        },
        onError: (err: any) => {
          toast.error(err.response.data.message || err.message);
        },
      }
    );
  };

  const handleCommentChecked = (commentIndex: number) => {
    moduleComments[commentIndex].value = !moduleComments[commentIndex].value;
    setModuleComments([...moduleComments]);
  };

  const checkIsBasketExpired = useMemo(
    () => checkIsTimeExpired(localOrder, restaurant),
    [localOrder, restaurant]
  );

  const handleOnSelectionUpdate = (updatedDate: Date) => {
    setLocalOrder({
      ...localOrder,
      due_date: updatedDate,
    });
    setDisplayRestaurantModal(null);
  };
  const formIsCompleted = useMemo(
    () =>
      isFormCompleted(
        customerCheckout,
        module,
        userPaymentMethod,
        methodsPayment
      ),
    [customerCheckout, module, userPaymentMethod, methodsPayment]
  );
  const cgvPrivacy = useMemo(() => displayCgvPrivacy(module), [module]);

  if (isLoadingPaymentMethods || postOrderIsLoading || fetchRestaurantLoading) {
    return <Spinner visible={true} />;
  }

  return (
    <div className="checkout-container">
      <Toaster />
      <Container>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(handleSubmitOrder)}
            className="form-custom"
          >
            <Row className="mt-1">
              <Button
                variant="link"
                className="back-catalog-link"
                onClick={() => {
                  navigate(`${routes.restaurants.path}/${restaurantId}`);
                }}
                disabled={!restaurant}
              >
                <Icon path={mdiChevronLeft} size={0.6} />
                {t<string>("checkout.backToBasket")}
              </Button>
              <h2 className="mb-3">{t<string>("checkout.title")}</h2>
              <Col md={7} xs={12}>
                {moduleComments.map((comment: IOrderComment) => {
                  const commentIndex = moduleComments.findIndex(
                    (moduleComment) => moduleComment.id === comment.id
                  );
                  return (
                    <Row key={comment.id}>
                      <Col xs={12}>
                        <span
                          onClick={() => handleCommentChecked(commentIndex)}
                        >
                          <Form.Check
                            type="switch"
                            className="item-switch mb-2"
                            checked={moduleComments[commentIndex].value}
                            readOnly
                            label={comment.title}
                          />
                        </span>
                      </Col>
                    </Row>
                  );
                })}
                <CheckoutCustomer />
                <Row>
                  {/* address is not yet ready for production */}
                  {/* <CheckoutAdress /> */}
                  <Col xs={12}>
                    <CommentForm />
                    {/*  // TODO: reactivate after FHT */}
                    {/* <CheckoutOptins /> */}
                  </Col>
                  <CheckoutPayment
                    onlyOnlinePayment={module.force_online_payment}
                    orderMode={orderMode?.id || ""}
                    setUserPaymentMethod={setUserPaymentMethod}
                    methodsPayment={methodsPayment}
                    errorBms={errorBms}
                  />
                </Row>
              </Col>
              <Col md={5} xs={12}>
                <CheckoutBasketSummary
                  totalBasketSummary={totalBasketSummary}
                  localOrder={localOrder}
                  localOrderIds={localOrderIds}
                  summary={
                    <OrderSummary
                      isToday={deliveryIsToday}
                      date={dateDelivery}
                      hours={hoursDelivery}
                      restaurantName={restaurant?.name || ""}
                      order={localOrder}
                    />
                  }
                >
                  <div className="mt-1 mb-3 px-4">
                    <CheckoutCgvPrivacy
                      cgvPrivacy={cgvPrivacy}
                      module={module}
                      cgv={customerCheckout.cgv}
                    />
                  </div>
                  <CheckoutButtons
                    formIsCompleted={formIsCompleted}
                    restaurant={restaurant}
                    restaurantId={restaurantId}
                    canCheckout={canCheckout}
                  />
                  {(cgvPrivacy === "privacy_policy" ||
                    cgvPrivacy === "cgv_privacy") && (
                    <p
                      className="opt-in-item my-2 px-4 text-center w-100"
                      dangerouslySetInnerHTML={{
                        __html: t<string>(`checkout.cgv.privacy_policy`, {
                          cgv: module.privacy_policy_url,
                        }),
                      }}
                    ></p>
                  )}
                </CheckoutBasketSummary>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </Container>
      {checkIsBasketExpired && restaurant && (
        <RestaurantModal
          activeRestaurant={restaurant}
          onHide={() => setDisplayRestaurantModal(null)}
          onSelectionUpdate={handleOnSelectionUpdate}
        />
      )}
      {/*@ts-ignore*/}
      <Stripe stripe={stripePromise}>
        {clientSecret && orderId ? (
          <StripeModal
            show={!!clientSecret}
            totalBasketSummary={totalBasketSummary}
            clientSecret={clientSecret}
            orderId={orderId}
            resetLocalOrder={resetLocalOrder}
            setClientSecret={setClientSecret}
          />
        ) : null}
      </Stripe>
    </div>
  );
}
