/**
 *Get the potential delay in minute according to its delivery mode
 * @param orderMode : takeaway | delivery | eatin
 * @param restaurant
 * @returns : number, delay in minute
 */

import IRestaurant from "shared/interfaces/Restaurant.interface";

export default (
  orderMode: string,
  restaurant: IRestaurant | undefined
): number => {
  switch (orderMode) {
    case "takeaway":
      return restaurant?.takeaway_delay ?? 0;
    case "delivery":
      return restaurant?.delivery_delay ?? 0;
    default:
      return 0;
  }
};
