import "shared/components/DishModal/components/ItemDish/dish-modal.scss";

import "./item-menu.scss";

import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import RestaurantItem from "shared/components/RestaurantItem";
import ZImage from "shared/components/ZImage";
import useCurrentMenu from "shared/hooks/useCurrentMenu.hook";
import ICatalog, {
  IHydratedItemDish,
  IHydratedItemMenu,
  IHydratedMenuPart,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { getFirstLetterUpperCaseRestLowerCase } from "shared/utils/commons";
import { getItemImage } from "shared/utils/restaurant";

import ItemsRecap from "./ItemsRecap";

interface ItemMenuProps {
  item: IHydratedItemMenu;
  catalog: ICatalog;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setAddingItem: React.Dispatch<
    React.SetStateAction<IHydratedItemDish | undefined>
  >;
  setCurrentMenuPartName: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  handleClose: () => void;
  modifyMenuOrder: ICurrentMenuState | undefined;
  modifyItemOrder: IOrderItem | undefined;
}

export const ItemMenu = ({
  item,
  catalog,
  setShow,
  setAddingItem,
  setCurrentMenuPartName,
  handleClose,
  modifyMenuOrder,
  modifyItemOrder,
}: ItemMenuProps) => {
  const { addMenuPartItem, currentMenuState } = useCurrentMenu();
  const { t } = useTranslation();

  const handleClick = (
    menuPart: IHydratedMenuPart,
    dish: IHydratedItemDish
  ) => {
    if (dish.is_sold_out) return null;

    setCurrentMenuPartName(menuPart.name);
    if (!dish.options.length) {
      addMenuPartItem(
        {
          id: dish.id,
          name: dish.name,
          unit_price: 0,
          modifiers: [],
          quantity: 1,
          type: "dish",
          comment: "",
          disabled: dish.disabled,
          ...(dish.group_id && { group_id: dish.group_id }),
        },
        menuPart.name
      );
    } else {
      setAddingItem(dish);
      setShow(false);
    }
  };

  return (
    <Modal.Body className="modal-menu-body position-relative">
      <Row>
        <Col md={7}>
          <div className="menu-presentation-container">
            <ZImage src={getItemImage(item.image)} />
            {item.description && (
              <div className="menu-presentation">
                <span>{item.description}</span>
              </div>
            )}
          </div>
          <Row>
            {item.menu_parts.map((menuPart) => {
              const currentMenuPartSelected = currentMenuState.modifiers.find(
                (currentMenuPart) => currentMenuPart.menuPartId === menuPart.id
              );
              const dishesAvailable = menuPart.dishes.filter(
                (dish) => !dish.is_sold_out
              );
              if (menuPart.dishes.length === 0) return null;
              return (
                <div key={menuPart.id}>
                  <div className="custom-option-title my-2 mt-4">
                    {getFirstLetterUpperCaseRestLowerCase(menuPart.name)}

                    {menuPart.minimum_choices > 0 && (
                      <>
                        {menuPart.maximum_choices > 1 && (
                          <span className="custom-option-title mx-3">
                            `${currentMenuPartSelected?.items.length}/$
                            {menuPart.maximum_choices}`
                          </span>
                        )}
                        <span className="minimum-choice ms-3">
                          {t<string>("itemModal.optionMinimum", {
                            minimum: menuPart.minimum_choices,
                          })}
                        </span>
                      </>
                    )}
                  </div>
                  {menuPart.dishes.map((dish, index: number) => {
                    const syncMenu = currentMenuState.modifiers.filter(
                      (modifier) => modifier.menuPartName === menuPart.name
                    );
                    if (
                      (menuPart.dishes.length === 1 ||
                        dishesAvailable.length === 1) &&
                      syncMenu[0]?.items.length === 0 &&
                      dish.options.length === 0
                    ) {
                      addMenuPartItem(
                        {
                          id: dish.id,
                          name: dish.name,
                          unit_price: 0,
                          modifiers: [],
                          quantity: 1,
                          type: "dish",
                          comment: "",
                          disabled: dish.disabled,
                          ...(dish.group_id && { group_id: dish.group_id }),
                        },
                        menuPart.name
                      );
                    }
                    return (
                      <Col md={12} key={index}>
                        <div
                          onClick={() => {
                            if (!dish.disabled) {
                              handleClick(menuPart, dish);
                            }
                          }}
                        >
                          <RestaurantItem
                            item={dish}
                            catalog={catalog}
                            isFromMenu
                          />
                        </div>
                      </Col>
                    );
                  })}
                </div>
              );
            })}
          </Row>
        </Col>
        <Col>
          <ItemsRecap
            handleClose={handleClose}
            item={item}
            modifyMenuOrder={modifyMenuOrder}
            modifyItemOrder={modifyItemOrder}
          />
        </Col>
      </Row>
    </Modal.Body>
  );
};
