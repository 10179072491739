import dayjs from "dayjs";
import ILocalOrder from "shared/interfaces/LocalOrder.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import { getIncludedTimesForDate } from ".";

export default (
  newDate: string,
  currentDate: Date,
  restaurant: IRestaurant,
  localOrder: ILocalOrder
): Date => {
  const formatedNewDate = dayjs(newDate);
  const newYear = formatedNewDate.year();
  const newMonth = formatedNewDate.month();
  const newDay = formatedNewDate.date();
  const updatedNewDateWithSameHour = dayjs(currentDate)
    .year(newYear)
    .month(newMonth)
    .date(newDay)
    .toDate();

  //Set the time updated with the new date
  const newTime = dayjs(
    getIncludedTimesForDate(
      restaurant,
      updatedNewDateWithSameHour,
      localOrder
    )[0]
  );
  const newHour = newTime.hour();
  const newMinute = newTime.minute();

  return dayjs(updatedNewDateWithSameHour)
    .hour(newHour)
    .minute(newMinute)
    .toDate();
};
