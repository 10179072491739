import "./empty-search-icon.scss";

import { mdiStorefrontOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

export const EmptySearchIcon = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center text-center text-muted p-4 mt-2">
      <Icon path={mdiStorefrontOutline} size={4} />
      <span className="text-empty p-2">
        {t<string>("restaurantsList.emptySearch")}
      </span>
    </div>
  );
};
