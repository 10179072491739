import { useState } from "react";
import { Modal } from "react-bootstrap";
import ItemModalHeader from "shared/components/ItemModalHeader";
import {
  IHydratedItemDish,
  IPriceOverride,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

import ItemDish from "./components/ItemDish";

interface DishModalProps {
  item: IHydratedItemDish;
  onClose: () => void;
  aLaCarte?: boolean;
  currentMenuPartName?: string;
  modifyItemOrder: IOrderItem | undefined;
}

export const DishModal = ({
  item,
  onClose,
  aLaCarte = false,
  currentMenuPartName = "",
  modifyItemOrder,
}: DishModalProps) => {
  if (!aLaCarte) {
    item.price_override.forEach((differentContext: IPriceOverride) => {
      const { contexts } = differentContext;
      const { context_type } = contexts[0];

      if (context_type === "menu_part") {
        item.price = differentContext.price;
      }
    });
  }
  const [orderItem] = useState<IOrderItem>({
    id: item.id,
    name: item.name,
    unit_price: item.price,
    modifiers: [],
    quantity: 1,
    type: "dish",
    comment: "",
    disabled: item.disabled,
    ...(item.group_id && { group_id: item.group_id }),
  });

  const [show, setShow] = useState(true);
  function handleClose() {
    onClose();
    setShow(false);
  }
  return (
    <Modal
      className="item-modal"
      show={show}
      size="sm"
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      <ItemDish
        item={item}
        orderItem={orderItem}
        handleClose={handleClose}
        aLaCarte={aLaCarte}
        currentMenuPartName={currentMenuPartName}
        modifyItemOrder={modifyItemOrder}
      />
    </Modal>
  );
};
