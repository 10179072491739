import {
  IHydratedOption,
  IOptionValue,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";

export default (
  option: IHydratedOption,
  value: IOptionValue,
  currentDish: IOrderItem,
  isCheckbox?: boolean
): IOrderItem => {
  if (option.maximum_choices === 1 && option.minimum_choices === 1) {
    return currentDish;
  } else {
    const index = currentDish.modifiers.findIndex(
      (modifier) => modifier.id === value.id
    );

    if (!~index) return currentDish;

    if (currentDish.modifiers[index].quantity > 1 && !isCheckbox) {
      currentDish.modifiers[index].quantity =
        currentDish.modifiers[index].quantity - 1;
      return { ...currentDish, modifiers: currentDish.modifiers };
    } else {
      currentDish.modifiers.splice(index, 1);
      return { ...currentDish, modifiers: currentDish.modifiers };
    }
  }
};
