import dayjs from "dayjs";
import IModule from "shared/interfaces/Module.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";

/**
 * Check if the current due_date is valid for the current context
 *
 * @param orderMode
 * @param dueDate
 * @param restaurant
 * @param module
 * @returns
 */
export default (
  module: IModule,
  restaurant: IRestaurant,
  dueDate?: string
): boolean => {
  if (!dueDate) {
    return false;
  }

  // check if restaurant is authorized
  const restaurantIndex = module.authorized_restaurants.indexOf(restaurant.id);
  if (!~restaurantIndex) {
    return false;
  }

  if (dayjs().isAfter(dayjs(dueDate))) {
    return false;
  }

  return true;
};
