import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

interface ConfirmButtonBasketProps {
  setShowBasket: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  canCheckout: boolean;
}

export const ConfirmButtonBasket = ({
  setShowBasket,
  canCheckout,
}: ConfirmButtonBasketProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const buttonStyle = !canCheckout ? "btn-secondary" : "btn-primary";

  function handleClose() {
    setShowBasket(false);
    navigate(`${location.pathname}/checkout`);
  }

  return (
    <div className="d-flex justify-content-center mb-2">
      <button
        className={`checkout-button btn 
      ${buttonStyle} fw-bold w-100`}
        onClick={handleClose}
        disabled={!canCheckout}
      >
        {t<string>("checkout.validationBasket")}
      </button>
    </div>
  );
};
