import "./restaurant-nav.scss";

import { filter } from "lodash";
import { Container } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import localOrderState from "shared/atoms/localOrder.atom";
import {
  IHydratedCatalog,
  IHydratedTag,
} from "shared/interfaces/Catalog.interface";
import IRestaurant from "shared/interfaces/Restaurant.interface";
import { itemFilteredByAvailability } from "shared/utils/restaurant";

import RestaurantNavHeader from "./components/RestaurantNavHeader";
import RestaurantNavItem from "./components/RestaurantNavItem";

interface RestaurantNavProps {
  restaurant: IRestaurant;
  catalog: IHydratedCatalog | undefined;
  onChange: (index: number, tag: IHydratedTag) => void;
  visible: boolean;
  activePill: number;
}
export const RestaurantNav = ({
  restaurant,
  catalog,
  onChange,
  visible,
  activePill,
}: RestaurantNavProps) => {
  const { due_date } = useRecoilValue(localOrderState);

  return (
    <>
      <RestaurantNavHeader restaurant={restaurant} />
      <Container fluid className="restaurant-nav-container p-2">
        <Container>
          <span className="restaurant-nav-separator mt-n2"></span>
          <ul className="nav nav-pills pills-scroll">
            {catalog &&
              filter(catalog.tags, (tag) => tag.parent_id === null).map(
                (tag: IHydratedTag, index: number) => {
                  const categoryHasItemsAvailable = tag.items.filter((item) =>
                    itemFilteredByAvailability(item, due_date)
                  );
                  return categoryHasItemsAvailable.length ? (
                    <RestaurantNavItem
                      key={tag.id}
                      {...{ index, onChange, tag, visible, activePill }}
                    />
                  ) : null;
                }
              )}
          </ul>
        </Container>
      </Container>
    </>
  );
};
