import { useTranslation } from "react-i18next";
import IRestaurant from "shared/interfaces/Restaurant.interface";

import EmptySearchIcon from "./components/EmptySearchIcon";
import RestaurantsListInput from "./components/RestaurantListInput";
import RestaurantsListRender from "./components/RestaurantListRender";
import RestaurantsListPreloader from "./components/RestaurantsListPreloader";

interface RestaurantsListHeaderProps {
  allData: IRestaurant[];
  setFilteredData: React.Dispatch<React.SetStateAction<IRestaurant[]>>;
  restaurants: IRestaurant[];
  setFilteredDataHasRestaurantWithCoordinates: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  hasMap: boolean | undefined;
  loadingRestaurants: boolean | undefined;
  filteredData: IRestaurant[];
}
export const RestaurantsListHeader = ({
  allData,
  setFilteredData,
  restaurants,
  setFilteredDataHasRestaurantWithCoordinates,
  loadingRestaurants,
  filteredData,
  hasMap,
}: RestaurantsListHeaderProps) => {
  const { t } = useTranslation();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = allData.filter((data: IRestaurant) => {
      const consolidatedName = `${data.name} - ${data.address}`.toLowerCase();
      return consolidatedName.search(value) !== -1;
    });
    setFilteredData(result);

    const restaurantHasCoordinates = restaurants.find(
      (r) => r.coordinates.lat && r.coordinates.lng
    );
    setFilteredDataHasRestaurantWithCoordinates(!!restaurantHasCoordinates);
  };
  return (
    <div
      className={`restaurants-list-container ${
        hasMap ? "restaurants-list-with-map" : ""
      }`}
    >
      <div className="restaurants-list-header d-flex flex-column">
        <span>{t<string>("restaurantsList.titleTakeAway")}</span>
        <small>{t<string>("restaurantsList.subtitleTakeAway")}</small>
        <RestaurantsListInput handleSearch={handleSearch} />
        {!filteredData.length ? <EmptySearchIcon /> : null}
      </div>
      {loadingRestaurants && <RestaurantsListPreloader />}
      <RestaurantsListRender restaurants={filteredData} />
    </div>
  );
};
