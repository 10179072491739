import "./top-bar.scss";

import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import moduleState, { IModuleState } from "shared/atoms/module.atom";
import userAtom from "shared/atoms/user.atom";
import ZImage from "shared/components/ZImage";

export function TopBar() {
  const { t, i18n } = useTranslation();
  const { module }: IModuleState = useRecoilValue(moduleState);
  const navigate = useNavigate();
  const location = useLocation();

  const isMenu = location.pathname === "/menu";
  // const [, setUserState] = useRecoilState(userAtom);

  // function handleLogoutUser() {
  //   setUserState({
  //     isLoggedIn: false,
  //     customer: {
  //       first_name: "",
  //       last_name: "",
  //       email: "",
  //       phone: "",
  //     },
  //   });
  //   document.location.reload();
  // }

  // function handleDropdownLink(link: string) {
  //  navigate(link);
  // }

  function NavBarItems() {
    return (
      <>
        {i18n.languages.length > 1 && (
          <NavDropdown
            title={i18n.language.toUpperCase()}
            className="d-flex"
            align="end"
            id="lang-nav-dropdown"
          >
            {i18n.languages.map((lang, langIndex) => (
              <div key={langIndex}>
                {lang !== i18n.language && (
                  <NavDropdown.Item onClick={() => i18n.changeLanguage(lang)}>
                    {lang.toUpperCase()}
                  </NavDropdown.Item>
                )}
              </div>
            ))}
          </NavDropdown>
        )}
      </>
    );
  }

  return (
    <Navbar expand="lg" className="top-bar" fixed="top">
      <Container className="d-flex justify-content-start align-items-center">
        <Nav className="d-flex nav-main">
          <Navbar.Brand
            className="d-flex flex-row align-items-center"
            onClick={() => {
              !isMenu && navigate("/");
            }}
          >
            <ZImage
              src={module.logo_url}
              alt={module.name}
              width={"48px"}
              height={"48px"}
            />
          </Navbar.Brand>
        </Nav>

        <Navbar.Toggle aria-controls="offcanvasNavbar" className="d-none" />

        <Nav className="nav-items d-flex">
          <NavBarItems />
        </Nav>

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              {/* {user && (
                <> {t<string>("topBar.welcomeUser", { userName: user.first_name })}</>
              )}
              {!user && <> {t<string>("commons.welcome")}</>} */}
              <> {t<string>("commons.welcome")}</>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>{/* <NavBarItems /> */}</Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
