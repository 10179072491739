import "./follow-order.scss";

import { mdiInformationOutline, mdiSilverware } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import Error from "pages/Error";
import { useEffect, useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import userAtom from "shared/atoms/user.atom";
import RestaurantBasketSummary from "shared/components/RestaurantBasketSummary";
import Spinner from "shared/components/Spinner";
import ORDER_MODES from "shared/constants/orderModes.const";
import ORDER_STATUS from "shared/constants/orderStatus.const";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import useFetchOrder from "shared/hooks/useUserOrder/useFetchOrder.hook";
import { formatPriceForBasket } from "shared/utils/commons";

import ItemRecapFollowOrder from "./components/ItemRecapFollowOrder";

export const FollowOrder = () => {
  const [refetch, setRefetch] = useState(true);
  const navigate = useNavigate();
  const { resetLocalOrder } = useLocalOrder();
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const orderStatusParams = searchParams.get("status");
  const orderNotFinished =
    orderStatusParams === "waiting" || orderStatusParams === "cancelled";
  const [userState, setUserState] = useRecoilState(userAtom);
  const { t } = useTranslation();
  const { data, isLoading } = useFetchOrder(
    `${orderId}`,
    refetch ? 1000 : false
  );
  const order = data?.data;

  useEffect(() => {
    if (order && order.status === "accepted_by_pos") {
      setRefetch(false);
    }
  }, [order]);

  const paiementStatus = useMemo(() => {
    if (!order) {
      return null;
    }
    if (!order.is_payed && !order.online_payment_identifier) {
      return t<string>(`order.status.${order.mode}.offline-paiement`);
    }
    if (order.is_payed && order.online_payment_identifier) {
      return t<string>(`order.status.${order.mode}.online-paiement`);
    }
  }, [order]);

  //On paygreen success reset current local order
  useEffect(() => {
    if (orderStatusParams === "payed") {
      resetLocalOrder();
    }
  }, [orderStatusParams]);

  //On paygreen abort keep the user and prepare redirection
  useEffect(() => {
    if (orderNotFinished && order) {
      const { customer_info: customer } = order;

      setUserState({
        ...userState,
        customer,
      });
    }
  }, [data]);
  if (isLoading) {
    return <Spinner visible={true} />;
  }

  if (!order) {
    return <Error icon={mdiSilverware} translation="errors.orderNotFound" />;
  }
  const { restaurant_id } = order;

  const totalBasket = formatPriceForBasket(order?.total_price);

  const orderLocalDate = dayjs(order.due_date).utc(true).toDate();
  const deliveryIsToday = dayjs(orderLocalDate).isToday();
  const deliveryDate = dayjs(orderLocalDate).format("DD/MM/YYYY");
  const deliveryHour = dayjs(orderLocalDate).format("HH:mm");
  const orderDate = dayjs(order.created_at).format("DD/MM/YYYY");
  const orderHour = dayjs(order.created_at).format("HH:mm");

  const orderMode = ORDER_MODES.find((mode) => mode.id === order.mode);
  const orderStatus = ORDER_STATUS.find(
    (status) => status.orderStatus === order.status
  );
  const hasStatus =
    orderStatus &&
    ["accepted_by_pos", "denied_by_pos", "sent_to_pos"].includes(
      orderStatus.orderStatus
    );

  return (
    <div className="follow-order">
      <div className="container-center">
        <Container>
          <div className="order-information mt-4 pb-4 px-3">
            <h4 className="pt-4 mb-2">
              {t<string>(`order.title`, {
                order: order.display_id,
              })}
            </h4>
            {orderMode && (
              <p className="information-order-item">
                {orderMode && <Icon path={orderMode.icon} size={0.8} />}
                {deliveryIsToday
                  ? t<string>(`checkout.summary.${orderMode.id}.today`, {
                      time: deliveryHour,
                    })
                  : t<string>(`checkout.summary.${orderMode.id}.other`, {
                      time: deliveryHour,
                      date: deliveryDate,
                    })}
              </p>
            )}
            {orderStatus && orderMode && hasStatus && (
              <p className="information-order-item mt-2">
                <Icon path={mdiInformationOutline} size={0.8} />{" "}
                <span>
                  {t(`order.status.${orderMode.id}.${orderStatus.orderStatus}`)}
                </span>
              </p>
            )}
          </div>
        </Container>
        <Container>
          <div className="order-information mt-4 px-3">
            <h5 className="order-title pt-4">{t<string>("order.date")}</h5>
            <div className="d-flex">
              <p className="information-order-item">{orderDate}</p>
              <p className="ms-2 information-order-item">{orderHour}</p>
            </div>
            <hr />
            <h5 className="order-title mt-2">{t<string>("order.summary")}</h5>
            <div className="ps-2 pe-4">
              {order?.items.map((item, index: number) => (
                <ItemRecapFollowOrder item={item} key={item.item_id + index} />
              ))}
            </div>
            <hr />
            <RestaurantBasketSummary total={totalBasket} />
            {paiementStatus && (
              <div className="payment-status-container p-4 fw-bold text-end">
                {paiementStatus}
              </div>
            )}
          </div>
          <div className="pt-4">
            {orderNotFinished ? (
              <Button
                onClick={() =>
                  navigate(`/restaurants/${restaurant_id}/checkout`)
                }
                variant="primary"
                className="mode-btn btn-block"
              >
                {t<string>("order.unpaidOrder")}
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/")}
                variant="primary"
                className="mode-btn btn-block"
              >
                {t<string>("order.reOrder")}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
