import ICatalog, {
  IHydratedItemDish,
  IHydratedItemMenu,
  IHydratedTag,
  ITag,
} from "shared/interfaces/Catalog.interface";

import { findCatalogItemById, hydrateDish, hydrateMenu } from ".";

export default (tag: ITag, catalog: ICatalog): IHydratedTag => {
  let hydratedTag: IHydratedTag = { ...tag, items: [] };

  for (const tagItemId of tag.item_ids) {
    const item = findCatalogItemById(tagItemId, catalog);

    if (item) {
      let hydratedItem: IHydratedItemDish | IHydratedItemMenu | null = null;
      switch (item.type) {
        case "dish":
          hydratedItem = hydrateDish(item, catalog);
          break;
        case "menu":
          hydratedItem = hydrateMenu(item, catalog);
          break;
        default:
          break;
      }
      if (hydratedItem) {
        hydratedTag.items.push(hydratedItem);
      }
    }
  }

  return hydratedTag;
};
