import "./item-card.scss";

import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ICurrentMenuState } from "shared/atoms/currentMenu.atom";
import Counter from "shared/components/Counter";
import useLocalOrder from "shared/hooks/useLocalOrder.hook";
import {
  IHydratedItemDish,
  IHydratedItemMenu,
} from "shared/interfaces/Catalog.interface";
import { IOrderItem } from "shared/interfaces/Order.interface";
import { formatPriceForBasket } from "shared/utils/commons";
import { getTotalPrice } from "shared/utils/localOrder";

interface RestaurantItemBasketCardProps {
  item: IOrderItem;
  isReadOnly?: boolean;
  setModifyItemOrder?: React.Dispatch<
    React.SetStateAction<IOrderItem | undefined>
  >;
  setAddingItem?: React.Dispatch<
    React.SetStateAction<IHydratedItemMenu | IHydratedItemDish | undefined>
  >;
  setModifyMenuOrder?: React.Dispatch<
    React.SetStateAction<ICurrentMenuState | undefined>
  >;
}

export const RestaurantItemBasketCard = ({
  item,
  isReadOnly,
  setModifyItemOrder,
  setAddingItem,
  setModifyMenuOrder,
}: RestaurantItemBasketCardProps) => {
  const { addItem, removeItem } = useLocalOrder();
  const { t } = useTranslation();
  if (!item) return null;
  const modifierChecker =
    setModifyItemOrder && setAddingItem && setModifyMenuOrder;

  return (
    <Container className="p-0 my-3 item-container">
      <div className="item-info">
        <div className="item-name fw-bold">{item.name}</div>
        <Counter
          add={() => addItem(item)}
          remove={() => removeItem(item)}
          quantity={item.quantity}
          isReadOnly={isReadOnly}
        />
        <div className="item-price fw-bold">
          {formatPriceForBasket(getTotalPrice([item]))}
        </div>
      </div>

      {item?.modifiers.length > 0 ? (
        <ul>
          {item.modifiers.map((modifierFirstLevel: IOrderItem) => {
            return (
              <li
                className="modifier-first-level d-flex flex-column pb-1"
                key={modifierFirstLevel.id}
              >
                <div className="d-flex justify-content-between">
                  <span>{modifierFirstLevel.name}</span>
                  <span>x{modifierFirstLevel.quantity}</span>
                </div>
                {modifierFirstLevel.modifiers.length > 0 ? (
                  <div>
                    <ul className="ps-4">
                      {modifierFirstLevel.modifiers.map(
                        (modifierSecondLevel: IOrderItem) => {
                          return (
                            <li
                              className="modifier-second-level text-muted"
                              key={modifierSecondLevel.id}
                            >
                              {modifierSecondLevel.name}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ) : null}
              </li>
            );
          })}
        </ul>
      ) : null}
      {item.modifiers.length > 0 && modifierChecker ? (
        <div className="edit-dish-container">
          <button
            className="button-edit-dish"
            onClick={() => {
              setModifyItemOrder(item);
              setAddingItem(item.modalItem?.addedItem);
              if (item.modalItem?.addedMenu) {
                setModifyMenuOrder(item.modalItem.addedMenu);
              }
            }}
          >
            {t<string>("commons.edit")}
          </button>
        </div>
      ) : null}
    </Container>
  );
};
