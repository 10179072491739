import dayjs from "dayjs";
import DAYS_OF_WEEK_ISO from "shared/constants/date/daysOfWeekISO.const";
import IRestaurant from "shared/interfaces/Restaurant.interface";

/**
 * Return an array of accepted date for the restaurant
 * @param restaurant
 */
export default (restaurant: IRestaurant, maxDays: number): Date[] => {
  const dates: Date[] = [];
  let counterDaysInArray = 0;

  while (counterDaysInArray <= maxDays) {
    if (!restaurant.opening_hours.length) break;
    for (const openingDay of restaurant.opening_hours) {
      counterDaysInArray++;

      if (openingDay.time_periods.length) {
        const currentWeek = Math.floor(counterDaysInArray / 7);
        const maxDay = dayjs().add(maxDays, "day");
        const getFirstHourOfTheDay =
          openingDay.time_periods[0].start_time.split("")[0];
        const dateMoment = dayjs()
          .day(DAYS_OF_WEEK_ISO[openingDay.day_of_week])
          .hour(Number(getFirstHourOfTheDay))
          .add(currentWeek, "week")
          .toDate();
        //Check if the date is available in the range of the max days
        if (dayjs(dateMoment).isSameOrBefore(dayjs(maxDay), "day")) {
          dates.push(dateMoment);
        }
      }
    }
  }
  return dates;
};
