import { Helmet } from "react-helmet-async";
import IModule from "shared/interfaces/Module.interface";

export const HelmetApp = ({ module }: { module: IModule }) => {
  const { image, title, description, favicon } = { ...module.metadata };

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}

      {favicon && <link rel="icon" href={favicon} />}

      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};
